import React, {useState} from "react";
import {IoEllipsisVertical, IoLocationSharp} from "react-icons/io5";
import {AddressItem} from "./StyleAdrss";
import {Dropdown, Spinner} from "react-bootstrap";
import {BiEditAlt} from "react-icons/bi";
import {RiDeleteBin6Line} from "react-icons/ri";
import CustomModal from "../../customModal/CustomModal";
import AddAddressForm from "../ListAddressUser/AddAddressForm";
import {useDispatch, useSelector} from "react-redux";
import cancel from "../../../assets/Images/delete.png";
import {deleteAddress, getAddresses} from "../../../Store/reducers/addresses";
import words from "../../../assets/words";

const IconButton = React.forwardRef(({type = "button", children, onClick, disabled = false}, ref) => {
   return (
      <button
         ref={ref}
         type={type}
         onClick={onClick}
         disabled={disabled}
         style={{border: "none"}}
      >
         {children}
      </button>
   );
});

const ItemAdress = ({address}) => {
   const dispatch = useDispatch();
   const loader = useSelector((state) => state.addresses.loading);
   const language = useSelector((state) => state.auth.language);

   const [showModales, setShowModales] = useState({
      showEditAddress: false,
      showDeleteAddress: false,
   });

   const handleEditAddress = () => {
      setShowModales({...showModales, showEditAddress: true});
   };

   const handleDeleteAddress = () => {
      dispatch(deleteAddress(address.id)).then((res) => {
         if (res?.meta?.requestStatus !== "rejected") {
            dispatch(getAddresses());
            setShowModales({...showModales, showDeleteAddress: false});
         }
      });
   };

   return (
      <div className="col-sm-12 col-lg-6 mb-3">
         {showModales.showEditAddress && (
            <CustomModal
               show={showModales.showEditAddress}
               onHide={() => setShowModales({...showModales, showEditAddress: false})}
               title={`${words.EDIT_ADDRESS[language]} #${address.id}`}
            >
               <AddAddressForm
                  address={address}
                  edit={true}
                  onDone={() => setShowModales({...showModales, showEditAddress: false})}
               />
            </CustomModal>
         )}

         {showModales.showDeleteAddress && (
            <CustomModal
               show={showModales.showDeleteAddress}
               onHide={() => setShowModales({...showModales, showDeleteAddress: false})}
               title={`${words.DELETE_ADDRESS[language]} #${address?.id} ?!`}
            >
               <div className="text-center">
                  <img
                     src={cancel}
                     alt="check pic"
                     style={{width: "50%"}}
                     crossorigin="anonymous"
                  />
                  <p className="mt-3">{words.ARE_YOU_WANT_TO_DELETE_ADDRESS[language]}</p>
                  <button
                     className="btn text-white w-50 mt-4"
                     onClick={handleDeleteAddress}
                     style={{
                        backgroundColor: "RGBA(74, 62, 76, 0.8)",
                     }}
                  >
                     {loader ? <Spinner animation="border" /> : words.YES[language]}
                  </button>
               </div>
            </CustomModal>
         )}

         <AddressItem className="rounded d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center gap-3">
               <div className="ico_loc rounded-circle bg-white d-flex justify-content-center align-items-center ">
                  <IoLocationSharp />
               </div>
               <p className="txt_addrs">
                  {address?.address}, {address?.area?.name}, {address?.city?.name}
                  {address?.country?.name && ","} {address?.country?.name}
                  <span className="primary_addrs">({address?.city?.name})</span>
               </p>
            </div>

            <button className="btn_edit">
               <Dropdown>
                  <Dropdown.Toggle
                     as={IconButton}
                     variant="success"
                     id="dropdown-basic"
                  >
                     <IoEllipsisVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Dropdown.Item
                        style={{
                           color: "var(--primaryColor700)",
                           display: "flex",
                           justifyContent: "flex-start",
                           alignItems: "center",
                        }}
                        onClick={handleEditAddress}
                     >
                        <BiEditAlt
                           size={18}
                           style={{marginRight: "6px"}}
                        />
                        {words.EDIT_ADDRESS[language]}
                     </Dropdown.Item>
                     <Dropdown.Item
                        style={{
                           color: "red",
                           display: "flex",
                           justifyContent: "flex-start",
                           alignItems: "center",
                        }}
                        onClick={() => setShowModales({...showModales, showDeleteAddress: true})}
                     >
                        <RiDeleteBin6Line
                           size={18}
                           style={{marginRight: "6px", color: "red"}}
                        />
                        {words.DELETE_ADDRESS[language]}
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            </button>
         </AddressItem>
      </div>
   );
};

export default ItemAdress;
