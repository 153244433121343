import { useEffect, useState } from "react";
import "./contact.scss";
import { useDispatch, useSelector } from "react-redux";
import words from "../../assets/words";
import CustomModal from "../../Components/customModal/CustomModal";
import check from "../../assets/Images/conact.png";
import { validEmail } from "../../utils/formatWords";
import { sendMesgFromContactUs } from "../../Store/reducers/contact-us";

const ContactUs = () => {
   const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.language);
  const loading = useSelector((state) => state.contactUs.loading);
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(()=>{
   if(data.name && data.email && data.message && validEmail(data.email)){
    setValidated(true)
   }else{
      setValidated(false)
   }
  },[data])

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   dispatch(sendMesgFromContactUs(data)).then(res=>{
      if(res){
         setShowModal(true);
      
      }
   })
  };

  return (
    <div className="contact" dir={language === "ar" ? "rtl" : "ltr"}>
      {showModal && (
        <CustomModal
          title={words.THANKS[language]}
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p style={{
               fontSize: "1.5rem",
               fontWeight: 700,
               marginBottom: "1rem",
               color: "RGBA(74, 62, 76, 0.8)",
            }}>{words.THANKS_FOR_CONTACTING_US[language]}</p>
            <img src={check} alt="contact us" style={{ width: "70%" }} />
            <button
              className="btn text-white w-50 mt-4"
              onClick={() =>{ setShowModal(false)
               setData({
                  name: "",
                  email: "",
                  message: "",
               })
            }}
              style={{
                backgroundColor: "RGBA(74, 62, 76, 0.8)",
              }}
            >
              {words.DONE[language]}
            </button>
          </div>
        </CustomModal>
      )}
      <div className="head_page mb-4">
        <div className="container">
          <h3>{words.CONTACT_US[language]}</h3>
        </div>
      </div>
      <div className="container">
        <h2 className="mb-5 mt-5 fs-4 text-center">
          {words.CONTACT_US_DESC[language]}
        </h2>
        <form className="mb-5" onChange={handleChange} onSubmit={handleSubmit}>
          <div className="row">
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="formGroupExampleInput" class="form-label">
                {words.NAME[language]}
              </label>
              <input
                type="text"
                class="form-control"
                id="formGroupExampleInput"
                placeholder={words.NAME[language]}
                name="name"
                value={data.name}
              />
            </div>
            <div class="mb-3 col-sm-12 col-lg-6">
              <label for="formGroupExampleInput2" class="form-label">
                {words.EMAIL[language]}
              </label>
              <input
                type="text"
                class="form-control"
                id="formGroupExampleInput2"
                placeholder={words.EMAIL[language]}
                name="email"
                value={data.email}
              />
            </div>
            <div class="mb-3 col-sm-12">
              <label for="message" class="form-label">
                {words.YOUR_MESSAGE[language]}
              </label>
              <textarea
                class="form-control"
                id="message"
                rows="5"
                placeholder={words.YOUR_MESSAGE[language]}
                name="message"
                value={data.message}
              ></textarea>
            </div>
          </div>
          <input
            class="btn btn_submit"
            type="submit"
            disabled={!validated || loading}
            value={
               loading ? words.LOADING[language] : words.SEND_MESSAGE[language]
            }
          />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
