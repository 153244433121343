import { Link, useLocation, useNavigate } from "react-router-dom";
import classes from "./MobileMenu.module.scss";
import { useState } from "react";
import { SlMenu } from "react-icons/sl";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../../assets/Images/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { BsFillHandbagFill, BsGridFill } from "react-icons/bs";
import { FaBullhorn, FaTruck } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi2";
import { NavLink } from "react-bootstrap";
import { logOut } from "../../../Store/reducers/auth";
import { BiLogOut } from "react-icons/bi";
import { AiFillWechat } from "react-icons/ai";
import words from "../../../assets/words";

const MobileMenu = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const user = useSelector((state) => state.auth.user);
  const language = useSelector((state) => state.auth.language);

  const [showMenu, setShowMenu] = useState(false);
  const [currentLink, setCurrentLink] = useState(location);
  const [ShowPhoneList, setShowPhoneList] = useState(false);

  const tabs = [
    {
      name: words.PRODUCTS[language],
      icon: <BsGridFill />,
      to: "/dashboard/products",
    },
    {
      name: words.NEW_ORDERS[language],
      icon: <BsFillHandbagFill />,
      to: "/dashboard/orders/new",
    },
    {
      name: words.ORDERS_HISTORY[language],
      icon: <FaTruck />,
      to: "/dashboard/orders/old",
    },
    {
      name: words.VENDORS[language],
      icon: <HiUserGroup />,
      to: "/dashboard/vendors",
    },
    {
      name: words.CHAT_CONTACTS[language],
      icon: <AiFillWechat />,
      to: "/dashboard/chat-contacts",
    },
    {
      name: words.DRIVERS_REPLIES[language],
      icon: <FaBullhorn />,
      to: "/dashboard/drivers-replies",
    },
    {
      name: words.PROFILE_SETTINGS[language],
      icon: (
        <img
          crossorigin="anonymous"
          src={user?.img}
          alt="user pic"
          style={{ width: "20px", height: "100%", borderRadius: "50%" }}
        />
      ),
      to: "/dashboard/profile",
    },
  ];

  return (
    <div className={classes.nav}
      dir={language == "ar" ? "rtl" : "ltr"}
    >
      <div className={classes.iconsSection}>
        <SlMenu size={35} onClick={() => setShowMenu(true)} />
      </div>
      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement="end"
        name="end"
        style={{ left: "100%", transform: "translate(-100%, 0)" }}
      >
        <Offcanvas.Header
          closeButton
          className={classes.offcanvas_header}
        ></Offcanvas.Header>
        <Offcanvas.Body style={{ paddingTop: "0px", direction: language === "en" ? "ltr" : "rtl" }}>
          <div
            className="brand text-center w-50"
            style={{
              backgroundColor: "#4a3e4c",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <img src={logo} alt="logo" className={classes.brandLogo} />
          </div>
          <hr />
          <div
            className={classes.navLinks}
            onClick={(ev) => {
              setCurrentLink(ev.target.innerText);
              setShowMenu(false);
            }}
          >
            {tabs.map((tab, index) => {
              return (
                <Link
                  to={tab.to}
                  className={` ${location == tab.to ? classes.activeLink : null
                    }`}
                  key={index}
                >
                  {tab.icon}
                  {tab.name}
                </Link>
              );
            })}
            <Link
              to="/"
              className="link_route fw-medium d-flex justify-content-start align-items-center "
              onClick={() => dispatch(logOut())}
            >
              <BiLogOut />
              {words.LOG_OUT[language]}
            </Link>
          </div>

          <hr />
          <div className={classes.downloadMobile}>@2023 Cottage</div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default MobileMenu;
