import styled from "styled-components";

export const StyleInfo = styled.div`
   .image_profile {
      width: 70px;
      height: 70px;
      img {
         border-radius: 50%;
         width: 100%;
         height: 100%;
      }
   }
   @media only screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
      .num_phone {
         margin: 0 10px !important;
      }
      txt_info {
         text-align: center;
      }
   }
`;
