import React from "react";
import { useSelector } from "react-redux";
import CardSummary from "../CardsProductSummary/CardSummary";
import { ListCards } from "./StyleList";

const ListCardsSummary = () => {
  const carts = useSelector(state => state.carts.reduxCarts)


  return (
    <ListCards className="row p-4">
      <div className="col">
        {
          carts?.map((item, index) => {
            return(
              <CardSummary key={index} item={item}/>
            )
          })
        }
      </div>
    </ListCards>
  );
};

export default ListCardsSummary;
