import { configureStore } from "@reduxjs/toolkit";
import auth from "./reducers/auth";
import products from "./reducers/products"
import categories from "./reducers/categories";
import brands from "./reducers/brands"
import anoncements from "./reducers/anoncements"
import countries from "./reducers/countries"
import favorites from "./reducers/favorites"
import carts from "./reducers/carts"
import orders from "./reducers/orders"
import users from "./reducers/users"
import addresses from "./reducers/addresses"
import services from "./reducers/services"
import sizes from "./reducers/sizes"
import colors from "./reducers/colors"
import chats from "./reducers/chats";
import contactUs from "./reducers/contact-us";

const cartMiddleware = (store) => (next) => (action) =>
{
  const prefix = "carts/";

  const result = next(action)

  if (action.type.startsWith(prefix))
  {

    localStorage.setItem('carts', JSON.stringify(store.getState().carts?.reduxCarts))

    // console.log("The string starts with 'carts/'.", , action);
  }

  return result;
};

const store = configureStore({
  reducer: {
    auth: auth,
    products: products,
    categories: categories,
    brands: brands,
    anoncements: anoncements,
    countries: countries,
    favorites: favorites,
    carts: carts,
    orders: orders,
    users: users,
    addresses: addresses,
    services: services,
    sizes: sizes,
    colors: colors,
    chats: chats,
    contactUs: contactUs,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(cartMiddleware)

});

export default store;
