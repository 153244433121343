import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getUsers = createAsyncThunk(
  "users/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/getAll/?type=${
        data.type ? data.type : ""
      }&search=${data?.search ? data?.search : ""}&service=${
        data?.service ? data?.service : ""
      }`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/get",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/${id}/getUser`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLastContacts = createAsyncThunk(
  "lastContacts/get",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/messages/lastContacts?id=${id}`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    user: null,
    loading: false,
    lastContacts: null,
  },
  reducers: {},
  extraReducers: {
    //get users
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
    },

    //get user
    [getUserById.pending]: (state, action) => {
      state.loading = true;
      state.user = null;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [getUserById.rejected]: (state, action) => {
      state.loading = false;
    },
    //get last contacts
    [getLastContacts.pending]: (state, action) => {
      state.loading = true;
    },
    [getLastContacts.fulfilled]: (state, action) => {
      state.loading = false;
      state.lastContacts = action.payload;
    },
    [getLastContacts.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = anoncementsSlice.actions;

export default usersSlice.reducer;
