import React from "react";
import {useState} from "react";
import {Spinner} from "react-bootstrap";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {FiHeart} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import minaas from "../../../assets/Images/minas.svg";
import addProd from "../../../assets/Images/add_prod.svg";
import {addToReduxCart, decreaseCart, removeFromReduxCart} from "../../../Store/reducers/carts";
import {createFavorite, deleteFavorite} from "../../../Store/reducers/favorites";
import {StyleProd} from "./StyleProdCart";
import words from "../../../assets/words";
const ProductCart = ({item}) => {
   const dispatch = useDispatch();
   const user = useSelector((state) => state?.auth?.user);
   const loading = useSelector((state) => state.carts.loading);
   const language = useSelector((state) => state.auth.language);
   const [fav, setFav] = useState(item?.product?.isFavourite);

   const handleAddToFav = () => {
      setFav(true);
      dispatch(createFavorite(item?.product?.id));
   };

   const handleRemoveFromFav = (e) => {
      setFav(false);
      dispatch(deleteFavorite(item?.product?.id));
   };

   const increaseCart = () => {
      dispatch(addToReduxCart(item));
   };

   const decreaseFromCart = () => {
      dispatch(decreaseCart(item));
   };

   const removeCart = () => {
      dispatch(removeFromReduxCart(item));
   };

   return (
      <StyleProd className="d-flex justify-content-between align-items-start flex-wrap border-bottom pb-4 mb-4">
         <div className="d-flex justify-content-start align-items-start gap-3 mb-3 inf_prod ">
            <div className="image_prod ">
               <img
                  src={item?.files ? item?.files : item?.image}
                  alt="product"
                  crossorigin="anonymous"
               />
            </div>
            <div className="txt_product d-flex flex-column">
               <p className="title_ pb-2">{item?.name}</p>
               <p className="info">
                  {words.SIZE[language]}: {item?.size?.name}
                  {item?.color && `, Color: ${item?.color?.name}`}
               </p>
            </div>
         </div>
         <div className="qty d-flex justify-content-center align-items-center gap-3 mb-3">
            {/* <select className="form-control form-select shadow-sm">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
        </select> */}

            <div className="info_price">
               <p>{words.COUNT[language]}: {item?.quantity}</p>
               <p className="total">{words.TOTAL_PRICE[language]}: {item?.price * item?.quantity} {words.SR[language]}</p>
               <p className="per_item">{item?.price} {words.SR[language]} / {words.PER_ITEM[language]}</p>
            </div>
         </div>
         <div className="fav_delet d-flex gap-2 mb-3">
            {/* <span className="btn shadow-sm btn_fav">
          {fav ? (
            <AiFillHeart
              color={"red"}
              onClick={(e) => handleRemoveFromFav(e)}
            />
          ) : (
            <AiOutlineHeart onClick={(e) => handleAddToFav(e)} />
          )}
        </span> */}
            <div className="d-flex flex-column">
               <buton
                  className="btn remove_ shadow-sm"
                  onClick={removeCart}
               >
                  {/* {loading ? <Spinner animation="border" /> : "Remove"} */}
                  {words.REMOVE[language]}
               </buton>
               <div className="">
                  <div
                     className="gap-3 inp_qty w-100 d-flex justify-content-between align-items-center fs-4 mt-3"
                     style={{
                        border: "1px solid grey",
                        borderRadius: 10,
                     }}
                  >
                     <button
                        className="btn"
                        onClick={decreaseFromCart}
                     >
                        <img
                           src={minaas}
                           alt="minas"
                           crossorigin="anonymous"
                        />
                     </button>
                     <span className="c_o_u_n">{item?.quantity}</span>
                     <button
                        className="btn"
                        onClick={increaseCart}
                     >
                        <img
                           src={addProd}
                           alt="add"
                           crossorigin="anonymous"
                        />
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </StyleProd>
   );
};

export default ProductCart;
