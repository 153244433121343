import styled from "styled-components";

export const Menu = styled.div`
   .list-group {
      .item_list {
         padding: 10px;
         svg {
            color: #9da1a7;
            font-size: 25px;
            margin-right: 15px;
         }
         .txt_list {
            color: #545454;
         }
         .link_R {
            @media only screen and (max-width: 991px) {
               flex-direction: column;
               align-items: center !important;
               justify-content: center !important;
               width: 90px;
            }
            .txt_list {
               @media only screen and (max-width: 991px) {
                  font-size: 14px;
                  text-align: center;
               }
            }
            svg {
               @media only screen and (max-width: 991px) {
                  margin-right: 0;
               }
            }
         }
         .logOut {
            @media only screen and (max-width: 991px) {
               display: flex;
               flex-direction: column;
               align-items: center !important;
               justify-content: center !important;
            }
         }
      }
      .active {
         svg {
            color: #4a3e4c;
         }
         .txt_list {
            color: #4a3e4c;
            font-size: 16px;
         }
      }
      @media only screen and (max-width: 991px) {
         flex-direction: row;
         overflow-x: scroll !important;
         margin-bottom: 30px;
      }
   }
`;
