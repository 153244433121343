import iconRevieww from "../../../assets/Images/iconspace_Create Note_25px.png";
import React, {useEffect, useState} from "react";
import {BsArrowLeft} from "react-icons/bs";
import {Link, useNavigate} from "react-router-dom";
import CompinedSh from "../../../assets/Images/Combined Shape.png";
import servEXPRESS from "../../../assets/Images/Express.png";
import finalizeIcon from "../../../assets/Images/finalize.png";
import HomeIcon from "../../../assets/Images/Home.png";
import cardPay from "../../../assets/Images/masterCardd.png";
import phoneIcon from "../../../assets/Images/Phone.png";
import prfofileImg from "../../../assets/Images/profile.png";
import CardProductMini from "../../../Components/cardGuestCheckout/CardProductGuest";
import DataSummary from "../../../Components/Checkout/DataSummary/DataSummary";
import ProgressBar from "../../../Components/Checkout/ProgressBarCheck/ProgressBar";
import AnAccount from "../../../Components/HaveAnAccount/AnAccount";
import HeaderCheckOut from "./../../../Components/Checkout/headerCheckout/headerCheckOut";
import ListCarts from "./../../../Components/Checkout/ListItemsCart/ListItems/ListCarts";
import ShippService from "./../../../Components/Checkout/shippingService/ShippService";
import OurFeature from "./../../../Components/ReusableComponent/ourFeature/OurFeature";
import imgProd from "../../../assets/Images/bigImage.png";
import {ReviewStyle} from "./ReviewStyle";
import {useDispatch, useSelector} from "react-redux";
import {getMyInfo} from "../../../Store/reducers/auth";
import {getAreasByCountryId, getCitiesByCountryId, getCountries} from "../../../Store/reducers/countries";
import {Button, Spinner} from "react-bootstrap";
import {createOrder, setRedirectUrl} from "../../../Store/reducers/orders";
import {removeReduxCart, setFormData} from "../../../Store/reducers/carts";

import axios from "axios";
import words from "../../../assets/words";

const Review = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const user = useSelector((state) => state.auth.user);
   const countries = useSelector((state) => state.countries.countries);
   const cities = useSelector((state) => state.countries.cities);
   const areas = useSelector((state) => state.countries.areas);
   const carts = useSelector((state) => state.carts.reduxCarts);
   const loader = useSelector((state) => state.orders.loading);
   const coupon = useSelector((state) => state.carts.discount);
   const language = useSelector((state) => state.auth.language);
   const formData = useSelector((state) => state.carts.formData);

   const [userData, setUserData] = useState(user);
   const [submit, setSubmit] = useState(false);
   const [noErrors, setNoErrors] = useState(false);

   useEffect(() => {
      dispatch(getCountries());
      if (carts?.length === 0) {
         navigate(-1);
      }
   }, []);

   useEffect(() => {
      setUserData(user);
      
      dispatch(setFormData({ ...user }));
   }, [user]);

   useEffect(() => {
      if (userData?.country) {
         dispatch(getCitiesByCountryId(userData?.country));
         dispatch(getAreasByCountryId(userData?.country));
      }
   }, [userData?.country]);

   useEffect(() => {
      if (
         userData?.firstname &&
         userData?.lastname &&
         userData?.email &&
         userData?.phone &&
         userData?.address &&
         userData?.country &&
         userData?.city &&
         userData?.area &&
         userData?.state
      ) {
         setNoErrors(true);
      } else {
         setNoErrors(false);
      }
   }, [userData]);

   const handleOnCahange = (e) => {
      const { name, value } = e.target;

      dispatch(setFormData({ ...formData, [name]: value }));
  
      setUserData({...userData, [e.target.name]: e.target.value});
   };

   const handleCreateOrder = () => {
      setSubmit(true);
      if (noErrors) {
         let items = [];
         carts?.map((cart) => {
            items?.push({
               product: cart?.id,
               count: cart?.quantity,
               size: cart?.size?.id,
               color: cart?.color?.id,
               vendor: cart?.vendor,
            });
         });
         dispatch(
            createOrder({
               orderItems: items,
               deliveryCost: 100,
               promoCode: coupon ? coupon?.couponNumber : "",
               paymentSystem: "CASH",
               country: userData?.country,
               city: userData?.city,
               area: userData?.area,
               address: userData?.address,
               destination: [0, 0],
               phone: userData?.phone,
               deliveryCost: 100,
            })
         ).then((res) => {
            if (res?.meta?.requestStatus === "fulfilled") {
               //add id for order created
               dispatch(removeReduxCart());
               // navigate("/purchase-success");
               // navigate("/payment");
               handlePaymentReq(res?.payload?.data?.id);
            }
         });
      }
   };
   const handlePaymentReq = (orderID) => {
      const dataPayment = {
         profile_id: 99592,
         tran_type: "sale",
         tran_class: "ecom",
         cart_description: "Order from cottage",
         cart_id: `${orderID}`,
         cart_currency: "SAR",
         cart_amount: 1000,
         callback: "https://www.cottage-ksa.com/api/v1/transactions/callback",
         return: `https://www.cottage-ksa.com/purchase-success/${orderID}`, // ${order?.id}
      };
      try {
         const url = `https://secure.paytabs.sa/payment/request`;
         const response = axios.post(url, dataPayment, {
            headers: {
               "Content-Type": "application/json",
               Authorization: `SKJNGMNWK6-J6HDDNNHTG-RDD2TH9JJM`,
            },
         });
         dispatch(setRedirectUrl(response.data.redirect_url));
         navigate("/payment", {state: {url: response.data.redirect_url}}); //complete payment
         console.log("response", response);
      } catch (error) {
         console.log("Error response", error);
      }
   };
   return (
      <ReviewStyle dir={language === "en" ? "ltr" : "rtl"}>
         <HeaderCheckOut title={words.REVIEW_YOUR_ORDER[language]} />
         <ProgressBar number={3}/>

         <div className="container body_page">
            <div className="row pb-5">
               <div className="col-sm-12 col-lg-8 review_details">
                  <div className="bg-white mb-3 shadow-sm rounded-3 p-3 pt-4">
                     <h4 className="fw-semibold">{words.SHIPPING_TO[language]}</h4>
                     <p className="sub_title">{words.PLEASE_CHECK_ORDER_DESC[language]}</p>
                     <div className="container">
                        <div className="row">
                           <div className="col-12 mb-3 ">
                              <div className="check_content bg-white def_shadow mt-3 rounded p-3">
                                 <form>
                                    <h4 className="fw-normal mb-3">{words.GUEST_CHECKOUT[language]}</h4>
                                    <div className="row up_form">
                                       <div className="col-sm-12 col-lg-6 mb-3">
                                          <label>{words.FIRST_NAME[language]}</label>
                                          <input
                                             name="firstname"
                                             onChange={handleOnCahange}
                                             value={userData?.firstname}
                                             type="text"
                                             className="form-control"
                                             placeholder= {words.FIRST_NAME[language]}
                                          />
                                       </div>
                                       <div className="col-sm-12 col-lg-6 mb-3">
                                          <label>{words.LAST_NAME[language]}</label>
                                          <input
                                             name={"lastname"}
                                             onChange={handleOnCahange}
                                             value={userData?.lastname}
                                             type="text"
                                             className="form-control"
                                             placeholder={words.LAST_NAME[language]}
                                          />
                                       </div>
                                       <div className="col-sm-12 col-lg-6 mb-3">
                                          <label>{words.PHONE[language]}</label>
                                          <input
                                             name={"phone"}
                                             onChange={handleOnCahange}
                                             value={userData?.phone}
                                             type="number"
                                             className="form-control"
                                             placeholder={words.PHONE[language]}
                                          />
                                       </div>
                                       <div className="col-sm-12 col-lg-6 mb-3">
                                          <label>{words.EMAIL[language]}</label>
                                          <input
                                             name={"email"}
                                             onChange={handleOnCahange}
                                             value={userData?.email}
                                             type="email"
                                             className="form-control"
                                             placeholder={words.EMAIL[language]}
                                          />
                                       </div>
                                    </div>
                                    <hr className="w-100 mt-4" />
                                 </form>

                                 <form>
                                    <div className="info__guest row">
                                       <div className="col-sm-12 col-lg-8 mb-3">
                                          <label>{words.ADDRESS[language]}*</label>
                                          <input
                                             style={{
                                                border: submit && !userData?.address && "1px solid red",
                                             }}
                                             name={"address"}
                                             onChange={handleOnCahange}
                                             value={userData?.address}
                                             type="text"
                                             className="form-control"
                                             placeholder={words.ADDRESS[language]}
                                          />
                                       </div>
                                       <div className="col-sm-12 col-lg-4 mb-3">
                                          <label htmlFor="inputZip">Country</label>
                                          <select
                                             id="inputState"
                                             style={{
                                                border: submit && !userData?.country && "1px solid red",
                                             }}
                                             className="form-select"
                                             name="country"
                                             onChange={handleOnCahange}
                                          >
                                             <option value="Select">{words.SELECT_COUNTRY[language]}</option>
                                             {countries?.map((country, index) => {
                                                return (
                                                   <option
                                                      value={country?.id}
                                                      key={index}
                                                   >
                                                      {country?.name}
                                                   </option>
                                                );
                                             })}
                                          </select>
                                       </div>
                                       <div className="col-sm-12 col-lg-4 mb-3">
                                          <label htmlFor="inputZip">{words.SELECT_CITY[language]} </label>
                                          <select
                                             style={{
                                                border: submit && !userData?.city && "1px solid red",
                                             }}
                                             id="inputState"
                                             className="form-select"
                                             name="city"
                                             onChange={handleOnCahange}
                                          >
                                             <option value="Select">{words.SELECT_CITY[language]}</option>
                                             {cities?.map((city, index) => {
                                                return (
                                                   <option
                                                      value={city?.id}
                                                      key={index}
                                                   >
                                                      {city?.name}
                                                   </option>
                                                );
                                             })}
                                          </select>
                                       </div>
                                       <div className="col-sm-12 col-lg-4 mb-3">
                                          <label htmlFor="inputCity">{words.SELECT_AREA[language]}</label>
                                          <select
                                             style={{
                                                border: submit && !userData?.area && "1px solid red",
                                             }}
                                             id="inputState"
                                             className="form-select"
                                             name="area"
                                             onChange={handleOnCahange}
                                          >
                                             <option value="Select">{words.SELECT_AREA[language]}</option>
                                             {areas?.map((area, index) => {
                                                return (
                                                   <option
                                                      value={area?.id}
                                                      key={index}
                                                   >
                                                      {area?.name}
                                                   </option>
                                                );
                                             })}
                                          </select>
                                       </div>
                                       
                                       <div className="col-sm-12 col-lg-4 mb-3">
                                          <label htmlFor="inputState">{words.STATE[language]}</label>
                                          <input
                                             style={{
                                                border: submit && !userData?.state && "1px solid red",
                                             }}
                                             name={"state"}
                                             onChange={handleOnCahange}
                                             value={userData?.state}
                                             type="text"
                                             className="form-control"
                                             placeholder={words.STATE[language]}
                                          />
                                       </div>

                                       {/* <div className="col-6 mb-3">
                      <label>Note to seller</label>
                      <textarea 
                      name={"notes"}
                      onChange={handleOnCahange}
                      value={userData?.notes}
                      className="form-control" placeholder="Type if anything"></textarea>
                    </div> */}
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* <div className="user_srvShipping mt-5 d-flex justify-content-between align-items-center  border-bottom pb-4">
                <div className="user_profile d-flex justify-content-between align-items-center w-100">
                  <div className="info___ d-flex">
                    <div className="image_profile">
                      <img src={prfofileImg} alt="imageProfile shadow-sm" />
                    </div>

                    <div className="info_user px-3">
                      <div className="pb-2 d-flex justify-content-start align-items-center gap-4">
                        <span className="user fw-semibold fs-5">Gareth Barry</span>
                        <span className="d-flex justify-content-center align-items-center gap-2 mob_num">
                          +1 218 128 991 <img src={phoneIcon} alt="phoneIcon" />
                        </span>
                      </div>
                      <p className="d-flex justify-content-center align-items-center gap-2 addrss">
                        <img src={HomeIcon} alt="HomeIcon" />
                        5690 Matilda Green Suite 627, New York, 12345
                      </p>
                    </div>
                  </div>
                  <button className="btn edit_btn d-flex justify-content-center align-items-center gap-2 fw-semibold">
                    <img src={CompinedSh} alt="CompinedSh" /> Edit info
                  </button>
                </div>
              </div> */}
                     {/* <ShippService
                title="Express Expedition"
                subTitle="Estimated delivery time: Jul 20 - Aug 03"
                currency={
                  <p>
                    $35.00 <span className="text-decoration-line-through disc">$45.00</span>
                  </p>
                }
                imageService={servEXPRESS}
                labelFor="Express"
              /> */}
                  </div>
                  {/* <div className="method_payment bg-white mb-3 shadow-sm rounded-3 p-3 pt-4">
              <div className="h_title d-flex justify-content-between align-items-center mb-3">
                <h4 className="fw-semibold">Payment method</h4>
                <button className="btn fw-semibold change_meth">Change method</button>
              </div>
              <div className="info_method d-flex align-items-center justify-content-between rounded-3 p-4 flex-wrap gap-2">
                <div className="details_card d-flex align-items-center gap-4">
                  <div className="image_cardPay">
                    <img src={cardPay} alt="card" />
                  </div>
                  <div className="text_tit">
                    <p className="fw-semibold fs-5">Credit Card</p>
                    <p className="card_expiry"> **** 7282 - Expired 8/2022</p>
                  </div>
                </div>
                <button className="btn edit_btn d-flex justify-content-center align-items-center gap-2 fw-semibold">
                  <img src={CompinedSh} alt="CompinedSh" /> Edit info
                </button>
              </div>
            </div> */}

                  {/* <div className="bg-white mb-3 shadow-sm rounded-3 p-3 pt-4">
              <h4 className="fw-semibold">Items cart</h4>
              <p className="sub_title">Choose what service you want for your transaction</p>

              <>
                <ListCarts />
              </>
            </div> */}
               </div>

               <div className="col-sm-12 col-lg-4">
                  <div className=" bg-white pt-4 shadow-sm rounded-3 ">
                     <h4 className="fs-5 px-3 pb-3">{words.ORDER_SUMMARY[language]}</h4>
                     <DataSummary />
                     {/* <div className="px-3">
                <div className="applied d-flex justify-content-between align-items-center rounded-3">
                  <span className="fw-semibold text-capitalize px-2">ELMA50</span>
                  <span className="apply rounded-3 fw-medium">Applied</span>
                </div>
                <hr className="w-100 line_" />
              </div> */}
                     <form className="note px-3">
                        <span className="title fw-bold">{words.WRITE_A_NOTE[language]}</span>
                        <textarea
                           name="w3review"
                           rows="5"
                           cols="50"
                           className="form-control border-0 mt-2"
                           placeholder={words.NOTE_DESC[language]}
                        ></textarea>
                        <hr className="w-100 line_not" />
                     </form>
                     {/* <p className="d-flex justify-content-between align-items-center pb-3 px-3 fw-bold">
                <span>Order Total</span>
                <span>$1,610.00</span>
              </p> */}
                  </div>
                  <div className="L-routes mt-4">
                  <Link to="/review" className="btn w-100 _review mb-2">
                <img src={iconRevieww} alt="review"  crossorigin="anonymous"/> {words.REVIEW_YOUR_ORDER[language]}
              </Link>
                     <Link
                        to="/"
                        className="btn w-100 b_info"
                     >
                        <BsArrowLeft /> {words.BACK_TO_CUSTOMER_INFO[language]}
                     </Link>
                  </div>
               </div>
            </div>
            <OurFeature />
         </div>
      </ReviewStyle>
   );
};

export default Review;
