import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../../Store/reducers/products";
import { Similar } from "./StyleSiimilar";
import words from "../../../assets/words";
import  {nameToSlug} from "../../../utils/general"

const SimilarProduct = ({ product }) => {
  //navigate
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products?.data);
  const language = useSelector((state) => state?.auth?.language);

  useEffect(() => {
    dispatch(
      getProducts({
        category: product?.category?.id,
        brand: product?.brand?.id,
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Similar>
      <h4 className="title pb-3">{words.SMILIER_PRODUCTS[language]}</h4>
      <ul className="list_products">
        {products?.slice(0, 3)?.map((item, index) => {
          return (
            <li
              className="d-flex justify-content-start align-items-center gap-3 mb-4"
              key={index}
              onClick={() => {
                navigate(`/product-details/${item?.id}/${nameToSlug(item.name)}`);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image_prod border border-1 p-3 rounded-3">
                <img src={item?.files?.[0] ? item?.files?.[0] : item?.images?.[0]?.images?.[0]} alt="productShorts" crossorigin="anonymous" />
              </div>
              <div className="txt_product">
                <p className="title_ pb-2">{item?.name}</p>
                <p className="price">{item?.priceTo} {words.SR[language]}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </Similar>
  );
};

export default SimilarProduct;
