import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getColors = createAsyncThunk(
  "colors/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/colors/withoutPagenation/get`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



const colorsSlice = createSlice({
  name: "colors",
  initialState: {
    colors: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //get colors
    [getColors.pending]: (state, action) => {
      state.loading = true;
    },
    [getColors.fulfilled]: (state, action) => {
      state.loading = false;
      state.colors = action.payload;
    },
    [getColors.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = anoncementsSlice.actions;

export default colorsSlice.reducer;
