import React from "react";
import { useSelector } from "react-redux";
import ProdctCrd from "../ReusableComponent/CardProduct/CardProduct";

import { FlashSaleStyle } from "./StyledFlashSale";
import words from "../../assets/words";

const FlashSale = ({products}) => {
  // const products = useSelector(state => state.products.products?.data)
  const user = useSelector(state => state.auth.user)
  const language = useSelector(state => state.auth.language)

  return (
    <>
      <FlashSaleStyle className="container" dir={language === "en" ? "ltr" : "rtl"}>
        <div className="col title_flashSale rounded-3 mb-3">
          <h3 className="fw-normal text-uppercase">
            {words.FLASH_SALE[language]} <span className="fs-6">{words.UP_TO_70[language]}</span>
          </h3>
        </div>
        <>
          <div className="row">
            {products?.map(product => {
              return  (
                <div
                  className="col-sm-12 col-md-6 col-lg-3 mt-3 d-flex justify-content-center align-items-center"
                  key={product.id}
                >
                  <ProdctCrd
                    addToFav={(user?.type === "USER" || !user) ? true : false}
                    offerPaddge={true}
                   item={product}
                  />
                </div>
            )})}
          </div>
        </>
      </FlashSaleStyle>
    </>
  );
};

export default FlashSale;
