import { useEffect, useState } from "react";
import classes from "./Properties.module.scss";
import { BsPlusSquareDotted } from "react-icons/bs";
import CustomModal from "../../customModal/CustomModal";
import { useSelector } from "react-redux";
import words from "../../../assets/words";

const Properties = ({ properties, setProperties }) => {
  const language = useSelector((state) => state.auth.language);
  const [showModal, setShowModal] = useState(false);
  const [property, setProperty] = useState({
    property_en: "",
    property_ar: "",
  });
  const [found, setFound] = useState("");
  const [noError, setNoError] = useState(false);

  useEffect(() => {
    if (property.property_en && property.property_ar) {
      setNoError(false);
    } else {
      setNoError(true);
    }
  }, [property]);


  const handleOnChange = (e) => {
    setProperty({ ...property, [e.target.name]: e.target.value });
  };

  const addProperty = () => {
    const findIndex = properties?.findIndex(
      (item) => item.property_en === property.property_en
    );
    if (findIndex === -1) {
      //if the key is not found in the array
      setProperties((prev) => [...prev, property]);
      setShowModal(false);
      setFound("");
      setProperty({});
    } else {
      setFound(words.PROPERTY_IS_EXIST[language]);
    }
  };

  return (
    <div className={classes.properties}>
      {showModal && (
        <CustomModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title={words.PROPERTIES[language]}
          size={"lg"}
        >
          <p className="text-center text-danger">{found}</p>
          <div
          dir={language === "ar" ? "rtl" : "ltr"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "0px 20px",
            }}
            onChange={handleOnChange}
          >
            <div className="mb-3 w-100">
              <p>{words.PROPERTY_EN[language]}</p>
              <input
                placeholder={words.PROPERTY_EN[language]}
                name="property_en"
                className="form-control"
              />
            </div>
            <div className="w-100">
              <p>{words.PROPERTY_AR[language]}</p>
              <input
                placeholder={words.PROPERTY_AR[language]}
                name="property_ar"
                className="form-control"
              />
            </div>
            <button
              className="btn"
              onClick={addProperty}
              style={{
                backgroundColor: "#4a3e4c",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                marginTop: 10,
                padding: "5px 30px",
              }}
              disabled={noError}
            >
              <BsPlusSquareDotted />
              {words.ADD[language]}
            </button>
          </div>
        </CustomModal>
      )}
      <button className="btn" onClick={() => setShowModal(true)}>
        <BsPlusSquareDotted />
        {words.PROPERTIES[language]}
      </button>
    </div>
  );
};

export default Properties;
