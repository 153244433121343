import UpdateProfile from "../../PagesUser/updateProfile/UpdateProfile";

const VendorProfile = () => {
  return (
    <>
      <UpdateProfile />
    </>
  );
};

export default VendorProfile;
