import React, { useState } from "react";
import { useEffect } from "react";
import { CiMemoPad } from "react-icons/ci";
import { FaHome } from "react-icons/fa";
import { FcHome, FcShipped } from "react-icons/fc";
import { MdCancel, MdLocalShipping } from "react-icons/md";
import { TbMessageCircle2 } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import OurFeature from "../../Components/ReusableComponent/ourFeature/OurFeature";
import { getOrderById } from "../../Store/reducers/orders";
import HeaderCheckOut from "./../../Components/Checkout/headerCheckout/headerCheckOut";
import ProgressBar from "./../../Components/Checkout/ProgressBarCheck/ProgressBar";
import { TrackStyle } from "./StyleTrackOrder";

import "./track.css";
import words from "../../assets/words";

const TrackOrder = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const order = useSelector(state => state.orders.order?.data);
  const language = useSelector(state => state.auth.language);

  const orderStatus = ['ACCEPTED', 'OUT-FOR-DELIVERY', 'DELIVERED']
  const [currentStatus, setCurrentStatus] = useState("")

  useEffect(()=>{
    dispatch(getOrderById(id))
  },[id])

  useEffect(()=>{
    if(order){
      const index = orderStatus?.findIndex((one) => one == order?.status)
      setCurrentStatus(index)
    }
  },[order])
  // console.log(currentStatus)


  return (
    <TrackStyle 
    dir={language === "ar" ? "rtl" : "ltr"}
    >
   
      <HeaderCheckOut title={words.TRACK_YOUR_ORDER[language]} />
      <ProgressBar number={3}/>
      {!order ? (
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            width: "100%",
            height: "calc(95vh - 46px)",
          }}
        >
          <BeatLoader color="rgb(1, 73, 124)" />
        </div>
      ) : (
        <div className="container" >
        <div className="row p-5 bg-white rounded shadow-sm mb-5">
          <div className="info_order d-flex justify-content-between align-items-start mb-5">
            <p className="order_id fw-medium text-uppercase">
              {words.ORDER_ID[language]}:
              <span className="id_num px-1 fw-bold" style={{ color: "#4a3e4c" }}>
                {order?.id}
              </span>
            </p>
          
          </div>

          {/* Progressbar for orders  */}
          <div className="lists_prog" dir="ltr">
            <ol className="progress_bar_track row">
              {
                orderStatus?.map((value, index)=>{
                  return(
                    <li className={`${index <= currentStatus ? "col step-done" : "col step-todo"}`}>
                    <span className="circll"></span>
                  </li>
                  )
                })
              }
            </ol>

            <div className="row d-flex justify-content-center align-items-center mt-4"
            style={{
              direction: "ltr"
            }}
            >
              <ul className="list_icons ">
                <li className="item d-flex align-items-center gap-2">
                  <CiMemoPad />
                  <p className="d-flex flex-column fw-medium">
                    <span>{words.ORDER_ACCEPTED[language]}</span>
                  </p>
                </li>
                <li className="item d-flex align-items-center gap-2">
                  {/* <FcShipped /> */}
                  <MdLocalShipping />
                  <p className="d-flex flex-column fw-medium">
                    <span>{words.OUT_OF_DELIVERY[language]}</span>
                  </p>
                </li>
                <li className="item d-flex align-items-center gap-2">
                  {/* <FaHome /> */}
                  <FcHome />
                    <p className="d-flex flex-column fw-medium">
                    <span>
                      {words.ORDER_ARRIVED[language]}
                    </span>
                  </p>
                </li>
              
                {/* <li className="item d-flex align-items-center gap-2">
                  <MdCancel color={"red"} />
                  <p className="d-flex flex-column fw-medium">
                    <span>Order</span>
                    <span>Canceled</span>
                  </p>
                </li> */}
              </ul>
            </div>
          </div>

          {/* Chat with seller */}
          {/* <div className="d-flex justify-content-center align-items-center">
            <button className="btn chat_seller px-5">
              <TbMessageCircle2 /> Chat with seller
            </button>
          </div> */}
        </div>

        <OurFeature />
      </div>
      )}
      
    </TrackStyle>
  );
};

export default TrackOrder;
