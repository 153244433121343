import React from "react";
import styled from "styled-components";
import Table from "../../../Components/table/Table";
import { useState, useEffect } from "react";
import classes from "./DriversReplies.module.scss"
import userImg from "../../../assets/Images/user-placeholder.png"
import { cutWords } from "../../../utils/formatWords";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import words from "../../../assets/words";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDriversOfferByOrderId, acceptDriversOfferByOrderId } from "../../../Store/reducers/orders";
import CustomModal from "../../../Components/customModal/CustomModal";
import cancel from "../../../assets/Images/delete.png";
import { Dropdown, Spinner } from "react-bootstrap";

const AcceptButton = ({ data }) =>
{
  const dispatch = useDispatch();

  const { id } = useParams();

  const language = useSelector((state) => state.auth.language);

  const [acceptModal, setAcceptModal] = useState(false)

  const handleAccept = () =>
  {
    dispatch(acceptDriversOfferByOrderId(data.id)).then((res) =>
    {
      if (res?.meta?.requestStatus !== "rejected")
      {
        dispatch(getDriversOfferByOrderId(id));
        setAcceptModal(false);
      }
    });
  }

  return <>
    <button className="btn d-flex gap-2 align-items-center"
      onClick={() => setAcceptModal(true)}
      style={{
        backgroundColor: "#05da73",
        color: "#fff"
      }}>
      <FaMoneyCheckAlt />
      {words.ACCEPT[language]}
    </button>

    {acceptModal && (
      <CustomModal
        show={acceptModal}
        onHide={() => setAcceptModal(false)}
        title={`${words.ACCEPT_OFFER[language]} #${data?.id} ?!`}
      >
        <div className="text-center">
          <img
            src={cancel}
            alt="check pic"
            style={{ width: "50%" }}
            crossorigin="anonymous"
          />
          <p className="mt-3">{words.ARE_YOU_WANT_TO_ACCEPT_OFFER[language]}</p>
          <button
            className="btn text-white w-50 mt-4"
            onClick={handleAccept}
            style={{
              backgroundColor: "RGBA(74, 62, 76, 0.8)",
            }}
          >
            {words.ACCEPT_OFFER[language]}
          </button>
        </div>
      </CustomModal>
    )}
  </>
}

const DriversReplies = () =>
{

  const language = useSelector((state) => state.auth.language);
  const dispatch = useDispatch();

  const { id } = useParams();
  const drivers = useSelector((state) => state.orders.drivers);
  // const loader = useSelector((state) => state.orders.loading);
  // const statusLoader = useSelector(state => state.orders.cancelLoading)
  // const language = useSelector((state) => state.auth.language);

  console.log(id, drivers, 'sss')

  useEffect(() =>
  {
    dispatch(
      getDriversOfferByOrderId(id)
    );
  }, [id]);
  const [data, setData] = useState([
    {
      name: "shrouk khaled",
      email: "shrouk@gmail.com",
      phoneNumber: "0114465828",
      offer: "300 SR"
    },
    {
      name: "shrouk khaled",
      email: "shrouk@gmail.com",
      phoneNumber: "0114465828",
      offer: "300 SR"
    },
    {
      name: "shrouk khaled",
      email: "shrouk@gmail.com",
      phoneNumber: "0114465828",
      offer: "300 SR"
    },
    {
      name: "shrouk khaled",
      email: "shrouk@gmail.com",
      phoneNumber: "0114465828",
      offer: "300 SR"
    },
    {
      name: "shrouk khaled",
      email: "shrouk@gmail.com",
      phoneNumber: "0114465828",
      offer: "300 SR"
    },
    {
      name: "shrouk khaled",
      email: "shrouk@gmail.com",
      phoneNumber: "0114465828",
      offer: "300 SR"
    },
  ]);



  const columns = [
    {
      cell: row => <div data-tag="allowRowEvents">
        <img
          crossorigin="anonymous"
          src={userImg}
          alt="user Pic"
          className="avatar"
          data-tag="allowRowEvents"
          style={{ width: '50px', padding: 0, borderRadius: "50%" }}
        />
        <div>
          {row.driver.firstname + ' ' + row.driver.lastname}
        </div>
      </div>,
      name: words.DRIVER_NAME[language],
      selector: row => row.name,
      sortable: true,
      wrap: true
    },
    {
      name: words.EMAIL[language],
      selector: row => row.email || '--',
      sortable: true,
      wrap: true
    },
    {
      name: words.PHONE_NUMBER[language],
      selector: row => row.phoneNumber || '--',
      sortable: true,
      wrap: true
    },
    {
      name: words.OFFER[language],
      selector: row => row.cost,
      sortable: true,
      wrap: true
    },
    {
      cell: row =>
        <div className="d-flex gap-2">
          <AcceptButton data={row} />
        </div>
      ,
      // width: '65px',
      name: words.ACTION[language],
      sortable: true,
      wrap: true
    },
  ];

  return (
    <StyleProd className="container-fluid">
      <div className="row" >

        <div className={`${classes.container} col-12 side_content pt-2`}>
          {/*List Products =====*/}
          <div className="pt-1 mb-3">
            <Table columns={columns} data={drivers?.data} noData={words.NO_DATA_FOUND_FOR_DISPLAY[language]} />
          </div>
        </div>
      </div>


    </StyleProd>
  );
};

export default DriversReplies;

const StyleProd = styled.div`
  .side_menu_dash {
    .inf_user {
      background-color: #f7f6fa;
      padding: 15px;
      border-radius: 15px;
      margin-bottom: 3rem;
    }
  }
  .side_content {
    min-height: 100vh;
    background-color: #f7f6fa;
    border-radius: 10px;
    .inp_search {
      min-width: 500px;
      .form-control {
        padding-left: 50px !important;
        background-color: #f0eff3;
        border-color: transparent;
        &:focus {
          box-shadow: none;
          border-color: #4a3e4c;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        font-size: 22px;
      }
    }
    .btn-secondary {
      background-color: #f0eff3;
      border: none;
      outline: none;
      color: #000;
    }
    .logNotifMess {
      .inf_user {
        svg {
          display: none;
        }
      }
      .title_ {
        padding-right: 10px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .icon_ {
      background-color: #f0eff3;
      color: #dddce2;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      width: 55px;
      padding: 10px;
    }

    // Style filter
    .filters {
      .sort {
        svg {
          font-size: 20px;
          margin-left: 5px;
        }
      }
    }
  }
  .copyRight {
    color: #aaabb1;
  }
`;
