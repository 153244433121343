import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

const user = localStorage.getItem("userId");

export const getProducts = createAsyncThunk(
  "products/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products/?hasOffer=${
        data?.hasOffer ? data?.hasOffer : ""
      }&category=${data?.category ? data?.category : ""}&brand=${
        data?.brand ? data?.brand : ""
      }&subCategory=${data?.subCategory ? data?.subCategory : ""}&search=${
        data?.search ? data?.search : ""
      }&rate=${data?.rate ? data?.rate : ""}&owner=${data?.owner ? data.owner : ""}&priceFrom=${
        data?.priceFrom ? data?.priceFrom : ""
      }&priceTo=${data?.priceTo ? data?.priceTo : ""}&sortByPrice=${
        data?.sortByPrice ? data?.sortByPrice : ""
      }&page=${
        data?.page ? data?.page : 1
      }&userId=${data?.userId ? data.userId : ""}
      
      `;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProduct = createAsyncThunk(
  "product/get",
  async ({ id = "", userId = "" }, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products/${id}/?userId=${userId ? userId : ""}`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addProduct = createAsyncThunk(
  "producpot/post",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products`;
      const response = await axios.post(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProduct = createAsyncThunk(
  "producpot/put",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products/${data?.id}`;
      const response = await axios.put(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const removeProduct = createAsyncThunk(
  "products/delete",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products/${id}`;
      const response = await axios.delete(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadImages = createAsyncThunk(
  "images/upload",
  async (data, { getState, rejectWithValue }) => {
    const formData = new FormData()
    for(let i = 0 ; i < data.images?.length ; i++){
      formData.append("images", data.images[i])
    }

    try {
      const url = `${BASE_URL}/upload`;
      const response = await axios.post(url, formData, 
        {
          headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${getState().auth.token}`,
					},
        });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getReviewsProduct = createAsyncThunk(
  "product/getReviews",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/rate?product=${id}`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addProductOffer = createAsyncThunk(
  "product-offer/post",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products/${data?.id}/offer`;
      const response = await axios.put(url,{
        offerRatio: data?.offerRatio
      }, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeProductOffer = createAsyncThunk(
  "product-offer/remove",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/products/${id}/offer`;
      const response = await axios.delete(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: null,
    product: null,
    loading: false,
    productLoading: false,
    removeLoading: false,
    reviewsProducts: null,
    removeOfferLoader: false,
    updateLoader: false,
    addOfferLoader: false,
    dashLoading: false,
  },
  reducers: {
    clearProducts: (state, action) => {
      state.products = [];
    },
    clearProduct: (state, action) => {
      state.product = null;
    }
  },
  extraReducers: {
    //get products
    [getProducts.pending]: (state, action) => {
      state.loading = true;
      state.dashLoading = true;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashLoading = false;
      state.products = action.payload;
    },
    [getProducts.rejected]: (state, action) => {
      state.loading = false;
      state.dashLoading = false;
    },

    //get product
    [getProduct.pending]: (state, action) => {
      state.loading = true;
      state.productLoading = true;
      // state.product = null;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.productLoading = false;
      state.product = action.payload;
    },
    [getProduct.rejected]: (state, action) => {
      state.loading = false;
      state.productLoading = false;
    },

    //get reviews products
    [getReviewsProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [getReviewsProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewsProducts = action.payload;
    },
    [getReviewsProduct.rejected]: (state, action) => {
      state.loading = false;
    },

     //add product
     [addProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addProduct.rejected]: (state, action) => {
      state.loading = false;
    },
    //update product
    [updateProduct.pending]: (state, action) => {
     state.updateLoader = true;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.updateLoader = false;
    },
    [updateProduct.rejected]: (state, action) => {
      state.updateLoader = false;
    },

      //delete offer
      [removeProductOffer.pending]: (state, action) => {
        state.removeOfferLoader = true;
      },
      [removeProductOffer.fulfilled]: (state, action) => {
        state.removeOfferLoader = false;
      },
      [removeProductOffer.rejected]: (state, action) => {
        state.removeOfferLoader = false;
      },

       //add offer
       [addProductOffer.pending]: (state, action) => {
        state.addOfferLoader = true;
      },
      [addProductOffer.fulfilled]: (state, action) => {
        state.addOfferLoader = false;
      },
      [addProductOffer.rejected]: (state, action) => {
        state.addOfferLoader = false;
      },

      //delete product
      [removeProduct.pending]: (state, action) => {
        state.removeLoading = true;
      },
      [removeProduct.fulfilled]: (state, action) => {
        state.removeLoading = false;
      },
      [removeProduct.rejected]: (state, action) => {
        state.removeLoading = false;
      }
  },
});

export const {clearProduct, clearProducts} = productsSlice.actions;

export default productsSlice.reducer;
