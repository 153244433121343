import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DataSummstyle } from "./StyleDataSumm";
import words from "../../../assets/words";

const DataSummary = ({ page, coupon }) => {
  const shoppingCart = useSelector((state) => state?.carts?.reduxCarts);
  const language = useSelector((state) => state.auth.language);
  const discount = useSelector(state => state.carts?.discount)
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let totalPrice = 0;
    shoppingCart.map((item) => {
      totalPrice += Number(item.quantity) * Number(item.price);
    });
    setTotal(totalPrice);
  }, [shoppingCart]);


  return (
    <DataSummstyle className="p-3 fw-normal">
      
      {page !== "cart" && (
        <>
        <li className="d-flex justify-content-between align-items-center mb-2">
        <span>{words.SUB_TOTAL[language]}</span>
        <span>{total} {words.SR[language]}</span>
      </li>
          {/* <li className="d-flex justify-content-between align-items-center mb-2">
            <span>Shipping</span>
            <span className="val_discount">-</span>
          </li>
          <li className="d-flex justify-content-between align-items-center mb-2">
            <span>Tax</span>
            <span className="val_tax">-</span>
          </li> */}
          </>
      )}
          <li className="d-flex justify-content-between align-items-center mb-2 border-bottom pb-3">
            <span>{words.DISCOUNT[language]}</span>
            <span className="val_discount">{discount ? discount?.discount + "%" : "0"}</span>
          </li>
      

      <li className="d-flex justify-content-between align-items-center mb-2">
        <span>{words.TOTAL[language]}:</span>
        <span className="fw-medium fs-5">{discount ? Number(total - ((total * discount?.discount) / 100)) : total} {words.SR[language]}</span>
      </li>
    </DataSummstyle>
  );
};

export default DataSummary;
