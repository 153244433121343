import DataTable from "react-data-table-component";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "../Pagination/Pagination";
// import Pagination from './Pagination';

const customStyles = {
  tableWrapper: {
    style: {
      display: "block",
      backgroundColor: "blue",
    },
  },
  header: {
    style: {
      fontSize: "56px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#f7f6fb",
    },
  },
  head: {
    style: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#bbb7bf",
      textAlight: "center",
      borderBottom: "1px solid #4a3e4c",
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
      textAlight: "center",
    },
  },
  rows: {
    style: {
      minHeight: "80px",
      fontSize: "15px",
      width: "100%",
      cursor: "pointer",
      backgroundColor: "#fff",
    //   margin: "10px",
      "&:not(:last-of-type)": {
        border: "none",
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: "#f7f6fb",
      width: "100%",
      maxWidth: "100%",
      minWidth: "fit-content",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      alignItems: "center",
      textAlight: "center",
      width: "100%",
      // minWidth: "130px",

      div: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlight: "center",
        width: "100%",
      },
    },
  },
  // pagination: {
  // 	style: {
  //         width: "100%"
  //     }
  // }
  // cells: {
  //     style: {
  //         '&:not(:last-of-type)': {
  //             borderRightStyle: 'solid',
  //             borderRightWidth: '1px',
  //             borderRightColor: defaultThemes.default.divider.default,
  //         },
  //     },
  // },
};

const Table = ({
  columns,
  data = [],
  onRowClicked,
  noHeader = false,
  loading,
  noData = "No Data",
  totalDocuments,
  viewLimit,
  currentPage,
  noPagination = false,
  itemName,
  changePage,
  conditionalRowStyles,
  onRowMouseEnter,
  onRowMouseLeave,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "10px"
      }}
    >
      {data.length > 0 ? (
        <>
          <DataTable
            columns={columns}
            data={data}
            onRowClicked={onRowClicked}
            noDataComponent={noData}
            highlightOnHover
            pagination={false}
            noTableHead={noHeader}
            fixedHeader={true}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            progressPending={loading}
            progressComponent={
              <Spinner as="span" animation="border" variant="primary" />
            }
            onRowMouseEnter={onRowMouseEnter}
            onRowMouseLeave={onRowMouseLeave}
            responsive={true}
          />
          {/* {!noPagination && (
            <Pagination
              data={data}
              totalDocuments={totalDocuments}
              viewLimit={viewLimit}
              currentPage={currentPage}
              itemName={itemName}
              changePage={changePage}
            />
          )} */}
        </>
      ) : (
        <>
          {loading ? (
            <Spinner as="span" animation="border" variant="primary" />
          ) : (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#4a3e4c",
                fontWeight: "normal",
              }}
            >
              {noData}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
