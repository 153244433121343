import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { RiFacebookBoxFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import backLogin from "../../../assets/Images/backLogin.png";
import logoGoogle from "../../../assets/Images/logo-google.png";
import logoSign from "../../../assets/Images/Logo.png";
import check from "../../../assets/Images/verfiy.png";
import CustomModal from "../../../Components/customModal/CustomModal";
import BottomBar from "../../../Components/ReusableComponent/Footer/bottomBar/BottomBar";
import { checkSocaiLogin, postSignIn, saveLanguage } from "../../../Store/reducers/auth";
import { SignInStyle } from "./StyleSignIn";
import { getCountries } from "../../../Store/reducers/countries";
import forgot from "../../../assets/Images/forgot.png";
import VerifyAccount from "../verfiyAccount/VerfiyAccount";
import { FacebookLogin, GoogleLogin } from "../../../utils/socialLogin";
import words from "../../../assets/words";

const SignIn = () => {
  //navigate
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const countries = useSelector(state => state.countries.countries)
  const language = useSelector(state => state.auth.language)
  const auth = useSelector((state) => state.auth);
  const loader = auth.loading;
  //states
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const [showModals, setShowModals] = useState({
    showVerfify: false,
    confirmOtp: false,
    forgotPassword: false,
    notMember: false,
  });
  const [changePhone, setChangePhone] = useState("");
  const [country, setCountry] = useState("");
  const [response, setResponse] = useState(null);

  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(response){
      dispatch(checkSocaiLogin({
        socialId: response?.sub,
      })).then((res)=>{
        if(res?.meta?.requestStatus == "rejected"){
          setShowModals({...showModals, notMember: true})
        }else{
          navigate("/")
        }
      })
    }
  },[response])

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      // country: "",
      token: "dfgnkjdngjknkkjhhklfdjkkjfg",
    },

    validationSchema: Yup.object({
      phone: Yup.string().required(
        words.PHONE_IS_REQUIRED[language]
      ),
      // country: Yup.string().required("Country code is required."),
      password: Yup.string().required(words.NO_PASS_PROVIDED[language]),
    }),
    onSubmit: (values) => {
      dispatch(postSignIn(values)).then((res) => {
        if (res?.meta?.requestStatus === "rejected") {
          setError(words.INVALID_PHONE_OR_PASS[language]);
        } else {
          if (res?.payload?.data?.accountType == "ACTIVE") {
            if (
              (res?.payload?.data?.type === "VENDOR" &&
                res?.payload?.data?.verify) ||
              res?.payload?.data?.type === "USER"
            ) {
              setError("");
              navigate("/");
            } else {
              setError(words.YOUR_ACCOUNT_NOT_VERFIED[language]);
            }
          } else {
            setUserId(res?.payload?.data?.id);
            setError("");
            setShowModals({ ...showModals, showVerfify: true });
          }
        }
      });
    },
  });

  return (
    <>
      <SignInStyle className="_signIn_" dir={`${language=== "en"? "ltr": "rtl"}`}>
        {showModals.showVerfify && (
          <CustomModal
            show={showModals.showVerfify}
            onHide={() => {
              setShowModals({ ...showModals, showVerfify: false });
            }}
            title={words.CHECK_YOUR_PHONE_NUMBER[language]}
          >
            <div className="text-center">
              <img src={check} alt="check pic" style={{ width: "250px" }} />
              <p className="mt-3">
                {words.YOUR_ACCOUNT_NOT_VERFIY[language]}
              </p>
              <button
                className="btn text-white w-50 mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  // navigate("/");
                  navigate(`/verify-account/${userId}`);
                }}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
              >
                {words.YES[language]}
              </button>
            </div>
          </CustomModal>
        )}
         {showModals.notMember && (
          <CustomModal
            show={showModals.notMember}
            onHide={() => {
              setShowModals({ ...showModals, notMember: false });
            }}
            title={words.YOU_MUST_SIGN_UP_FIRST[language]}
          >
            <div className="text-center">
              <img src={check} alt="check pic" style={{ width: "250px" }} />
              <p className="mt-3">
                {words.YOU_ARE_NOT_A_MEMBER[language]}, {words.YOU_MUST_SIGN_UP_FIRST[language]}
              </p>
              <button
                className="btn text-white w-50 mt-4"
                onClick={(e) => {
                 setShowModals({ ...showModals, notMember: false });
                }}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
              >
                {words.OK[language]}
              </button>
            </div>
          </CustomModal>
        )}
        {showModals.forgotPassword && (
          <CustomModal
            show={showModals.forgotPassword}
            onHide={() => {
              setShowModals({ ...showModals, forgotPassword: false });
            }}
            title={words.FORGET_PASSWORD[language]}
          >
            <div className="text-center">
              <img src={forgot} alt="check pic" style={{ width: "250px" }} />
              <div className="text-start my-3">
                <p className="mt-3">
                  {words.ENTER_PHONE_NUMBER[language]} <span className="text-danger">*</span>
                </p>
                <div className="d-flex gap-2 justify-content-center align-items-center mt-3">
                <select
                  id="inputState"
                  style={{ width: "30%"}}
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                  className="form-select"
                  name="country"
                >
                  <option value="Select">{words.SELECT_CODE[language]}</option>
                  {countries?.map((country, index) => {
                    return (
                      <option value={country?.id} key={index}>
                        {country?.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  placeholder={words.PHONE_NUMBER[language]}
                  className="form-control"
                  type="text"
                  name="phone"
                  value={changePhone}
                  onChange={(e) => setChangePhone(e.target.value)}
                  onKeyDown={(e)=>{
                    if(e.key === "Enter"){
                      navigate(`/verify-account/${changePhone}/${country}/forgot-password`);
                    }
                  }}
                />
                </div>
                
              </div>

              <button
                className="btn text-white w-50 my-4"
                onClick={() => {
                  navigate(`/verify-account/${changePhone}/${country}/forgot-password`);
                }}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
                disabled={changePhone && country ? false : true} 
              >
                {words.CONTINUE[language]}
              </button>
            </div>
          </CustomModal>
        )}
        <img
          src={backLogin}
          alt="imageBack"
          className={`backimgForm ${language==="ar"? "back-img-form-ar": null}`}
          crossorigin="anonymous"
          
        />
        <span className={`overlay ${language==="ar"? "overlay-ar": null}`}></span>
        <div className="row align-align-items-stretch justify-content-around w-100">
          <div className="col-sm-12 col-lg-5 img_sign ">
            <div className="container">
              <div className="cont_head d-flex flex-column justify-content-start align-items-center gap-5 pt-5">
                <div className="text text-center">
                  <h4 className="mb-4">{words.SHOP_ALL_IN_ONE_PLACE[language]}</h4>
                  <h2 className="">#{words.FIRST_IN_SUADI_ARABIA[language]}</h2>
                </div>
                <img src={logoSign} alt="logo" crossorigin="anonymous" />
              </div>
            </div>
          </div>

          <form
            className="col-sm-12 col-lg-5 f-cnt"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-control shadow-sm">
              <div className="container">
                <h2 className="py-3">{words.SIGNIN[language]}</h2>
                <div className="mb-3">
                  <label className="form-label">{words.PHONE_NUMBER[language]}</label>
                  <div className="d-flex gap-1">
                    {/* <select id="inputState"
                  style={{width: "20%"}}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                      className="form-select" name="country" >
                      <option value="Select" >Select code</option>
                        {
                          countries?.map((country, index)=>{
                            return(
                             <option value={country?.id} key={index}>{country?.name}</option>
                            )
                          })
                        }
                      </select> */}
                    <input
                      type="text"
                      name="phone"
                      className="form-control w-100"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      placeholder={words.PHONE_NUMBER[language]}
                    />
                  </div>

                  {formik.touched.phone && formik.errors.phone ? (
                    <div
                      className="msg_error"
                      style={{ color: "red", display: "block" }}
                    >
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-label ">{words.PASSWORD[language]}</label>
                    <p
                      onClick={() =>
                        setShowModals({ ...showModals, forgotPassword: true })
                      }
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {words.FORGET_PASSWORD[language]}
                    </p>
                  </div>
                  <input
                    name="password"
                    type="password"
                    className="form-control w-100"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    placeholder={words.PASSWORD[language]}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div
                      className="msg_error"
                      style={{ color: "red", display: "block" }}
                    >
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
                {error && (
                  <div>
                    <p className="text-danger">{error}</p>
                  </div>
                )}
                {/* <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    {words.REMEMBER_ME[language]}
                  </label>
                </div> */}
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-5"
                  disabled={loader}
                >
                  {loader ? <Spinner animation="border" /> : 
                  words.LOGIN_IN[language]
                    }
                </button>
                <div className="_or d-flex justify-content-center align-items-center my-3 position-relative">
                  <hr className="w-75" />
                </div>

                <div className="sign_with">
                  <GoogleLogin response={response} setResponse={setResponse}>
                  <div className="btn_google btn w-100">
                    <img
                      src={logoGoogle}
                      alt="logoGoogle"
                      crossorigin="anonymous"
                    />
                    <span className="px-3">
                      {words.COUNTINUE_WITH_GOOGLE[language]}
                    </span>
                  </div>
                  </GoogleLogin>
              
              <FacebookLogin response={response} setResponse={setResponse}>
              <div className="btn_faceboK btn w-100 mt-2">
                    <RiFacebookBoxFill />
                    <span className="px-3">
                      {words.COUNTINUE_WITH_FACEBOOK[language]}
                    </span>
                  </div>
              </FacebookLogin>
             
                </div>

                <div className="foot_form d-flex justify-content-center w-100 p-4">
                  <span>
                    {words.DONT_HAVE_ACCOUNT[language]}{" "}
                    <Link to="/signup/USER">
                      {words.REGISTER_NOW[language]}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </SignInStyle>
      <BottomBar />
    </>
  );
};

export default SignIn;