import React from "react";
import {useSelector} from "react-redux";
import prfofileImg from "../../../assets/Images/infoImage.png";
import {StyleInfo} from "./StyleInfo";

const InfoUser = ({children}) => {
   const user = useSelector((state) => state.auth.user);

   return (
      <StyleInfo className="d-flex justify-content-start align-items-center gap-3">
         <div className="image_profile">
            <img
               src={user?.img}
               alt="imageProfile shadow-sm"
               crossorigin="anonymous"
            />
         </div>
         <div className="txt_info">{children}</div>
      </StyleInfo>
   );
};

export default InfoUser;
