import { useDispatch, useSelector } from "react-redux";
import classes from "./AddProductForm.module.scss";
import { useEffect } from "react";
import { getSubCategories } from "../../../Store/reducers/categories";
import words from "../../../assets/words";

const AddProductForm = ({ onChange, product, filesError }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const subCategories = useSelector((state) => state.categories.subCategories);
  const brands = useSelector((state) => state.brands.brands);
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    product?.category && dispatch(getSubCategories(product?.category));
  }, [product?.category]);


  return (
    <div className={classes.form} onChange={onChange}>
      <div className={classes.row}>
        <div>
          <p>
            {words.PRODUCT_NAME_EN[language]} <span>*</span>
          </p>
          <input
            placeholder={words.PRODUCT_NAME_EN[language]}
            name="name_en"
            type="text"
            className="form-control"
            value={product?.name_en}
          />
        </div>
        <div>
          <p>
            {words.PRODUCT_NAME_AR[language]} <span>*</span>
          </p>
          <input
            placeholder={words.PRODUCT_NAME_AR[language]}
            name="name_ar"
            type="text"
            className="form-control"
            value={product?.name_ar}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <p>
            {words.DESCRIPTION_EN[language]} <span>*</span>
          </p>
          <input
            placeholder={words.DESCRIPTION_EN[language]}
            name="description_en"
            type="text"
            className="form-control"
            value={product?.description_en}
          />
        </div>
        <div>
          <p>
            {words.DESCRIPTION_AR[language]} <span>*</span>
          </p>
          <input
            placeholder={words.DESCRIPTION_AR[language]}
            name="description_ar"
            type="text"
            className="form-control"
            value={product?.description_ar}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <p>
            {words.CATEGORY[language]} <span>*</span>
          </p>
          <select
            id="inputState"
            className="form-select"
            name="category"
            style={{
              width: "100%",
            }}
            value={product?.category?.id}
            placeholder={words.SELECT_CATEGORY[language]}
          >
            <option value="">{words.SELECT_CATEGORY[language]}</option>
            {categories?.data?.filter(item => item.hasChild)?.map((cat, index) => {
              return (
                <option
                  value={cat?.id}
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {cat?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <p>
            {words.SUB_CATEGORY[language]} <span>*</span>
          </p>
          <select
            id="inputState"
            className="form-select"
            name="subCategory"
            style={{
              width: "100%",
            }}
            value={product?.subCategory?.id}
            placeholder="Select City"
          >
            <option value="">{words.SELECT_SUB_CATEGORY[language]} </option>
            {subCategories?.data?.map((sub, index) => {
              return (
                <option value={sub?.id} key={index}>
                  {sub?.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className={classes.row}>
        <div>
          <p>
            {words.BRAND[language]} <span>*</span>
          </p>
          <select
            id="inputState"
            className="form-select"
            name="brand"
            style={{
              width: "100%",
            }}
            value={product?.brand?.id}
            placeholder={words.SELECT_BRAND[language]}
          >
            <option value="">{words.SELECT_BRAND[language]}</option>
            {brands?.data?.map((brand, index) => {
              return (
                <option value={brand?.id} key={index}>
                  {brand?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <p>
            {words.IMAGES[language]} <span></span>
          </p>
          <input
            placeholder="Images"
            name="files"
            type="file"
            className="form-control"
            multiple={true}
            accept="image/*"
          />
          <p className="text-warning">
            {words.NO_IMAGES_DISPLAY[language]}
          </p>
          {
            product?.files?.length > 0 &&
            <div className="mt-2">
              {
                product?.files?.map((file, index) => {
                  return (
                    <div className={classes.image} key={index}>
                      <img src={file} alt="fff" crossOrigin="anonymous" style={{width: 100, height: 100}}/>
                    </div>
                  )
                }
                )
              }
            </div>
          }
          {
            filesError && 
            <p className="text-danger">
              {filesError}
            </p>
          }
        </div>
      </div>
    </div>
  );
};

export default AddProductForm;
