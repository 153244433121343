import styled from "styled-components";

export const Profile = styled.div`
   .txt_user {
      .email {
         color: #545454;
      }
      .num_phone {
         position: relative;
         &::before {
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            background-color: #e0e0e0;
            left: -15px;
            top: 50%;
            transform: translateY(-30%);
            border-radius: 50%;
            @media only screen and (max-width: 991px) {
               display: none;
            }
         }
      }
      @media only screen and (max-width: 991px) {
         text-align: center;
      }
   }
   .top__card {
      @media only screen and (max-width: 767px) {
         flex-direction: column;
         justify-content: center !important;
         align-items: flex-start !important;
      }
   }
   .id_date {
      @media only screen and (max-width: 767px) {
         margin-bottom: 20px;
      }
   }
   .cancel_ord {
      margin-left: 0 !important;
   }
`;
