import { redirect } from "react-router-dom";

export function mainLoader() {
  const token = localStorage.getItem("token");
  const pathname = window.location.pathname;
  // debugger
  if (!token && (pathname !== "/signin" || pathname.includes("/signup"))) {
    return redirect("/signin");
  } else {
    return null;
  }
}

export function authLoader() {
  const token = localStorage.getItem("token");
  const pathname = window.location.pathname;
  // debugger
  if (token && (pathname === "/signin" || pathname.includes("/signup"))) {
    return redirect("/");
  } else {
    return null;
  }
}

export const checkToken = (token) => {
  if (token) {
    return true;
  } else {
    return false;
  }
};
