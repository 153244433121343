import React from "react";
import { useSelector } from "react-redux";
import { CardStyle } from "./StyleCard";
import words from "../../../assets/words";

const CardSummary = ({item}) => {
  const language = useSelector((state) => state.auth.language);

  
  return (
    <>
      <CardStyle className="d-flex justify-content-between align-items-center">
        <div className="img_card">
          <img src={item?.files ? item?.files : item?.image} alt="imageCard" className=""  crossorigin="anonymous"/>
        </div>
        <div className="details_card d-flex flex-column justify-content-center align-items-start gap-2">
          <span className="title_prod fw-medium fs-6">{item?.name}</span>
          <p className="price_count">
            {words.PRICE[language]}<span className="price">{item?.price * item?.quantity}</span>
            <br/>
            <span className="count_prod">{item?.quantity} {words.ITEM[language]}</span>
          </p>
        </div>
        <span></span>
        {/* <span className="ico_delete" onClick={removeFromCart}>
          <BsTrashFill />
        </span> */}
      </CardStyle>
    </>
  );
};

export default CardSummary;
