import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getFavorites = createAsyncThunk(
  "favorites/get",
  async (_, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/favourite`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createFavorite = createAsyncThunk(
  "favorites/post",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/favourite/${id}/add`;
      const response = await axios.post(url,{id: id}, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteFavorite = createAsyncThunk(
  "favorites/delete",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/favourite/${id}`;
      const response = await axios.delete(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const favoritesSlice = createSlice({
  name: "favorites",
  initialState: {
    favorites: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //get favorites
    [getFavorites.pending]: (state, action) => {
      state.loading = true;
    },
    [getFavorites.fulfilled]: (state, action) => {
      state.loading = false;
      state.favorites = action.payload;
    },
    [getFavorites.rejected]: (state, action) => {
      state.loading = false;
    },

    //create fav
    [createFavorite.pending]: (state, action) => {
      state.loading = true;
    },
    [createFavorite.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createFavorite.rejected]: (state, action) => {
      state.loading = false;
    },

       //delete fav
       [deleteFavorite.pending]: (state, action) => {
        state.loading = true;
      },
      [deleteFavorite.fulfilled]: (state, action) => {
        state.loading = false;
      },
      [deleteFavorite.rejected]: (state, action) => {
        state.loading = false;
      },
  },
});

// export const {} = anoncementsSlice.actions;

export default favoritesSlice.reducer;
