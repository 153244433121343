import React from "react";
import {Link, Outlet, ScrollRestoration} from "react-router-dom";
import MenuProfile from "../../Components/ProfileUser/sideMenu/MenuProfile";
import Breadcrmb from "./../../Components/breadCrumb/Breadcrumb";
import Footer from "./../../Components/ReusableComponent/Footer/Footer";
import NavbarComp from "./../../Components/ReusableComponent/Navbar/NavbarComp";
import { useSelector } from "react-redux";
import words from "../../assets/words";

const UserLayout = () => {
   const language = useSelector((state) => state.auth.language);

   return (
      <>
         <NavbarComp />
         <Breadcrmb>
            <ol className="breadcrumb mb-0">
               <li className="breadcrumb-item">
                  <Link to="/">{words.HOME[language]}</Link>
               </li>
               <li className="breadcrumb-item">
                  <Link to="/">{words.ACCOUNT[language]}</Link>
               </li>
               <li
                  className="breadcrumb-item active"
                  aria-current="page"
               >
                  {words.PROFILE_MAIN[language]}
               </li>
            </ol>
         </Breadcrmb>
         <div style={{backgroundColor: "#f7f7f7", paddingTop: "3rem"}}>
            <div className="container">
               <div className="row" style={{
                  direction: language == "ar" ? "rtl" : "ltr"
               }}>
                  <div className="col-sm-12 col-lg-2">
                     <MenuProfile />
                  </div>
                  <div className="col-sm-12 col-lg-10 mb-3">
                     <ScrollRestoration />
                     <Outlet />
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
};

export default UserLayout;
