import React from "react";
import {IoIosArrowBack} from "react-icons/io";
import {Link} from "react-router-dom";
import {StyledHeaderCheck} from "./StyleHeader";
import { useSelector } from "react-redux";
import words from "../../../assets/words";

const HeaderCheckOut = ({title}) => {
   const language = useSelector((state) => state.auth.language);
   return (
      <StyledHeaderCheck className="container">
         <div className="d-flex justify-content-between align-items-center px-2">
            <div className="title ">
               <h3>{title}</h3>
            </div>
            <Link
               to="/"
               className="b_t_shop"
               style={{color: "#4a3e4c", borderColor: "#4a3e4c"}}
            >
               <IoIosArrowBack />
               <span className="px-1">{words.BACK_TO_SHOPPING[language]}</span>
            </Link>
         </div>
      </StyledHeaderCheck>
   );
};

export default HeaderCheckOut;
