import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import backLogin from "../../../assets/Images/backLogin.png";
import logoSign from "../../../assets/Images/Logo.png";
import { postSignUp, saveLanguage } from "../../../Store/reducers/auth";
import { getCountries } from "../../../Store/reducers/countries";
import BottomBar from "../../../Components/ReusableComponent/Footer/bottomBar/BottomBar";
import { SignUpStyle } from "./styleSignUp";
import { FacebookLogin, GoogleLogin } from "../../../utils/socialLogin";
import { RiFacebookBoxFill } from "react-icons/ri";
import logoGoogle from "../../../assets/Images/logo-google.png";
import words from "../../../assets/words";

const SignUpVendor = () =>
{
  //route 
  const navigate = useNavigate()
  const { type } = useParams();
  const [backendError, setBackEndError] = useState("")
  const [data, setData] = useState({
    name: "",
    lastname: "",
    email: "",
    licenseImgs: null,
    idImgs: null,
    password: "",
    phone: "",
    country: "",
    type: type
  })
  const [response, setResponse] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const dispatch = useDispatch()
  const loader = useSelector(state => state.auth.loading)
  const language = useSelector(state => state.auth.language)
  const countries = useSelector(state => state.countries.countries)

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  useEffect(() =>
  {
    dispatch(getCountries())
    // eslint-disable-next-line
  }, [])

  useEffect(() =>
  {
    setData(prev => { return { ...prev, type: type } })
  }, [type])

  useEffect(() =>
  {
    if (response)
    {
      setData({
        name: response.name || response.given_name,
        // lastname: response.last_name || response.family_name,
        email: response.email,
        socailId: response.userID || response.sub,
      })
    }
  }, [response])

  const handleRegister = () =>
  {
    if (data.type === 'USER')
    {
      navigate(`/signup/VENDOR`)
    } else
    {
      navigate(`/signup/USER`)
    }
  }



  return (
    <>
      <SignUpStyle className="_signIn_" dir={`${language === "en" ? "ltr" : "rtl"}`}>

        <img
          src={backLogin}
          alt="imageBack"
          className={`backimgForm ${language === "ar" ? "back-img-form-ar" : null}`}
          crossorigin="anonymous"
        />
        <span className={`overlay ${language === "ar" ? "overlay-ar" : null}`}></span>
        <div className="row align-align-items-stretch justify-content-around w-100">
          <div className="col-sm-12 col-lg-5 img_sign ">
            <div className="container">
              <div className="cont_head d-flex flex-column justify-content-start align-items-center gap-5 pt-5">
                <div className="text text-center">
                  <h4 className="mb-4">{words.SHOP_ALL_IN_ONE_PLACE[language]}</h4>
                  <h2 className="">#{words.FIRST_IN_SUADI_ARABIA[language]}</h2>
                </div>
                <img src={logoSign} alt="logo" crossorigin="anonymous" />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-5 f-cnt">
            <Formik
              enableReinitialize={true}
              className="container"
              initialValues={data}
              validationSchema={Yup.object().shape({
                name: Yup.string().min(3, words.TOO_SHORT[language]).max(50, words.TOO_LONG[language]).required(words.THIS_FIELD_IS_REQIRED[language]),
                // lastname: Yup.string().min(3, words.TOO_SHORT[language]).max(50, words.TOO_LONG[language]).required(words.THIS_FIELD_IS_REQIRED[language]),
                email: Yup.string().email(words.INVALID_EMAIL[language]).required(words.THIS_FIELD_IS_REQIRED[language]),
                password: response ? null : Yup.string()
                  .required(words.NO_PASS_PROVIDED[language])
                  .min(8, words.PASS_TOO_SHORT[language])
                  .matches(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*[!#$@%&?"]).*$/, words.PASS_REGIX[language]),
                repeatPassword: response ? null : Yup.string()
                  .oneOf([Yup.ref("password"), null], words.PASSWORDS_NOT_MATCH[language])
                  .required(words.NO_PASS_PROVIDED[language]),
                phone: Yup.string()
                  .matches(phoneRegExp, words.INVALID_PHONE[language])
                  .required(words.THIS_FIELD_IS_REQIRED[language]),
                country: Yup.string().required(words.SELECT_CODE[language]),
              })}
              onSubmit={values =>
              {
                const formData = new FormData();        

          Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("socailId", response?.googleId);
          formData.append("type", type);
                console.log(values, 'LA')
                if (!acceptTerms)
                {
                  setBackEndError(words.PLEASE_ACCEPT_TERMS[language])
                  return
                } else
                {
                  setBackEndError("")
                  if (isNaN(values.name))
                  {
                    if (response)
                    {
                      dispatch(postSignUp(formData)).then((res) =>
                      {
                        if (res.meta.requestStatus === "rejected")
                        {
                          setBackEndError(words.USER_ALREADY_EXIST[language])
                        } else
                        {
                          setBackEndError("")
                          navigate(`/verify-account/${res?.payload?.data?.id}`)
                          // setShowModal(true)
                        }
                      })
                    } else
                    {
                      dispatch(postSignUp(formData)).then((res) =>
                      {
                        if (res.meta.requestStatus === "rejected")
                        {
                          setBackEndError(words.USER_ALREADY_EXIST[language])
                        } else
                        {
                          setBackEndError("")
                          navigate(`/verify-account/${res?.payload?.data?.id}`)
                          // setShowModal(true)
                        }
                      })
                    }
                  } else
                  {
                    if (!isNaN(values.name))
                    {
                      setBackEndError(words.INVALID_FIRST_NAME[language])
                    }
                  }
                }

              }}
            >
              {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Form className="form-control shadow-sm">

                  <p className="text-danger text-center">{backendError}</p>
                  <h2 className="py-3">{type === 'USER' ? words.REGISTER[language] : words.REGISTER_AS_VENDOR[language]}</h2>

                  <div className="mb-3 text_inputs_ row">
                    <div className="col">
                      <label className="form-label">{words.FULL_NAME[language]}</label>
                      <Field type="text" name="name" placeholder={words.FULL_NAME[language]} className="form-control" aria-label="First name" />
                      <div style={{ color: "red" }}>
                        <ErrorMessage name="name" />
                      </div>
                    </div>

                    {/* <div className="col">
                    <label className="form-label">{words.LAST_NAME[language]}</label>
                    <Field type="text" name="lastname" placeholder={words.LAST_NAME[language]} className="form-control" aria-label="Last name" />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="lastname" />
                    </div>
                  </div> */}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      {words.EMAIL_ADDRESS[language]}
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="example@mail.com"
                    />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="email" />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="idImgs" className="form-label">
                      {words.ID_IMG[language]}
                    </label>
                    <input
                      type="file"
                      name="idImgs"
                      className="form-control"
                      id="idImgs"
                      onChange={(event) => setFieldValue("idImgs", event.currentTarget.files[0])}
                    />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="idImgs" />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="licenseImgs" className="form-label">
                      {words.LICENSE_IMG[language]}
                    </label>
                    <input
                      type="file"
                      name="licenseImgs"
                      className="form-control"
                      id="licenseImgs"
                      onChange={(event) => setFieldValue("licenseImgs", event.currentTarget.files[0])}
                    />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="licenseImgs" />
                    </div>
                  </div>

                  <div className="input-group mb-3 d-flex justify-content-start">
                    <Field as="select" name="country" className="border selec">
                      {/* <option value={1}>one</option> */}
                      {/* <option>two</option> */}
                      <option>{words.SELECT_CODE[language]}</option>
                      {
                        countries?.map((country, index) =>
                        {
                          return (
                            <option key={index} value={country?.id}>
                              {country?.countryCode}
                            </option>
                          )
                        })
                      }
                    </Field>
                    <Field type="text" className="form-control" name="phone" placeholder={words.PHONE_NUMBER[language]} />
                    <div style={{ color: "red", width: "100%" }}>
                      <span style={{ marginRight: "3rem" }}>
                        <ErrorMessage name="country" />
                      </span>
                      <ErrorMessage name="phone" />
                    </div>
                  </div>

                  {
                    !response &&
                    <>
                      <div className="mb-3">
                        <label htmlFor="addpass" className="form-label">
                          {words.PASSWORD[language]}
                        </label>
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder={words.AT_LEAST_8_CHARACTERS[language]}
                          id="addpass"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="password" />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="addpass" className="form-label">
                          {words.REPEAT_PASSWORD[language]}
                        </label>
                        <Field type="password" name="repeatPassword" placeholder={words.REPEAT_PASSWORD[language]} className="form-control" id="addpass" />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="repeatPassword" />
                        </div>
                      </div>
                    </>
                  }



                  <div className="mb-3 form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" value={acceptTerms}
                      onChange={() => setAcceptTerms(!acceptTerms)}
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      {words.I_AGREE_TERMS[language]}
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary w-100 mb-3" disabled={loader}>
                    {
                      loader ? <Spinner animation='border' /> : words.SIGNUP[language]
                    }
                  </button>
                  <div className="sign_with">
                    <GoogleLogin response={response} setResponse={setResponse}>
                      <div className="btn_google btn w-100">
                        <img
                          src={logoGoogle}
                          alt="logoGoogle"
                          crossorigin="anonymous"
                        />
                        <span className="px-3">{words.COUNTINUE_WITH_GOOGLE[language]}</span>
                      </div>
                    </GoogleLogin>

                    <FacebookLogin response={response} setResponse={setResponse}>
                      <div className="btn_faceboK btn w-100 mt-2">
                        <RiFacebookBoxFill />
                        <span className="px-3">{words.COUNTINUE_WITH_FACEBOOK[language]}</span>
                      </div>
                    </FacebookLogin>

                  </div>
                  <div className="_or d-flex justify-content-center align-items-center my-1 position-relative">
                    <hr className="w-75" />
                  </div>
                  <div className="foot_form d-flex justify-content-center w-100 p-2">
                    <span>
                      {words.ALREADY_HAVE_ACCOUNT[language]}<Link to="/signin">{words.SIGNIN[language]}</Link>
                    </span>
                  </div>
                  <div className="foot_form d-flex justify-content-center w-100 p-2">
                    <span>
                      {words.REGISTER_AS_A[language]} {type === 'USER' ? words.VENDOR[language] : words.USER[language]} ? <span style={{
                        color: "#0d6efd",
                        cursor: "pointer"
                      }} onClick={handleRegister}>{words.REGISTER_NOW[language]}</span>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </SignUpStyle>
      <BottomBar />
    </>
  );
};

export default SignUpVendor;
