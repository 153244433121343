import "animate.css";
import React, { useEffect, useState } from "react";

import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAnoncementApi } from "../../Apis/AnoncementApi";
import { getBrandsApi } from "../../Apis/brandsApi";
import { getCategoriesApi } from "../../Apis/CategoriesApi";
import cam from "../../assets/Images/cam.png";
import ListIm from "../../assets/Images/list.png";
import paidArt from "../../assets/Images/paid-articles.png";
import triangleImage from "../../assets/Images/Path 18.png";
import waveBackg from "../../assets/Images/wave-1.png";
import imageWave from "../../assets/Images/wave-down.png";
import { getProducts } from "../../Store/reducers/products";
import FlashSale from "../FlashSale/FlashSale";
import Categories from "../ReusableComponent/Categories/Categories";
import LatestItems from "../ReusableComponent/LatestItemSection/LatestItems";
import OurFeature from "../ReusableComponent/ourFeature/OurFeature";
import TitleSection from "../ReusableComponent/title&subtitle&btnSeeAll/TitleSection";
import { CategoryStyle, FlashSleSection, Header, LatestView } from "./styledHome/HomePageStyled";
import words from "../../assets/words";

function HomePage({ toggleSidebar, sidebar }) {
  const navigate = useNavigate()
  //redux
  const dispatch = useDispatch()
  const userId = useSelector(state => state.auth.userId)
  const language = useSelector(state => state.auth.language)
  const [products, setProducts] = useState([])

  useEffect(() => {
    dispatch(getProducts({
      hasOffer: true,
      userId: userId ? userId : ""
    })).then((res)=>{
      setProducts(res?.payload?.data)
    })
    // dispatch(getProducts())
  }, [userId]);

  return (
    <>
      <Header>
        <div className={`backTriangle`}>
          <img src={triangleImage} alt="headerTriangle"/>
        </div>
        <div className="cont_head">
          <div className="text text-center">
            <h4 className="mb-4">{words.SHOP_ALL_IN_ONE_PLACE[language]}</h4>
            <h2>{words.FIRST_IN_SUADI_ARABIA[language]}</h2>
          </div>
        </div>
      </Header>

      <CategoryStyle dir={language === "en" ? "ltr" : "rtl"}>
        <div className="container">
          <TitleSection
            title={
              <>
                <span className="cust_text">{words.VIEW[language]} </span>{words.ALL_CATEGORIES[language]}
              </>
            }
            subtitle={words.OUR_LATEST_UPDATES[language]}
            btnSell={
              <span onClick={()=> navigate(`/products-page/cat=`)}>
                {words.SEE_ALL[language]} <MdOutlineKeyboardArrowRight className="icoArrow" size="1.5rem" />
              </span>
            }
          />
          <Categories />
        </div>
      </CategoryStyle>

      <FlashSleSection className="Latest_join bg-white pt-5 pb-1 position-relative ">
        <img src={waveBackg} alt="wave" className="wave-up"  crossorigin="anonymous"/>

        {/*How to join*/}
        <div className="container mb-5" dir={language === "en" ? "ltr" : "rtl"}>
          <TitleSection
            title={
              <>
                <span className="cust_text">{words.HOW_TO_JOIN[language]} </span> {words.COTTAGE_CAPITAL[language]}
              </>
            }
            subtitle={words.DID_YOU_KNOW[language]}
          />
          <div className="row">
            <div className="col-sm-12 col-lg-4 mb-2">
              <div className="cont_box  no1">
                <img src={cam} alt="camera"  crossorigin="anonymous"/>
                <p>{words.CLEAR_PHOTO[language]}</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 mb-2">
              <div className="cont_box  orag">
                <img src={ListIm} alt="camera"  crossorigin="anonymous"/>
                <p>{words.MORE_DETAILS_DESC[language]}</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 mb-2">
              <div className="cont_box  blu">
                <img src={paidArt} alt="camera"  crossorigin="anonymous"/>
                <p>{words.YOU_LISTING_DESC[language]}</p>
              </div>
            </div>
          </div>
        </div>

        {/*FlashSALE - SECTION*/}
        <FlashSale products={products}/>
      </FlashSleSection>

      <div className="wave_d w-100">
        <img src={imageWave} alt="wave" className="wave-down w-100"  crossorigin="anonymous"/>
      </div>

      {/* Latest___Offer*/}
      <LatestView>
        <LatestItems />
        <OurFeature />
      </LatestView>
    </>
  );
}

export default HomePage;
