import React from "react";
import { BsArrowRight, BsCheckSquareFill } from "react-icons/bs";
import { StyleProg } from "./StyleProgress";
import words from "../../../assets/words";
import { useSelector } from "react-redux";

const ProgressBar = ({ number }) => {
  const language = useSelector((state) => state.auth.language);
  const tabs = [
    {
      name: words.CART[language],
      active: true,
    },
    {
      name: words.SHIPPING_AND_PAYMENT[language],
      active: false,
    },
    {
      name: words.REVIEW[language],
      active: false,
    },
  ];
  return (
    <StyleProg>
      <div className="container">
        <div className="bg-white rounded-2 d-flex justify-content-start align-items-center px-3 py-3 cont_step flex-wrap def_shadow">
          {tabs.map((tab, index) => {
            return (
              <>
                <div className={index + 1 >= number ? "step review_prog next_step" : "step cart_prog active_step"}>
                  {index + 1 < number && (
                    <BsCheckSquareFill className="checkRight" />
                  )}
                  {index + 1 >= number && (
                    <div className="notActive_step">
                      <span>{index + 1}</span>
                    </div>
                  )}

                  <span>{tab.name}</span>
                </div>
                {index !== tabs.length - 1 && (
                  <BsArrowRight className={'arrow_step ' + (language == 'en' ? 'en' : 'ar')} />
                )}
              </>
            );
          })}

          {/* <BsArrowRight className="arrow_step" />
               <div className="step Customer_prog active_step">
                  <BsCheckSquareFill className="checkRight" />
                  <span>{words.CUSTOMER_INFORMATION[language]}</span>
               </div> */}
          {/* <BsArrowRight className="arrow_step" />
         
          <BsArrowRight className="arrow_step" />
          <div className="step review_prog next_step">
            <div className="notActive_step">
              <span>3</span>
            </div>
            <p>{words.REVIEW[language]}</p>
          </div> */}
        </div>
      </div>
    </StyleProg>
  );
};

export default ProgressBar;
