const words = {
    /////////////////////GENERAL/////////////////////
    SHOP_ALL_IN_ONE_PLACE: {
        en: "Shop everything in one place",
        ar: "تسوق كل ما تحتاجه في مكان واحد",
    },
    FIRST_IN_SUADI_ARABIA: {
        en: "1st online Mall at Saudi Arabia",
        ar: "أول مول إلكتروني في السعودية",
    },
    /////////////////AUTH/////////////////////
    SIGNIN: {
        en: "Sign In",
        ar: "تسجيل الدخول",
    },
    SIGNUP: {
        en: "Sign Up",
        ar: "تسجيل",
    },
    PHONE_NUMBER: {
        en: "Phone Number",
        ar: "رقم الهاتف",
    },
    ID_IMG: {
        en: "ID img",
        ar: "صورة الهوية",
    },
    LICENSE_IMG: {
        en: "License img",
        ar: "السجل التجاري",
    },
    PASSWORD: {
        en: "Password",
        ar: "كلمة المرور",
    },
    INVALID_FIRST_NAME: {
        en: "Invalid first name, must be text only",
        ar: "الاسم الأول غير صالح ، يجب أن يكون نصًا فقط",
    },
    INVALID_LAST_NAME: {
        en: "Invalid last name, must be text only",
        ar: "الاسم الأخير غير صالح ، يجب أن يكون نصًا فقط",
    },
    REMEMBER_ME: {
        en: "Remember Me",
        ar: "تذكرني",
    },
    LOGIN_IN: {
        en: "Login",
        ar: "تسجيل الدخول",
    },
    FORGET_PASSWORD: {
        en: "Forget Password",
        ar: "نسيت كلمة المرور",
    },
    EMAIL: {
        en: "Email",
        ar: "البريد الالكتروني",
    },
    OR: {
        en: "OR",
        ar: "أو",
    },
    COUNTINUE_WITH_GOOGLE: {
        en: "Countinue With Google",
        ar: "تسجيل الدخول بواسطة جوجل",
    },
    COUNTINUE_WITH_FACEBOOK: {
        en: "Countinue With Facebook",
        ar: "تسجيل الدخول بواسطة فيسبوك",
    },
    DONT_HAVE_ACCOUNT: {
        en: "Don't have an account?",
        ar: "ليس لديك حساب؟",
    },
    REGISTER_NOW: {
        en: "Register Now",
        ar: "سجل الآن",
    },
    REGISTER: {
        en: "Register",
        ar: "تسجيل",
    },
    FIRST_NAME: {
        en: "First Name",
        ar: "الاسم الأول",
    },
    FULL_NAME: {
        en: "Full Name",
        ar: "الاسم بالكامل",
    },
    LAST_NAME: {
        en: "Last Name",
        ar: "الاسم الأخير",
    },
    EMAIL_ADDRESS: {
        en: "Email Address",
        ar: "البريد الالكتروني",
    },
    SELECT_CODE: {
        en: "Select Code",
        ar: "اختر الرمز",
    },
    PHONE: {
        en: "Phone",
        ar: "رقم الهاتف",
    },
    REPEAT_PASSWORD: {
        en: "Repeat Password",
        ar: "تأكيد كلمة المرور",
    },
    I_AGREE_TERMS: {
        en: "I agree to the terms and conditions",
        ar: "أوافق على الشروط والأحكام",
    },
    ALREADY_HAVE_ACCOUNT: {
        en: "Already have an account?",
        ar: "هل لديك حساب؟",
    },
    REGISTER_AS_VENDOR: {
        en: "Register as a vendor",
        ar: "سجل كبائع",
    },
    AT_LEAST_8_CHARACTERS: {
        en: "At least 8 characters",
        ar: "8 أحرف على الأقل",
    },
    VENDOR: {
        en: "Vendor",
        ar: "بائع",
    },
    USER: {
        en: "User",
        ar: "مستخدم",
    },
    REGISTER_AS_A: {
        en: "Register as a",
        ar: "سجل كـ",
    },
    ENTER_PHONE_NUMBER: {
        en: "Enter your phone number",
        ar: "أدخل رقم هاتفك",
    },
    CONTINUE: {
        en: "Continue",
        ar: "استمر",
    },
    YOU_MUST_SIGN_UP_FIRST: {
        en: "You must sign up first",
        ar: "يجب عليك التسجيل أولاً",
    },
    YOU_ARE_NOT_A_MEMBER: {
        en: "You are not a member?",
        ar: "أنت لست عضوا؟",
    },
    OK: {
        en: "OK",
        ar: "حسنا",
    },
    CHECK_YOUR_PHONE_NUMBER: {
        en: "Check your phone number",
        ar: "تحقق من رقم هاتفك",
    },
    YOUR_ACCOUNT_NOT_VERFIY: {
        en: "Your acount is not verfied, Are you want to verfiy it ?",
        ar: "حسابك غير مؤكد ، هل تريد التحقق منه؟",
    },
    YOUR_ACCOUNT_NOT_VERFIED: {
        en: "Your account is not verfied",
        ar: "حسابك غير مؤكد",
    },
    INVALID_PHONE_OR_PASS: {
        en: "Invalid phone number or password",
        ar: "رقم الهاتف أو كلمة المرور غير صالحة",
    },
    PHONE_IS_REQUIRED: {
        en: "Phone number is required",
        ar: "رقم الهاتف مطلوب",
    },
    NO_PASS_PROVIDED: {
        en: "No password provided",
        ar: "لم يتم تقديم كلمة مرور",
    },
    NO_PRODUCTS_FOUND: {
        en: "no products found ",
        ar: "لا يوجد منتجات",
    },
    YES: {
        en: "Yes",
        ar: "نعم",
    },
    TOO_SHORT: {
        en: "Too short",
        ar: "قصير جدا",
    },
    TOO_LONG: {
        en: "Too long",
        ar: "طويل جدا",
    },
    INVALID_EMAIL: {
        en: "Invalid email",
        ar: "بريد إلكتروني خاطئ",
    },
    PASS_TOO_SHORT: {
        en: "Password is too short",
        ar: "كلمة المرور قصيرة جدا",
    },
    PASS_REGIX: {
        en: "Password must contain at least one uppercase letter, one lowercase letter and one number",
        ar: "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل وحرف صغير واحد ورقم واحد",
    },
    PASSWORDS_NOT_MATCH: {
        en: "Passwords not match",
        ar: "كلمات المرور غير متطابقة",
    },
    INVALID_PHONE: {
        en: "Invalid phone number",
        ar: "رقم الهاتف غير صالح",
    },
    THIS_FIELD_IS_REQIRED: {
        en: "This field is required",
        ar: "هذا الحقل مطلوب",
    },
    PLEASE_ACCEPT_TERMS: {
        en: "Please accept terms and conditions",
        ar: "يرجى قبول الشروط والأحكام",
    },
    USER_ALREADY_EXIST: {
        en: "User already exist",
        ar: "المستخدم موجود بالفعل",
    },
    VERFICATION_CODE: {
        en: "Verfication Code",
        ar: "رمز التحقق",
    },
    ENTER_CODE: {
        en: "Enter the code sent to your phone number",
        ar: "أدخل الرمز المرسل إلى رقم هاتفك",
    },
    DONE: {
        en: "Done",
        ar: "حسنا",
    },
    THANKS_FOR_CONTACTING_US: {
        en: "Thanks for contacting us",
        ar: "شكرا لاتصالك بنا",
    },
    THANKS: {
        en: "Thanks",
        ar: "شكرا",
    },
    SEND_MESSAGE: {
        en: "Send Message",
        ar: "إرسال رسالة",
    },
    LOADING: {
        en: "Loading...",
        ar: "جار التحميل...",
    },
    SUBMIT: {
        en: "Submit",
        ar: "إرسال",
    },
    RESET_PASS: {
        en: "Reset Password",
        ar: "إعادة تعيين كلمة المرور",
    },
    NEW_PASS: {
        en: "New Password",
        ar: "كلمة مرور جديدة",
    },
    SUCCESS: {
        en: "Success",
        ar: "نجاح",
    },
    YOUR_PASS_RESET_SUCCESS: {
        en: "Your password has been reset successfully,",
        ar: "تم إعادة تعيين كلمة المرور الخاصة بك بنجاح",
    },
    PLEASE_LOGIN_WITH_NEW_PASS: {
        en: "Please login with your new password",
        ar: "يرجى تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك",
    },
    SOMTHING_WRONG: {
        en: "Somthing went wrong",
        ar: "هناك خطأ ما",
    },
    LOG_OUT: {
        en: "Log Out",
        ar: "تسجيل خروج",
    },
    /////////////////////////////  Home /////////////////////////////
    SHOP_BY_CATEGORY: {
        en: "Shop by category",
        ar: "تسوق حسب الفئة",
    },
    VIEW: {
        en: "View",
        ar: "عرض",
    },
    ALL_CATEGORIES: {
        en: "All Categories",
        ar: "جميع الفئات",
    },
    SEE_ALL: {
        en: "See All",
        ar: "اظهر الكل",
    },
    OUR_LATEST_UPDATES: {
        en: "Our Latest Updates",
        ar: "أحدث التحديثات",
    },
    HOW_TO_JOIN: {
        en: "How to join",
        ar: " كيف تنضم ل",
    },
    COTTAGE: {
        en: "Cottage",
        ar: "Cottage",
    },
    COTTAGE_CAPITAL: {
        en: "COTTAGE",
        ar: "COTTAGE",
    },
    DID_YOU_KNOW: {
        en: "Did you know ?",
        ar: "هل تعلم ؟",
    },
    CLEAR_PHOTO: {
        en: "A clear photo/video gets you more exposure",
        ar: "تحصل الصورة / الفيديو الواضح على مزيد من التعرض",
    },
    MORE_DETAILS_DESC: {
        en: "More details means more users will find your listing easier.",
        ar: "المزيد من التفاصيل يعني أن المزيد من المستخدمين سيجدون قائمتك بسهولة.",
    },
    YOU_LISTING_DESC: {
        en: "Your listing easier. Choose one of our cool add-ons for millions to see it!",
        ar: "قائمتك بسهولة. اختر أحد إضافاتنا الرائعة ليروها الملايين!",
    },
    FLASH_SALE: {
        en: "Flash Sale",
        ar: "خصم مميز"
    },
    UP_TO_70: {
        en: "Up to 70% off",
        ar: "خصم يصل إلى 70٪",
    },
    SR: {
        en: "SR",
        ar: "ريال",
    },
    OFFER: {
        en: "Offer",
        ar: "عرض",
    },
    ADD_TO_CART: {
        en: "Add to cart",
        ar: "أضف إلى السلة",
    },
    NEW: {
        en: "New",
        ar: "جديد",
    },
    YOU_aRE_NOT_LOGGED_IN: {
        en: "You are not logged in",
        ar: "أنت غير مسجل الدخول",
    },
    YOU_MUST_SIGN_IN_FIRST: {
        en: "You must sign in first",
        ar: "يجب عليك تسجيل الدخول أولا",
    },
    LATEST_OFFERS: {
        en: "Latest Offers",
        ar: "أحدث العروض",
    },
    REASONABLE_PRICES: {
        en: "Reasonable Prices",
        ar: "أسعار معقولة",
    },
    CUSTOMER_SUPPORT: {
        en: "Customer Support",
        ar: "دعم العملاء",
    },
    EXPRESS_DELIVERY: {
        en: "Express Delivery",
        ar: "توصيل سريع",
    },
    HAVE_YOU_EVER_DESC: {
        en: "Have you ever finally just",
        ar: "هل سبق لك أن فعلت فقط",
    },
    COTTAGE_DESC: {
        en: `Take COTTAGE anywhere you go with our exclusive applications for both Android and iOS
    devices.`,
        ar: `خذ COTTAGE أينما ذهبت مع تطبيقاتنا الحصرية لكل من أجهزة Android و iOS`,
    },
    DOWNLOAD_OUR_APP: {
        en: "Download Our App",
        ar: "قم بتنزيل تطبيقنا",
    },
    ALL_RIGHTS_RESERVED: {
        en: "All Rights Reserved By Cottage 2023",
        ar: "جميع الحقوق محفوظة لـ Cottage 2023",
    },
    CATEGORIES: {
        en: "Categories",
        ar: "التصنيفات",
    },
    MY_ACCOUNT: {
        en: "My Account",
        ar: "حسابي",
    },
    ACCOUNT_INFORMATION: {
        en: "Account Information",
        ar: "معلومات الحساب",
    },
    MY_FAVORITES: {
        en: "My Favorites",
        ar: "مفضلتي",
    },
    MY_PAYMENTS: {
        en: "My Payments",
        ar: "مدفوعاتي",
    },
    MY_ORDERS: {
        en: "My Orders",
        ar: "طلباتي",
    },
    MY_LISTINGS: {
        en: "My Listings",
        ar: "قوائمي",
    },
    INFORMATION: {
        en: "Information",
        ar: "معلومات",
    },
    ABOUT_US: {
        en: "About Us",
        ar: "معلومات عنا",
    },
    CONTACT_US: {
        en: "Contact Us",
        ar: "اتصل بنا",
    },
    TERMS_AND_CONDITIONS: {
        en: "Terms and Conditions",
        ar: "الأحكام والشروط",
    },
    HOME: {
        en: "Home",
        ar: "الصفحة الرئيسية",
    },
    /////////////////////////////  DETAILS /////////////////////////////
    ASK_THE_SELLER: {
        en: "Ask the seller",
        ar: "اسأل البائع",
    },
    PER_ITEM: {
        en: " Per Item",
        ar: " لكل قطعة",
    },
    COLOR: {
        en: "Color",
        ar: "اللون",
    },
    SIZE: {
        en: "Size",
        ar: "الحجم",
    },
    BRAND: {
        en: "Brand",
        ar: "العلامة التجارية",
    },
    COLORS: {
        en: "Colors",
        ar: "الألوان",
    },
    OVERVIEW: {
        en: "Overview",
        ar: "نظرة عامة",
    },
    SPACIFICATON: {
        en: "Specification",
        ar: "المواصفات",
    },
    REVIEWS: {
        en: "Reviews",
        ar: "التعليقات",
    },
    SMILIER_PRODUCTS: {
        en: "Smilier Products",
        ar: "منتجات مماثلة",
    },
    PRODUCTS_FOUND: {
        en: "Products Found",
        ar: "المنتجات الموجودة",
    },
    ALL: {
        en: "All",
        ar: "الكل",
    },
    BRANDS: {
        en: "Brands",
        ar: "العلامات التجارية",
    },
    SUB_CATEGORIES: {
        en: "Sub Categories",
        ar: "الفئات الفرعية",
    },
    PRICES: {
        en: "Prices",
        ar: "الأسعار",
    },
    RATINGS: {
        en: "Ratings",
        ar: "التقييمات",
    },
    MIN: {
        en: "Min",
        ar: "الحد الأدنى",
    },
    MAX: {
        en: "Max",
        ar: "الحد الأقصى",
    },
    APPLY: {
        en: "Apply",
        ar: "تطبيق",
    },
    RESET: {
        en: "Reset",
        ar: "إعادة تعيين",
    },
    SORT_BY_PRICE: {
        en: "Sort By Price",
        ar: "ترتيب حسب السعر",
    },
    HIGHEST_PRICE: {
        en: "Highest Price",
        ar: "أعلى سعر",
    },
    LOWEST_PRICE: {
        en: "Lowest Price",
        ar: "أقل سعر",
    },
    SEARCH_EX: {
        en: "ex: house for sale, nissan, LCD",
        ar: "مثال: منزل للبيع ، نيسان ، شاشة LCD",
    },
    ACCOUNT: {
        en: "Account",
        ar: "الحساب",
    },
    PROFILE_MAIN: {
        en: "Profile Main",
        ar: "الملف الشخصي",
    },
    ACCOUNT_MAIN: {
        en: "Account Main",
        ar: "الحساب الرئيسي",
    },
    ORDERS_HISTORY: {
        en: "Orders History",
        ar: "تاريخ الطلبات",
    },
    NEW_ORDERS: {
        en: "New Orders",
        ar: "طلبات جديدة",
    },
    MY_WISHLIST: {
        en: "My Wishlist",
        ar: "قائمة امنياتي",
    },
    PROFILE_SETTINGS: {
        en: "Profile Settings",
        ar: "إعدادات الملف الشخصي",
    },
    CURRENT_ORDERS: {
        en: "Current Orders",
        ar: "الطلبات الحالية",
    },
    CANCEL_ORDER: {
        en: "Cancel Order",
        ar: "إلغاء الطلب",
    },
    ARE_YOU_WANT_TO_CANCEL_ORDER: {
        en: "Do you want to cancel this order?",
        ar: "هل تريد إلغاء هذا الطلب؟",
    },
    ADD_NEW_ADDRESS: {
        en: "Add New Address",
        ar: "إضافة عنوان جديد",
    },
    SELECT_COUNTRY: {
        en: "Select Country",
        ar: "اختر الدولة",
    },
    EDIT_ADDRESS: {
        en: "Edit Address",
        ar: "تعديل العنوان",
    },
    DELETE_ADDRESS: {
        en: "Delete Address",
        ar: "حذف العنوان",
    },
    ARE_YOU_WANT_TO_DELETE_ADDRESS: {
        en: "Are you want to delete this address ?",
        ar: "هل تريد حذف هذا العنوان؟",
    },
    ACCEPT_OFFER: {
        en: "Accept Offer",
        ar: " الموافقة على العرض",
    },
    ARE_YOU_WANT_TO_ACCEPT_OFFER: {
        en: "Are you sure to accept offer?",
        ar: "هل انت متأكد من الموافقة على العرض؟",
    },
    ORDER_ID: {
        en: "Order ID",
        ar: "رقم التعريف الخاص بالطلب",
    },
    TRACK_ORDER: {
        en: "Track Order",
        ar: "تتبع الطلب",
    },
    PERSON: {
        en: "Person",
        ar: "شخص",
    },
    DELIVERY_ADDRESS: {
        en: "Delivery Address",
        ar: "عنوان التسليم",
    },
    PAYMENT: {
        en: "Payment",
        ar: "دفع",
    },
    SHIPPING_FEE: {
        en: "Shipping Fee",
        ar: "رسوم الشحن",
    },
    TOTAL_PAID: {
        en: "Total Paid",
        ar: "المجموع المدفوع",
    },
    DATE: {
        en: "Date",
        ar: "تاريخ",
    },
    NO_ORDERS_FOUND: {
        en: "No Orders Found",
        ar: "لم يتم العثور على طلبات",
    },
    UPDATE_YOUR_PROFILE: {
        en: "Update Your Profile",
        ar: "تحديث ملفك الشخصي",
    },
    COUNTRY: {
        en: "Country",
        ar: "بلد",
    },
    CHANGE_PASSWORD: {
        en: "Change Password",
        ar: "تغيير كلمة المرور",
    },
    UPDATE: {
        en: "Update",
        ar: "تحديث",
    },
    CURRENT_PASSWORD: {
        en: "Current Password",
        ar: "كلمة المرور الحالية",
    },
    NEW_PASSWORD: {
        en: "New Password",
        ar: "كلمة السر الجديدة",
    },
    OUT_OF_STOCK: {
        en: "Out of Stock",
        ar: "إنتهى من المخزن",
    },
    IN_STOCK: {
        en: "In Stock",
        ar: "في المخزن",
    },
    NO_REVIEWS_FOUND: {
        en: "No Reviews Found",
        ar: "لم يتم العثور على مراجعات",
    },
    SHOPPING_CART: {
        en: "Shopping Cart",
        ar: "عربة التسوق",
    },
    YOUR_CART_IS_EMPTY: {
        en: "Your cart is empty",
        ar: "عربتك فارغة",
    },
    HAVE_A_COUPON: {
        en: "Have a Coupon?",
        ar: "هل لديك قسيمة؟",
    },
    TYPE_COUPOUN_CODE_HERE: {
        en: "Type coupoun code here",
        ar: "اكتب رمز القسيمة هنا",
    },
    APPLY: {
        en: "Apply",
        ar: "تطبيق",
    },
    CHECKOUT: {
        en: "Checkout",
        ar: "الدفع",
    },
    BACK_TO_SHOP: {
        en: "Back to Shop",
        ar: "العودة إلى المتجر",
    },
    SUB_TOTAL: {
        en: "Sub Total",
        ar: "المجموع الفرعي",
    },
    DISCOUNT: {
        en: "Discount",
        ar: "خصم",
    },
    TOTAL: {
        en: "Total",
        ar: "مجموع",
    },
    COUNT: {
        en: "Count",
        ar: "عدد",
    },
    TOTAL_PRICE: {
        en: "Total Price",
        ar: "السعر الكلي",
    },
    REMOVE: {
        en: "Remove",
        ar: "إزالة",
    },
    RECOMMENDED_ITEMS: {
        en: "Recommended Items",
        ar: "العناصر الموصى بها",
    },
    BACK_TO_SHOPPING: {
        en: "Back to Shopping",
        ar: "العودة للتسوق",
    },
    SHIPPING_AND_PAYMENT: {
        en: "Shipping & Payment",
        ar: "الشحن والدفع",
    },
    CHOOSE_SHIPPING_SERVICE: {
        en: "Choose Shipping Service",
        ar: "اختر خدمة الشحن",
    },
    YOU_CAN_CHOOSE_DESC: {
        en: "You can choose one best shipping service accross your needs.",
        ar: "يمكنك اختيار خدمة شحن واحدة مناسبة لاحتياجاتك.",
    },
    ITEM: {
        en: "Item",
        ar: "العنصر",
    },
    PRICE: {
        en: "Price",
        ar: "السعر",
    },
    ORDER_SUMMARY: {
        en: "Order Summary",
        ar: "ملخص الطلب",
    },
    REVIEW_YOUR_ORDER: {
        en: "Review Your Order",
        ar: "مراجعة طلبك",
    },
    BACK_TO_CUSTOMER_INFO: {
        en: "Back to Customer Info",
        ar: "العودة إلى معلومات العميل",
    },
    SHIPPING_TO: {
        en: "Shipping to…",
        ar: "الشحن إلى…",
    },
    PLEASE_CHECK_ORDER_DESC: {
        en: "Please check berofe you finalize your order",
        ar: "يرجى التحقق من قبل إكمال طلبك",
    },
    GUEST_CHECKOUT: {
        en: "Guest Checkout",
        ar: "الدفع كضيف",
    },
    ADDRESS: {
        en: "Address",
        ar: "عنوان",
    },
    SELECT_AREA: {
        en: "Select Area",
        ar: "حدد المنطقة",
    },
    SELECT_CITY: {
        en: "Select City",
        ar: "اختر مدينة",
    },
    STATE: {
        en: "State",
        ar: "حالة",
    },
    WRITE_A_NOTE: {
        en: "Write a note (optional)",
        ar: "اكتب ملاحظة (اختياري)",
    },
    NOTE_DESC: {
        en: "Fragile item, Electronics etc",
        ar: "عنصر هش ، إلكترونيات إلخ",
    },
    FINALIZE_ORDER: {
        en: "Finalize Order",
        ar: "تأكيد الطلب",
    },
    PRODUCTS: {
        en: "Products",
        ar: "منتجات",
    },
    VENDORS: {
        en: "Vendors",
        ar: "الباعة",
    },
    CHAT_CONTACTS: {
        en: "Chat Contacts",
        ar: "جهات الاتصال في الدردشة",
    },
    DRIVERS_REPLIES: {
        en: "Drivers Replies",
        ar: "ردود السائقين",
    },
    DELETE_PRODUCT: {
        en: "Delete Product?",
        ar: "حذف المنتج",
    },
    NEW_PRODUCT: {
        en: "New Product",
        ar: "منتج جديد",
    },
    SEARCH_ABOUT_PRODUCT: {
        en: "Search about product",
        ar: "البحث عن المنتج",
    },
    FILTER_BY_CATEGORY: {
        en: "Filter by category",
        ar: "تصفية حسب الفئة",
    },
    NO_DATA_FOUND_FOR_DISPLAY: {
        en: "No data found for display",
        ar: "لم يتم العثور على بيانات للعرض",
    },
    PRODUCT_NAME: {
        en: "Product Name",
        ar: "اسم المنتج",
    },
    CATEGORY: {
        en: "Category",
        ar: "الفئة",
    },
    QUANTITY: {
        en: "Quantity",
        ar: "كمية",
    },
    RATE: {
        en: "Rate",
        ar: "معدل",
    },
    PRODUCT_ACTIONS: {
        en: "Product Actions",
        ar: "إجراءات المنتج",
    },
    OFFER_ACTIONS: {
        en: "Offer Actions",
        ar: "إجراءات العرض",
    },
    NO_OFFER: {
        en: "No Offer",
        ar: "لا يوجد عرض",
    },
    ARE_YOU_WANT_TO_DELETE_PRODUCT: {
        en: "Are you want to delete this Product ?",
        ar: "هل تريد حذف هذا المنتج؟",
    },
    ADD_PRODUCT_OFFER: {
        en: "Add Product Offer",
        ar: "إضافة عرض المنتج",
    },
    PRODUCT_OFFER: {
        en: "Product Offer",
        ar: "عرض المنتج",
    },
    DELETE_OFFER: {
        en: "Delete Offer?",
        ar: "حذف العرض",
    },
    ARE_YOU_WANT_TO_DELETE_OFFER_PRODUCT: {
        en: "Are you want to delete the offer on this product ?",
        ar: "هل تريد حذف العرض على هذا المنتج؟",
    },
    YOU_MUST_ADD_PROPERTY: {
        en: " You must add at least one property.",
        ar: "يجب عليك إضافة خاصية واحدة على الأقل.",
    },
    YOU_MUST_ADD_STOCK: {
        en: " You must add at least one stock.",
        ar: "يجب عليك إضافة مخزون واحد على الأقل.",
    },
    ADD_PRODUCT: {
        en: "Add Product",
        ar: "إضافة منتج",
    },
    PRODUCT_NAME_EN: {
        en: "Product Name (English)",
        ar: "اسم المنتج (الإنجليزية)",
    },
    PRODUCT_NAME_AR: {
        en: "Product Name (Arabic)",
        ar: "اسم المنتج (العربية)",
    },
    DESCRIPTION_EN: {
        en: "Description (English)",
        ar: "وصف (الإنجليزية)",
    },
    DESCRIPTION_AR: {
        en: "Description (Arabic)",
        ar: "وصف (العربية)",
    },
    SUB_CATEGORY: {
        en: "Sub Category",
        ar: "فرعي",
    },
    IMAGES: {
        en: "Images",
        ar: "صور",
    },
    NO_IMAGES_DISPLAY: {
        en: "Warning: These images will be deleted if there are colors",
        ar: "تحذير: سيتم حذف هذه الصور إذا كانت هناك ألوان",
    },
    PROPERTIES: {
        en: "Properties",
        ar: "خصائص",
    },
    ADD_NEW_STOCK: {
        en: "Add New Stock",
        ar: "إضافة مخزون جديد",
    },
    SELECT_CATEGORY: {
        en: "Select Category",
        ar: "اختر الفئة",
    },
    SELECT_SUB_CATEGORY: {
        en: "Select Sub Category",
        ar: "اختر الفئة الفرعية",
    },
    SELECT_BRAND: {
        en: "Select Brand",
        ar: "اختر العلامة التجارية",
    },
    UPDATE_PRODUCT: {
        en: "Update Product",
        ar: "تحديث المنتج",
    },
    PROPERTY_IS_EXIST: {
        en: "property is already exists",
        ar: "الخاصية موجودة بالفعل",
    },
    PROPERTY_EN: {
        en: "Property (English)",
        ar: "خاصية (الإنجليزية)",
    },
    PROPERTY_AR: {
        en: "Property (Arabic)",
        ar: "خاصية (العربية)",
    },
    ADD: {
        en: "Add",
        ar: "إضافة",
    },
    QUANTITY_DESC: {
        en: "Quantity of all colors must be less than quantity of the size selected",
        ar: "يجب أن تكون كمية جميع الألوان أقل من كمية الحجم المحدد",
    },
    COLOR_IS_EXIST: {
        en: "color is already exists",
        ar: "اللون موجود بالفعل",
    },
    SELECT_SIZE: {
        en: "Select Size",
        ar: "اختر المقاس",
    },
    PLEASE_SELECT_SIZE_FIRST: {
        en: "Please select size data first",
        ar: "يرجى تحديد بيانات الحجم أولاً",
    },
    SELECT_COLOR: {
        en: "Select Color",
        ar: "اختر اللون",
    },
    SENT_TO_DERIVERS: {
        en: "Send to drivers",
        ar: "إرسال إلى السائقين",
    },
    CHANGE_STATUS: {
        en: "Change Status",
        ar: "تغيير الحالة",
    },
    ON_PROGRESS: {
        en: "ON PROGRESS",
        ar: "قيد التقدم",
    },
    OUT_OF_DELIVERY: {
        en: "OUT OF DELIVERY",
        ar: "خارج التسليم",
    },
    DELIVERED: {
        en: "DELIVERED",
        ar: "تم التوصيل",
    },
    SEARCH: {
        en: "Search...",
        ar: "بحث...",
    },
    FILTER_BY_SERVICES: {
        en: "Filter by services",
        ar: "تصفية حسب الخدمات",
    },
    CHAT_WITH: {
        en: "Chat with",
        ar: "الدردشة مع",
    },
    WRITE_HERE: {
        en: "Write here...",
        ar: "اكتب هنا...",
    },
    SHOW_OFFERS: {
        en: "Show Offers",
        ar: "عرض العروض",
    },
    DRIVER_NAME: {
        en: "Driver Name",
        ar: "اسم السائق",
    },
    ACTION: {
        en: "Action",
        ar: "عمل",
    },
    ACCEPT: {
        en: "Accept",
        ar: "قبول",
    },
    ABOUT_US_DESC: {
        en: `  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eum consectetur dolorum asperiores.
        Laborum possimus sequi odit natus commodi recusandae velit a? Magnam at culpa quae iusto distinctio
        harum tempore. Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eum consectetur dolorum
        asperiores. Laborum possimus sequi odit natus commodi recusandae velit a? Magnam at culpa quae iusto
        distinctio harum tempore. Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eum consectetur
        dolorum asperiores. Laborum possimus sequi odit natus commodi recusandae velit a? Magnam at culpa quae
        iusto distinctio harum tempore.`,
        ar: `  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eum consectetur dolorum asperiores.
        Laborum possimus sequi odit natus commodi recusandae velit a? Magnam at culpa quae iusto distinctio
        harum tempore. Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eum consectetur dolorum
        asperiores. Laborum possimus sequi odit natus commodi recusandae velit a? Magnam at culpa quae iusto
        distinctio harum tempore. Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eum consectetur
        dolorum asperiores. Laborum possimus sequi odit natus commodi recusandae velit a? Magnam at culpa quae
        iusto distinctio harum tempore.`
    },
    GENERAL: {
        en: "General",
        ar: "عام"
    },
    GENERAL_DESC: {
        en: ` 1.1 Scope. Welcome to SHEIN.com. These Terms & Conditions ( "Agreement" or "Terms" ) are a contract
        between you and Roadget Business Pte. Ltd., as operator of the Site and App (as defined below), and
        Fashion Choice Pte. Ltd. (the "Company" ), as the company that uses the Site and App to sell products to
        you, collect payment and process your returns where applicable, "SHEIN" , "we" , "us" and "our" shall
        refer to Roadget Business Pte. Ltd. and/or Fashion Choice Pte. Ltd. and "you" or "your" shall refer to
        the user of the Services (as defined below). These Terms govern your use of our website at SHEIN.com (the
        "Site," which includes local versions of the Site, such as br.shein.com), any mobile applications (
        "Apps" ) that hyperlink to this Agreement, any other written, electronic, and oral communications with
        SHEIN and its affiliated entities, or any websites, pages, features, or content owned and operated by us
        that hyperlink to this Agreement (collectively, including the Site and Apps, the "Services" ). There are
        other websites that use the SHEIN trademark which are operated by the Company’s affiliated entities (
        "Other SHEIN Sites" ). There may be terms and conditions linked to the Other SHEIN Sites which may apply
        to you if you access those sites as well. By accessing or using the Services in any manner, including,
        but not limited to, visiting or browsing the Site, downloading the mobile applications, registering an
        account, or contributing content or other materials to the Site or on or via the Apps, you expressly
        understand, acknowledge and agree that you have read and understood the Terms and agree to be bound by
        such terms found on the Site. You are only authorized to use the Services if you agree to abide by all
        applicable laws and to these Terms. If you reside in one country but order products to be shipped to
        another country, you may be redirected to the local Site of the country, which may be an Other SHEIN
        Site. You will be subject to the Terms of that local Site. In addition, you may read our Privacy & Cookie
        Policy at any time for more information about how the Company collects, stores, and protects your
        information when you use the Services. Our Privacy & Cookie Policy is hereby incorporated by reference
        into these Terms as though fully set forth herein. 1.2 Updates to the Terms. We reserve the right to
        modify the Terms, including the Privacy & Cookie Policy, at any time, in our sole discretion. By
        continuing to use the Services, following such changes (regardless of whether a notice was sent by the
        Company), you agree to be bound by any variation made by us to this policy and/or accompanying
        policies/agreements including, but not limited to, the Privacy & Cookie Policy. It is your responsibility
        to check this policy and/or accompanying policies/agreements from time to time to verify such variations.
        1.3 Acceptance of Terms. To shop with us, you need to be at least 16 years old. Any accessing, browsing,
        or otherwise using the Services indicates your agreement to all the terms and conditions in this
        Agreement. If you disagree with any part of the Terms, then you should immediately discontinue access or
        use of the Services. Please read this Agreement carefully before proceeding. If you have any questions
        regarding these Terms & Conditions or our Privacy & Cookie Policy, you may contact us anytime at
        legal@shein.com or use our Customer Service Platform as described in the "Contact Us" section below.`,
        ar: `1.1 النطاق. مرحبا بكم في موقع شي إن. هذه الشروط والأحكام ("الاتفاقية" أو "الشروط") هي عقد
        بينك وبين Roadget Business Pte. ، كمشغل للموقع والتطبيق (على النحو المحدد أدناه) ، و
        اختيار الموضة بي تي إي. Ltd. ("الشركة") ، بصفتها الشركة التي تستخدم الموقع والتطبيق لبيع المنتجات إليها
        عليك ، تحصيل المدفوعات ومعالجة المرتجعات الخاصة بك عند الاقتضاء ، "شي إن" و "نحن" و "نحن" و "لدينا"
        الرجوع إلى Roadget Business Pte. Ltd. و / أو Fashion Choice Pte. Ltd. و "أنت" أو "الخاص بك" تشير إلى
        مستخدم الخدمات (على النحو المحدد أدناه). تحكم هذه الشروط استخدامك لموقعنا على SHEIN.com (ملف
        "الموقع" ، والذي يتضمن الإصدارات المحلية من الموقع ، مثل br.shein.com) ، وأي تطبيقات للهاتف المحمول (
        "التطبيقات") هذا الارتباط التشعبي لهذه الاتفاقية ، وأي اتصالات أخرى مكتوبة وإلكترونية وشفوية مع
        شي إن والكيانات التابعة لها ، أو أي مواقع ويب أو صفحات أو ميزات أو محتوى مملوك لنا ونديره
        هذا الارتباط التشعبي لهذه الاتفاقية (إجمالاً ، بما في ذلك الموقع والتطبيقات ، "الخدمات"). هناك
        مواقع الويب الأخرى التي تستخدم علامة شي إن التجارية التي تديرها الكيانات التابعة للشركة (
        "مواقع شي إن الأخرى"). قد تكون هناك شروط وأحكام مرتبطة بمواقع شي إن الأخرى التي قد تنطبق
        لك إذا قمت بالوصول إلى هذه المواقع أيضًا. من خلال الوصول إلى الخدمات أو استخدامها بأي طريقة ، بما في ذلك ،
        على سبيل المثال لا الحصر ، زيارة أو تصفح الموقع ، وتنزيل تطبيقات الهاتف المحمول ، وتسجيل
        حساب ، أو المساهمة بمحتوى أو مواد أخرى على الموقع أو على أو عبر التطبيقات ، أنت صراحة
        تفهم وتقر وتوافق على أنك قد قرأت وفهمت الشروط وتوافق على الالتزام بها
        هذه الشروط الموجودة على الموقع. لا يحق لك استخدام الخدمات إلا إذا وافقت على الالتزام بكل شيء
        القوانين المعمول بها وهذه الشروط. إذا كنت تقيم في بلد واحد ولكنك تطلب شحن المنتجات إليه
        في دولة أخرى ، قد تتم إعادة توجيهك إلى الموقع المحلي للبلد ، والذي قد يكون موقع SHEIN آخر
        موقع. ستخضع لشروط ذلك الموقع المحلي. بالإضافة إلى ذلك ، يمكنك قراءة الخصوصية وملفات تعريف الارتباط الخاصة بنا
        السياسة في أي وقت لمزيد من المعلومات حول كيفية قيام الشركة بجمع وتخزين وحماية
        المعلومات عند استخدام الخدمات. سياسة الخصوصية وملفات تعريف الارتباط الخاصة بنا مدمجة هنا بالإشارة
        في هذه الشروط كما لو تم تحديدها بالكامل هنا. 1.2 تحديثات الشروط. نحن نحتفظ بالحق في
        تعديل الشروط ، بما في ذلك سياسة الخصوصية وملفات تعريف الارتباط ، في أي وقت وفقًا لتقديرنا الخاص. بواسطة
        الاستمرار في استخدام الخدمات ، باتباع هذه التغييرات (بغض النظر عما إذا كان قد تم إرسال إشعار بواسطة
        الشركة) ، فإنك توافق على الالتزام بأي تغيير نقوم به على هذه السياسة و / أو المصاحبة
        السياسات / الاتفاقيات بما في ذلك ، على سبيل المثال لا الحصر ، سياسة الخصوصية وملفات تعريف الارتباط. إنها مسؤوليتك
        للتحقق من هذه السياسة و / أو السياسات / الاتفاقيات المصاحبة من وقت لآخر للتحقق من هذه الاختلافات.
        1.3 قبول الشروط. لكي تتسوق معنا ، يجب أن يكون عمرك 16 عامًا على الأقل. أي وصول ، تصفح ،
        أو استخدام الخدمات بطريقة أخرى يشير إلى موافقتك على جميع البنود والشروط الواردة في هذا
        اتفاق. إذا كنت لا توافق على أي جزء من الشروط ، فيجب عليك التوقف فورًا عن الوصول أو
        استخدام الخدمات. يرجى قراءة هذه الاتفاقية بعناية قبل المتابعة. إذا كان لديك أية أسئلة
        بخصوص هذه الشروط والأحكام أو سياسة الخصوصية وملفات تعريف الارتباط الخاصة بنا ، يمكنك الاتصال بنا في أي وقت على
        legal@shein.com أو استخدم منصة خدمة العملاء كما هو موضح في قسم "اتصل بنا" أدناه.`
    },
    CONTACT_US_DESC: {
        en: `  You can contact us by filling out the contact form available on our website, sending us an email, or
        using the social media platforms we participate in. We would be happy to answer all your questions and
        inquiries.`,
        ar: `يمكنك الاتصال بنا عن طريق ملء نموذج الاتصال المتاح على موقعنا ، أو إرسال بريد إلكتروني إلينا ، أويمكنك الاتصال بنا عن طريق ملء نموذج الاتصال المتاح على موقعنا الإلكتروني ، أو إرسال بريد إلكتروني إلينا ، أو استخدام منصات التواصل الاجتماعي التي نشارك فيها. يسعدنا الرد على جميع أسئلتك واستفساراتك.`
    },
    NAME: {
       en: "Name",
       ar: "الاسم"
    },
    YOUR_MESSAGE: {
        en: "Your Message",
        ar: "رسالتك"
    },
    CART: {
        en: "Cart",
        ar: "عربة التسوق"
    },
    TRACK_YOUR_ORDER: {
        en: "Track Your Order",
        ar: "تتبع طلبك"
    },
    REVIEW: {
        en: "Review",
        ar: "مراجعة"
    },
    CUSTOMER_INFORMATION: {
        en: "Customer Information",
        ar: "معلومات العميل"
    },
    ORDER: {
        en: "Order",
        ar: "طلب"
    },
    ORDER_ACCEPTED: {
        en: "Order Accepted",
        ar: "تم قبول الطلب"
    },
    OUT_OF_DELIVERY: {
        en: "Out of Delivery",
        ar: "خارج التسليم"
    },
    ORDER_ARRIVED: {
        en: "Order Arrived",
        ar: "الطلب وصل"
    },
    NO_RATE_FOUND: {
        en: "No Rate Found",
        ar: "لا يوجد تقييم"
    }
}

export default words;