import React from "react";
import { TitleDiv } from "./StyledTitle";
import { useDispatch, useSelector } from "react-redux";

const TitleSection = ({ title, subtitle, btnSell }) => {
  const language = useSelector(state => state.auth.language)

  return (
    <TitleDiv className={'d-flex w-100 justify-content-between align-items-center mb-5 title_sections ' + (language === "en" ? "ltr" : "rtl")}>
      <div className="title_subTitle">
        <h4 className="fw-normal main_h">{title} </h4>
        <span className="subtitle">{subtitle}</span>
      </div>
      <button className="btn btn_sellAll fw-bolder">{btnSell}</button>
    </TitleDiv>
  );
};

export default TitleSection;
