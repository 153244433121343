import React from "react";
import styled from "styled-components";
import { useState } from "react";
import classes from "./ChatContacts.module.scss";
import { BsChatDotsFill } from "react-icons/bs";
import StarsRate from "../../../Components/StarsRate/StarsRate";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastContacts } from "../../../Store/reducers/users";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Chat from "../chat/Chat";
import { socket } from "../../../socket/Socket";
import { saveChatUser } from "../../../Store/reducers/chats";
import words from "../../../assets/words";
socket.connect();

const ChatContacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastContacts = useSelector((state) => state.users.lastContacts);
  const language = useSelector((state) => state.auth.language);
  const loader = useSelector((state) => state.users.loading);
  const chatUserData = useSelector((state) => state.chats.chatUser);
  const [chatDetails, setChatDetails] = useState({
    openChat: false,
    chatVendor: {},
  });
  const [callApi, setCallApi] = useState(false);
  const [data, setData] = useState(null)

  useEffect(() => {
    dispatch(
      getLastContacts(JSON.parse(localStorage.getItem("userId")))
    )
  }, []);

  useEffect(()=>{
    if(lastContacts){
      let arr = []
      lastContacts?.data?.map((item)=>{
        item?.from?.id == JSON.parse(localStorage.getItem("userId")) ? arr.push(item?.to) : arr.push(item?.from)
      })
    setData(arr)
    }
  },[lastContacts])

  useEffect(() => {
    if(!chatDetails.openChat){
      socket.on("newMessage", (data) => {
        if((data.user.id != JSON.parse(localStorage.getItem("userId")))){
          dispatch(saveChatUser({
            user: data.user,
            openChat: true
          }))
         setChatDetails({
          openChat: true,
          chatVendor: data.user
         })
         setCallApi(false)
        }
      });
    }
  }, []);

  return (
    <StyleProd className="container-fluid">
      <div className={`${classes.container} row`}>
        {(chatDetails.openChat || chatUserData?.openChat) && (
          <Chat
            callApi={callApi}
            vendor={chatDetails.chatVendor || chatUserData?.user}
            onChatClose={() =>
            {  setChatDetails({ ...chatDetails, openChat: false })
              dispatch(saveChatUser({
                user: {},
                openChat: false
              }))}
            }
          />
        )}
        <div className={`${classes.subContainer} col-12 side_content pt-2`}>

          {loader && (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )}
          {data?.length === 0 && (
            <div className="text-center" style={{ height: "50%" }}>
              <b>{words.NO_DATA_FOUND_FOR_DISPLAY[language]}</b>
            </div>
          )}
          {!loader && (
            <div className={`${classes.vendorsBox} w-100 mt-2 d-flex gap-4`}>
              {data
                ?.map((vendor, index) => {
                  return (
                    <div className={`${classes.vendorDiv} shadow`} key={index}>
                      <img
                        src={vendor?.img}
                        alt="vendorPic"
                        style={{
                          width: "100%",
                          height: "200px",
                          borderRadius: "10px",
                        }}
                        crossorigin="anonymous"
                      />
                      <h4
                        style={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => navigate(`/vendor/${vendor?.id}/cat=`)}
                      >
                        {vendor?.firstname} {vendor?.lastname} 
                        <span style={{fontSize: "16px", color: "#605363"}}>
                        {vendor?.type === "USER" ? "(User)" : "(Vendor)"}
                        </span>
                      </h4>
                      <p style={{ color: "grey" }}>{vendor?.email}</p>
                      <p style={{ color: "grey" }}>{vendor?.phone}</p>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        {
                          vendor?.type !== "USER" ?
                          <div>
                          <StarsRate num={Math.ceil(vendor.rate)} size={15} />{" "}
                          <span style={{ color: "grey" }}>
                            ({vendor.rateNumbers})
                          </span>
                        </div>
                        :
                        <div>
                        <span style={{ color: "grey" }}>
                         { words.NO_RATE_FOUND[language]}
                        </span>
                      </div>
                        }
                       
                        {/* <BsChatDotsFill color="#05da73" size={25}/> */}
                      </div>
                      <div>
                        <button
                          className="btn mt-2 text-center d-flex justify-content-center align-items-center gap-2"
                          style={{
                            backgroundColor: "#605363",
                            width: "100%",
                            color: "#fff",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setChatDetails({
                              ...chatDetails,
                              openChat: true,
                              chatVendor: vendor,
                            });
                            setCallApi(true);
                          }}
                        >
                          {words.CHAT_WITH[language]} {vendor?.firstname}{" "}
                          <BsChatDotsFill size={15} />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </StyleProd>
  );
};

export default ChatContacts;

const StyleProd = styled.div`
  .side_menu_dash {
    .inf_user {
      background-color: #f7f6fa;
      padding: 15px;
      border-radius: 15px;
      margin-bottom: 3rem;
    }
  }
  .side_content {
    min-height: 100vh;
    background-color: #f7f6fa;
    border-radius: 10px;
    .inp_search {
      min-width: 50%;
      .form-control {
        padding-left: 50px !important;
        background-color: #f0eff3;
        border-color: transparent;
        &:focus {
          box-shadow: none;
          border-color: #4a3e4c;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        font-size: 22px;
      }
    }
    .btn-secondary {
      background-color: #f0eff3;
      border: none;
      outline: none;
      color: #000;
    }
    .logNotifMess {
      .inf_user {
        svg {
          display: none;
        }
      }
      .title_ {
        padding-right: 10px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .icon_ {
      background-color: #f0eff3;
      color: #dddce2;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      width: 55px;
      padding: 10px;
    }

    // Style filter
    .filters {
      .sort {
        svg {
          font-size: 20px;
          margin-left: 5px;
        }
      }
    }
  }
  .copyRight {
    color: #aaabb1;
  }
`;
