export const cutWords = (word, sliceNum) => {
    let slice_num = sliceNum ? sliceNum : 30 
    if(word.length > slice_num) {
        return word.slice(0, slice_num) + '...';
    }
    else{
        return word;
    }
}

export const validEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(emailRegex.test(email)){
        return true
    }else{
        return false
    }
}

export const validPhoneNumber = (phone) => {
    const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    if(phoneRegExp.test(phone)){
        return true
    }else{
        return false
    }
} 

export const validPassword = (pass) => {
    const passRegex = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*[!#$@%&?"]).*$/;
    if (passRegex.test(pass)) {
      return true;
    } else {
      return false;
    }
  };

  export const filterPath = (filterObj, filterName, vendorId) => {
    let path = vendorId ? `/vendor/${vendorId}/` : '/products-page/'
    Object.keys(filterObj)?.map((item)=>{
      return filterName !== item ? path += `${item}=${filterObj[item]}&` : null
    })
    return path
  }

  export const createDate = (date) => {
    return (date?.split("T")?.[0])
  }