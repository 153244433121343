import React, {useState} from "react";
import {useEffect} from "react";
import {Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import cancel from "../../assets/Images/cancel.png";
import {Link} from "react-router-dom";
import CustomModal from "../../Components/customModal/CustomModal";
import {cancelOrder, changeOrderStatus, getOrders} from "../../Store/reducers/orders";
import {createDate} from "../../utils/formatWords";
import InfoUser from "./../../Components/ProfileUser/infoUser/InfoUser";
import ListAdress from "./../../Components/ProfileUser/ListAddressUser/ListAdress";
import OrderCard from "./../../Components/ProfileUser/Order/OrderCard";
import {Profile} from "./styleUser";
import words from "../../assets/words";

const ProfileUser = () => {
   const dispatch = useDispatch();
   const user = useSelector((state) => state.auth.user);
   const orders = useSelector((state) => state.orders.orders);
   const loader = useSelector((state) => state.orders.loading);
   const cancelLoader = useSelector((state) => state.orders?.cancelLoading);
   const language = useSelector((state) => state.auth.language);

   const [showModal, setShowModal] = useState(false);
   const [currentOrder, setCurrentOrder] = useState(null);

   useEffect(() => {
      dispatch(
         getOrders({
            status: "ACCEPTED",
         })
      );
   }, []);

   const handleCancelOrder = () => {
      dispatch(
         changeOrderStatus({
            id: currentOrder?.id,
            status: "cancel",
         })
      ).then((res) => {
         setShowModal(false);
         dispatch(
            getOrders({
               status: "ACCEPTED",
            })
         );
      });
   };

   return (
      <>
         {showModal && (
            <CustomModal
               show={showModal}
               onHide={() => {
                  setShowModal(false);
               }}
               title={`${words.CANCEL_ORDER[language]} #${currentOrder?.id} ?!`}
            >
               <div className="text-center">
                  <img
                     src={cancel}
                     alt="check pic"
                     style={{width: "50%"}}
                     crossorigin="anonymous"
                  />
                  <p className="mt-3">{words.ARE_YOU_WANT_TO_CANCEL_ORDER[language]}</p>
                  <button
                     className="btn text-white w-50 mt-4"
                     onClick={handleCancelOrder}
                     style={{
                        backgroundColor: "RGBA(74, 62, 76, 0.8)",
                     }}
                  >
                     {cancelLoader ? <Spinner animation="border" /> : words.YES[language]}
                  </button>
               </div>
            </CustomModal>
         )}
         <Profile dir={language === "en" ? "ltr" : "rtl"}>
            <>
               <div className="bg-white rounded def_shadow">
                  <div className="p-4">
                     <InfoUser>
                        <div className="txt_user">
                           <p className="fw-medium">
                              {user?.firstname} {user?.lastname}
                           </p>
                           <p className="email">
                              {words.EMAIL[language]}: {user?.email} <span className="num_phone mx-5">{words.PHONE[language]}: {user?.phone}</span>
                           </p>
                        </div>
                     </InfoUser>
                     <ListAdress />
                  </div>
                  <hr
                     className="w-100"
                     style={{color: "#9da1a7"}}
                  />
                  <div className="px-4 pt-4 pb-2 mb-3">
                     <h4 className="fs-5 pb-3">{words.CURRENT_ORDERS[language]}</h4>
                     {loader && (
                        <div
                           className="text-center h-100"
                           style={{height: "100%"}}
                        >
                           <Spinner animation="border" />
                        </div>
                     )}
                     {orders?.data?.map((order, index) => {
                        return (
                           <OrderCard key={index}>
                              <div className="d-flex justify-content-between align-items-start mb-3 top__card">
                                 <div className="id_date">
                                    <p className="id_order">
                                       {words.ORDER_ID[language]}: <span>{order?.id}</span>
                                       <br/>
                                      {words.DATE[language]}:<span className="date_order mx-4">{createDate(order?.createdAt)}</span>
                                    </p>
                                    <span className="state_order fw-medium">{order?.status}</span>
                                 </div>
                                 <div className="action_order d-flex gap-2">
                                    <button
                                       className="btn cancel_ord mx-2"
                                       onClick={() => {
                                          setShowModal(true);
                                          setCurrentOrder(order);
                                       }}
                                    >
                                       {words.CANCEL_ORDER[language]}
                                    </button>
                                    <Link
                                       to={`/track-order/${order?.id}`}
                                       className="btn track_ord"
                                    >
                                       {words.TRACK_ORDER[language]}
                                    </Link>
                                 </div>
                              </div>

                              <div className="about_user border-bottom pb-3">
                                 <div className="row">
                                    <div className="col-sm-6 col-lg-3 mb-3">
                                       <span className="title d-inline-block pb-2">{words.PERSON[language]}</span>
                                       <ul className="list-group">
                                          <li>
                                             {order?.client?.firstname} {order?.client?.lastname}
                                          </li>
                                          <li>{words.PHONE[language]}: {order?.client?.phone}</li>
                                          <li>{words.EMAIL[language]}: {order?.client?.email}</li>
                                       </ul>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 mb-3">
                                       <span className="title d-inline-block pb-2">{words.ADDRESS[language]}</span>
                                       <ul className="list-group">
                                          <li className="address">
                                             {order?.area?.name}, {order?.city?.name}
                                          </li>
                                       </ul>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 mb-3">
                                       <span className="title d-inline-block pb-2">{words.PAYMENT[language]}</span>
                                       <ul className="list-group">
                                          <li className="num_credit">{order?.paymentSystem}</li>
                                          <li>{words.SHIPPING_FEE[language]}: {order?.deliveryCost}</li>
                                          <li>{words.TOTAL_PAID[language]}: {order?.finalTotal}</li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>

                              <div className="products_bought pt-3">
                                 <div className="row">
                                    {order?.orderItems?.map((item, y) => {
                                       return (
                                          <div
                                             className="col"
                                             key={y}
                                          >
                                             <div className="d-flex justify-content-start align-items-start gap-3 mb-2">
                                                <div className="image_prod border border-1 p-3 rounded-3">
                                                   <img
                                                      crossorigin="anonymous"
                                                      src={item?.product?.files ? item?.product?.files : item?.product?.images?.images?.[0]}
                                                      alt="productShorts"
                                                      style={{
                                                         width: "80px",
                                                         height: "80px",
                                                         objectFit: "contain",
                                                      }}
                                                   />
                                                </div>
                                                <div className="txt_product">
                                                   <p className="title_ pb-2">{item?.product?.name}</p>
                                                   <p className="title_ pb-2">
                                                      {item?.size?.name}, {item?.color?.name}
                                                   </p>
                                                   <p className="num_products_price">
                                                      {item?.count}x = {item?.unitCost}
                                                   </p>
                                                </div>
                                             </div>
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           </OrderCard>
                        );
                     })}
                  </div>
               </div>
            </>
         </Profile>
      </>
   );
};
export default ProfileUser;
