import styled from "styled-components";

export const StyledHeaderCheck = styled.div`
   margin-bottom: 5rem;
   padding: 0;
   @media only screen and (max-width: 991px) {
      margin-bottom: 2rem;
   }
   .b_t_shop {
      border: 1px solid #5c6ac4;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      font-weight: 500;
   }
`;
