import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getFavorites } from "../../../Store/reducers/favorites";
import ProdctCrd from "./../../../Components/ReusableComponent/CardProduct/CardProduct";
import words from "../../../assets/words";

const WishList = () => {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state?.favorites?.favorites);
  const loader = useSelector((state) => state?.favorites?.loading);
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    dispatch(getFavorites()).then((res) => {
    });
  }, []);

  return (
    <div className="row bg-white pt-4 rounded">
      {loader ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : favorites?.data?.length === 0 ? (
        <div>
          <p className="text-center">{words.NO_PRODUCTS_FOUND[language]}!</p>
        </div>
      ) : (
        favorites?.data?.map((fav, index) => {
          return (
            <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
              <ProdctCrd
                fav={true}
                // addToCard={true}
                item={fav}
                addToFav={true}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default WishList;
