import React from "react";
import { BiEditAlt, BiSearch } from "react-icons/bi";
import styled from "styled-components";
import AddProductDash from "../../../Components/Dashboard/AddProduct/AddProductDash";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbSquarePlus } from "react-icons/tb";
import Table from "../../../Components/table/Table";
import { useState } from "react";
import classes from "./ProductsDash.module.scss";
import productImg from "../../../assets/Images/bigImage.png";
import offerPic from "../../../assets/Images/offer.png";
import cancel from "../../../assets/Images/delete.png";
import { cutWords } from "../../../utils/formatWords";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductOffer,
  clearProducts,
  getProducts,
  removeProduct,
  removeProductOffer,
} from "../../../Store/reducers/products";
import CustomModal from "../../../Components/customModal/CustomModal";
import { Spinner } from "react-bootstrap";
import Pagination from "../../../Components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import words from "../../../assets/words";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const categories = useSelector((state) => state.categories.categories);
  const removeLoader = useSelector((state) => state.products.removeLoading);
  const language = useSelector((state) => state.auth.language);
  const removeOfferLoader = useSelector(
    (state) => state.products.removeOfferLoader
  );
  const addOfferLoader = useSelector((state) => state.products.addOfferLoader);
  const loading = useSelector((state) => state.products.dashLoading);
  const data = useSelector((state) => state.products.products);
  // const [data, setData] = useState([]);
  const [search, setSearch] = useState({
    value: "",
    enterValue: "",
  });
  const [category, setCategory] = useState("");
  const [showmodals, setShowModals] = useState({
    deleteProduct: false,
    addOffer: false,
    deleteOffer: false,
  });
  const [saveProduct, setSaveProduct] = useState({});
  // const [loading, setLoading] = useState(false);
  const [productOffer, setProductOffer] = useState("");
  const [page, setPage] = useState(1);
  const [getData, setGetData] = useState(false)

  const columns = [
    {
      cell: (row) => (
        <div data-tag="allowRowEvents" className="gap-2">
          <img
            crossorigin="anonymous"
            src={
              row?.files?.[0]
                ? row?.files?.[0]
                : row?.images?.[0]
                ? row?.images?.[0]?.images?.[0]
                : productImg
            }
            alt="Student Pic"
            className="avatar"
            data-tag="allowRowEvents"
            style={{
              width: "50px",
              padding: 0,
              height: "50px",
              objectFit: "contain",
            }}
          />
          <div>{cutWords(row.name, 15)}</div>
        </div>
      ),
      name: words.PRODUCT_NAME[language],
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      width: "200px"
    },
    {
      name: words.CATEGORY[language],
      selector: (row) => row.subCategory.name,
      sortable: true,
      wrap: true,
    },
    {
      name: words.BRAND[language],
      selector: (row) => row.brand.name,
      sortable: true,
      wrap: true,
    },
    {
      name: words.QUANTITY[language],
      selector: (row) => row.quantity,
      sortable: true,
      wrap: true,
    },
    {
      name: words.PRICE[language],
      selector: (row) => row.priceTo,
      sortable: true,
      wrap: true,
    },
    {
      name: words.RATE[language],
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <p style={{ color: row.rateCount <= 2.5 && "red" }}>
            {row.rateCount}
          </p>
        </div>
      ),
      selector: (row) => row.rateCount,
      sortable: true,
      wrap: true,
    },
    {
      name: words.OFFER[language],
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <p style={{ color: row?.offer && "green" }}>
            {row?.hasOffer && row?.offer
              ? `${row?.offer?.offerRatio}%`
              : words.NO_OFFER[language]}
          </p>
        </div>
      ),
      selector: (row) => row.rateCount,
      sortable: true,
      wrap: true,
    },
    {
      cell: (row) => (
        <div
          style={{
            display: "flex",
            gap: 1,
          }}
        >
          <button className="btn" onClick={() => navigate(`/dashboard/add-product/${row?.id}`)}>
            <BiEditAlt color="#83beec" size={20} />
          </button>
          <button className="btn" onClick={() => deleteProduct(row)}>
            <RiDeleteBin6Line color={"#e91e63"} size={20} />
          </button>
        </div>
      ),
      // width: '65px',
      name: words.PRODUCT_ACTIONS[language],
      sortable: true,
      wrap: true,
    },
    {
      cell: (row) => (
        <div
          style={{
            display: "flex",
            gap: 1,
          }}
        >
          {row?.hasOffer && row?.offer ? (
            <>
              {/* <button className="btn" onClick={() => addOffer(row)}>
                <BiEditAlt color="#83beec" size={20} />
              </button> */}
              <button
                className="btn"
                onClick={() => {
                  setSaveProduct(row);
                  setShowModals({
                    ...showmodals,
                    deleteProduct: false,
                    deleteOffer: true,
                    addOffer: false,
                  });
                }}
              >
                <RiDeleteBin6Line color={"#e91e63"} size={20} />
              </button>
            </>
          ) : (
            <button className="btn" onClick={() => addOffer(row)}>
              <TbSquarePlus color={"#05da73"} size={25} />
            </button>
          )}
        </div>
      ),
      // width: '65px',
      name: words.OFFER_ACTIONS[language],
      sortable: true,
      wrap: true,
    },
  ];

  useEffect(() => {
    setProductOffer(saveProduct?.offer?.offerRatio);
  }, [saveProduct]);


  useEffect(() => {
    if(user){
    dispatch(clearProducts())
   dispatch(
      getProducts({
        owner: user?.id,
        search: search.enterValue,
        category: category,
        page: page,
      })
    ).then((res)=>{
      setGetData(true)
    })
    }
  }, [user, category, search.enterValue, page]);

  const deleteProduct = (row) => {
    setSaveProduct(row);
    setShowModals({ ...showmodals, deleteProduct: true, addOffer: false });
  };

  const handleDeleteProduct = () => {
    dispatch(removeProduct(saveProduct.id)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setShowModals({ ...showmodals, deleteProduct: false });
        dispatch(getProducts({ owner: user?.id, page: 1 }));
      }
    });
  };

  const handleDeleteOffer = () => {
    dispatch(removeProductOffer(saveProduct.id)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setShowModals({ ...showmodals, deleteOffer: false });
        dispatch(getProducts({ owner: user?.id, page: 1 }));
      }
    });
  };

  const addOffer = (row) => {
    setProductOffer("");
    setSaveProduct(row);
    setShowModals({ ...showmodals, addOffer: true, deleteProduct: false });
  };

  const handleAddOffer = () => {
    dispatch(
      addProductOffer({
        id: saveProduct?.id,
        offerRatio: productOffer,
      })
    ).then((res) => {
      setShowModals({ ...showmodals, addOffer: false, deleteProduct: false });
      dispatch(
        getProducts({
          owner: user?.id,
          page: 1,
        })
      );
    });
  };

  return (
    <StyleProd className="container-fluid">
      <div className="row">
        {showmodals.deleteProduct && (
          <CustomModal
            show={showmodals.deleteProduct}
            onHide={() =>
              setShowModals({ ...showmodals, deleteProduct: false })
            }
            title={words.DELETE_PRODUCT[language]}
          >
            <div className="text-center">
              <img src={cancel} alt="check pic" style={{ width: "50%" }}  crossorigin="anonymous"/>
              <p className="mt-3">{words.ARE_YOU_WANT_TO_DELETE_PRODUCT[language]}</p>
              <button
                className="btn text-white w-50 mt-4"
                onClick={handleDeleteProduct}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
                disabled={removeLoader}
              >
                {removeLoader ? <Spinner animation="border" /> : words.YES[language]}
              </button>
            </div>
          </CustomModal>
        )}
        {showmodals.deleteOffer && (
          <CustomModal
            show={showmodals.deleteOffer}
            onHide={() => setShowModals({ ...showmodals, deleteOffer: false })}
            title={words.DELETE_OFFER[language]}
          >
            <div className="text-center">
              <img src={cancel} alt="check pic" style={{ width: "50%" }}  crossorigin="anonymous"/>
              <p className="mt-3">
                {words.ARE_YOU_WANT_TO_DELETE_OFFER_PRODUCT[language]}
              </p>
              <button
                className="btn text-white w-50 mt-4"
                onClick={handleDeleteOffer}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
                disabled={removeOfferLoader}
              >
                {removeOfferLoader ? <Spinner animation="border" /> : words.YES[language]}
              </button>
            </div>
          </CustomModal>
        )}
        {showmodals.addOffer && (
          <CustomModal
            show={showmodals.addOffer}
            onHide={() => setShowModals({ ...showmodals, addOffer: false })}
            title={words.ADD_PRODUCT_OFFER[language]}
          >
            <div className="text-center" dir={language === "en" ? "ltr" : "rtl"}>
              <img src={offerPic} alt="check pic" style={{ width: "50%" }}  crossorigin="anonymous"/>
              <div className="text-start">
                {/* <p className="my-3">Add offer ratio</p> */}
                <input
                  placeholder={words.PRODUCT_OFFER[language]}
                  type="number"
                  name="offer"
                  className="form-control my-3"
                  onChange={(e) => setProductOffer(e.target.value)}
                  value={productOffer}
                />
              </div>

              <button
                className="btn text-white w-50 mt-4"
                onClick={handleAddOffer}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
                disabled={!productOffer || addOfferLoader}
              >
                {addOfferLoader ? <Spinner animation="border" /> : words.ADD_PRODUCT_OFFER[language]}
              </button>
            </div>
          </CustomModal>
        )}
        <div className={`${classes.container} col-12 side_content`}>
          <div className="search&log d-flex justify-content-between align-items-center">
            <div
              style={{
                marginLeft: "auto",
                marginTop: "10px",
              }}
            >
              <AddProductDash />
            </div>
          </div>
          {/*Filter section*/}
          <div className="addProduct_&_filter d-md-flex gap-2 d-block justify-content-between align-items-center">
            <div className="inp_search position-relative">
              <input
                className="form-control py-3"
                placeholder={words.SEARCH_ABOUT_PRODUCT[language]}
                value={search.value}
                onChange={(e) =>
                  setSearch({ ...search, value: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    setSearch({ ...search, enterValue: e.target.value });
                  }
                }}
              />
              <BiSearch />
            </div>
            <div className="filters d-flex justify-content-start gap-4 mt-2">
              {/*Filter Sort*/}
              <div class="dropdown">
                <select
                  id="inputState"
                  className="form-select"
                  name="city"
                  style={{
                    width: "100%",
                  }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  placeholder="Filter By Category"
                >
                  <option value="">{words.FILTER_BY_CATEGORY[language]}</option>
                  {categories?.data?.map((cat, index) => {
                    return (
                      <option
                        value={cat?.id}
                        key={index}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setCategory(e.target.value);
                        }}
                      >
                        {cat?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          {/*List Products =====*/}

          <div className="pt-1">
            {!loading && data?.data?.length > 0 && getData && (
              <Table
                columns={columns}
                data={data?.data}
                noData={words.NO_DATA_FOUND_FOR_DISPLAY[language]}
              />
            )}
          </div>

          {data?.data?.length === 0 && !loading && (
            <div className="text-center" style={{ height: "50%" }}>
              <b>{words.NO_DATA_FOUND_FOR_DISPLAY[language]} </b>
            </div>
          )}

          {loading && (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )}

          {data?.totalCount > 20 && (
            <Pagination
              setPageNumber={setPage}
              count={data?.totalCount}
              limit={20}
            />
          )}
        </div>
      </div>
    </StyleProd>
  );
};

export default Products;

const StyleProd = styled.div`
.addProduct_&_filter{
  background-color: #000;
}
  .side_menu_dash {
    .inf_user {
      background-color: #f7f6fa;
      padding: 15px;
      border-radius: 15px;
      margin-bottom: 3rem;
    }
  }
  .side_content {
    min-height: 100vh;
    background-color: #f7f6fa;
    border-radius: 10px;
    .inp_search {
      min-width: 50%;
      .form-control {
        padding-left: 50px !important;
        background-color: #f0eff3;
        border-color: transparent;
        &:focus {
          box-shadow: none;
          border-color: #4a3e4c;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        font-size: 22px;
      }
    }
    .btn-secondary {
      background-color: #f0eff3;
      border: none;
      outline: none;
      color: #000;
    }
    .logNotifMess {
      .inf_user {
        svg {
          display: none;
        }
      }
      .title_ {
        padding-right: 10px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .icon_ {
      background-color: #f0eff3;
      color: #dddce2;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      width: 55px;
      padding: 10px;
    }

    // Style filter
    .filters {
      .sort {
        svg {
          font-size: 20px;
          margin-left: 5px;
        }
      }
    }
  }
  .copyRight {
    color: #aaabb1;
  }
`;
