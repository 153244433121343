import React, { useState } from "react";
import { useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { filterPath } from "../../utils/formatWords";
import StarsRate from "../StarsRate/StarsRate";
import { StyleFilter } from "./StyleFilter";
import { AiOutlineFilter, AiOutlineClose } from "react-icons/ai";

import "./Filtering.scss";
import words from "../../assets/words";
const SideFiltring = ({ filterObj }) => {
   //navigate
   const navigate = useNavigate();
   //roter
   const vendorId = useParams()?.id;
   const filter = useParams()?.filter;

   const categories = useSelector((state) => state.categories.categories?.data);
   const brands = useSelector((state) => state.brands.brands?.data);
   const category = useSelector((state) => state.categories.category?.data);
   const language = useSelector((state) => state.auth.language);

   const [starClicked, setClickedStar] = useState(0);
   const [minPrice, setMinPrice] = useState(filterObj?.price?.split(",")[0]);
   const [maxPrice, setMaxPrice] = useState(filterObj?.price?.split(",")[0]);

   useEffect(() => {
      starClicked != 0 && navigate(`${filterPath(filterObj, "rate", vendorId)}rate=${starClicked}`);
   }, [starClicked]);

   useEffect(() => {
      setMinPrice(filterObj?.price?.split(",")[0]);
      setMaxPrice(filterObj?.price?.split(",")[1]);
   }, [filterObj]);
   const [sideFilter, setSideFilter] = useState(false);
   const toggleFilter = () => {
      setSideFilter((prevState) => !sideFilter);
   };

   return (
      <>
         <div
            className="filterIcon"
            onClick={toggleFilter}
         >
            <AiOutlineFilter />
         </div>
         <div className={sideFilter ? "side__filter show_filter" : "side__filter"}>
            <div
               className="close_filter"
               onClick={toggleFilter}
            >
               <AiOutlineClose />
            </div>
            <StyleFilter className="shadow-sm rounded">
               <div className="accordion">
                  {/* ========== List filter categories ========== */}
                  <div className="accordion-item px-1">
                     <h2 className="accordion-header">
                        <button
                           className="accordion-button d-flex align-items-center justify-content-between px-3"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#categories"
                           aria-expanded="true"
                           aria-controls="categories"
                        >
                           {words.CATEGORIES[language]}
                           <MdKeyboardArrowDown />
                        </button>
                     </h2>
                     <div
                        id="categories"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                     >
                        <div className="accordion-body px-0 pt-0">
                           <ul class="list-group px-0">
                              <li class="list-group-item rounded"
                                 style={{
                                    backgroundColor: category && !category?.id ? "RGBA(74, 62, 76, 0.2)" : "#fff",
                                 }}
                                 onClick={() => {
                                    navigate(`${vendorId ?`/vendor/${vendorId}/` : "/products-page/"}cat=`);
                                 }}>{words.ALL[language]}</li>
                              {categories?.map((cat, index) => {
                                 return (
                                    <li
                                       class="list-group-item rounded"
                                       key={index}
                                       style={{
                                          backgroundColor: category?.id === cat?.id ? "RGBA(74, 62, 76, 0.2)" : "#fff",
                                       }}
                                       onClick={() => {
                                          navigate(`${vendorId ? `/vendor/${vendorId}/` : "/products-page/"}cat=${cat?.id}`);
                                       }}
                                    >
                                       {" "}
                                       {cat.name}
                                    </li>
                                 );
                              })}
                           </ul>
                        </div>
                     </div>
                  </div>

                  {/* ========== List filter Brand ========== */}
                  <div class="accordion-item px-1">
                     <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingTwo"
                     >
                        <button
                           className="accordion-button d-flex align-items-center justify-content-between px-3"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#Condition"
                           aria-expanded="false"
                           aria-controls="Condition"
                        >
                           {words.BRANDS[language]}
                           <MdKeyboardArrowDown />
                        </button>
                     </h2>
                     <div
                        id="Condition"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                     >
                        <div class="accordion-body pt-0 px-3">
                           <form className="d-flex flex-column gap-3">
                              {brands?.map((brand, index) => {
                                 return (
                                    <label
                                       className="inp_choose"
                                       key={index}
                                    >
                                       <div className="cont__ d-flex justify-content-start align-items-center gap-2">
                                          <input
                                             type="radio"
                                             name="condition"
                                             onChange={() => {
                                                navigate(
                                                   `${filterPath(filterObj, "brand", vendorId)}brand=${brand?.id}`
                                                );
                                             }}
                                             checked={filterObj?.brand == brand?.id}
                                          />
                                          <span>{brand.name}</span>
                                       </div>
                                    </label>
                                 );
                              })}
                           </form>
                        </div>
                     </div>
                  </div>

                  {/* ========== List filter Prices ========== */}
                  <div class="accordion-item px-1">
                     <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingTwo"
                     >
                        <button
                           className="accordion-button d-flex align-items-center justify-content-between px-3"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#Prices"
                           aria-expanded="false"
                           aria-controls="Prices"
                        >
                           {words.PRICES[language]}
                           <MdKeyboardArrowDown />
                        </button>
                     </h2>
                     <div
                        id="Prices"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                     >
                        <div class="accordion-body pt-0 px-3">
                           {/* <div class="wrapper">
                <div class="contain">
                  <div class="slider-track"></div>
                  <input type="range" min="0" max="100" id="slider-1" />
                  <input type="range" min="0" max="100" id="slider-2" />
                </div>
              </div> */}
                           <div className="inpts_m d-flex justify-content-between gap-3">
                              <div>
                                 <label>{words.MIN[language]}</label>
                                 <input
                                    value={minPrice}
                                    onChange={(e) => {
                                       setMinPrice(e.target.value);
                                    }}
                                    type="number"
                                    className="form-control"
                                    placeholder={`0.00${words.SR[language]}`}
                                 />
                              </div>
                              <div>
                                 <label>{words.MAX[language]}</label>
                                 <input
                                    type="number"
                                    className="form-control"
                                    placeholder={`0.00${words.SR[language]}`}
                                    value={maxPrice}
                                    onChange={(e) => {
                                       setMaxPrice(e.target.value);
                                    }}
                                 />
                              </div>
                           </div>
                           <button
                              className="btn border w-100 mt-3"
                              disabled={!minPrice || !maxPrice}
                              onClick={() => {
                                 navigate(
                                    `${filterPath(filterObj, "price", vendorId)}price=${minPrice + "," + maxPrice}`
                                 );
                              }}
                           >
                              {words.APPLY[language]}
                           </button>
                        </div>
                     </div>
                  </div>

                  {/* ========== List filter Ratings stars ========== */}
                  <div class="accordion-item px-1">
                     <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingTwo"
                     >
                        <button
                           className="accordion-button d-flex align-items-center justify-content-between px-3"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#Ratings"
                           aria-expanded="false"
                           aria-controls="Ratings"
                        >
                           {words.RATINGS[language]}
                           <MdKeyboardArrowDown />
                        </button>
                     </h2>
                     <div
                        id="Ratings"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                     >
                        <div class="accordion-body pt-0 px-3">
                           <StarsRate
                              num={filterObj?.rate}
                              clicked={true}
                              setClickedStar={setClickedStar}
                           />
                        </div>
                     </div>
                     <button
                        className="btn text-white w-50 mb-4 ms-2"
                        onClick={() => {
                           vendorId ? navigate(`/vendor/${vendorId}/cat=`) : navigate("/products-page/cat=");
                        }}
                        style={{
                           backgroundColor: "RGBA(74, 62, 76, 0.8)",
                        }}
                        disabled={filter === "cat=" ? true : false}
                     >
                        {words.RESET[language]}
                     </button>
                  </div>
               </div>
            </StyleFilter>
         </div>
      </>
   );
};

export default SideFiltring;
