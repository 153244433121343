import React from "react";
import "./terms.scss";
import { useSelector } from "react-redux";
import words from "../../assets/words";

const Terms = () => {
   const language = useSelector(state => state.auth.language)

   return (
      <div className="terms"
         dir={language === "ar" ? "rtl" : "ltr"}
      >
         <div className="head_page mb-4">
            <div className="container">
               <h3>{words.TERMS_AND_CONDITIONS[language]}</h3>
            </div>
         </div>
         <div className="container">
            <p className="mb-5 mt-5 fw-bold fs-3 text-center">Effective Date: june 3, 2023</p>

            <h3 className="mb-3 title_txt">{words.GENERAL[language]}</h3>
            <p className="desc">
              {words.GENERAL_DESC[language]}
            </p>
         </div>
      </div>
   );
};

export default Terms;
