import { useState } from "react";
import user from "../../../assets/Images/user-placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import CustomModal from "../../../Components/customModal/CustomModal";
import { Spinner } from "react-bootstrap";
import {
  getMyInfo,
  updateInfo,
  updatePassword,
} from "../../../Store/reducers/auth";
import { getCountries } from "../../../Store/reducers/countries";
import classes from "./UpdateProfile.module.scss";
import words from "../../../assets/words";

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const User = useSelector((state) => state.auth.user);
  const updateLoader = useSelector((state) => state.auth.updateLoading);
  const countries = useSelector((state) => state.countries.countries);
  const passwordLoader = useSelector((state) => state.auth.updatePassword);
  const language = useSelector((state) => state.auth.language);
  const [userData, setUserData] = useState(User);
  const [userPassword, setUserPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(true);
  const [passError, setPassError] = useState("");

  const handleOnChange = (e) => {
    if (e.target.type == "file") {
      setImage(URL.createObjectURL(e.target.files[0]));
      setUserData({ ...userData, [e.target.name]: e.target.files[0] });
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };

  const handlePassData = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
  };

  const handleUpdateInfo = () => {
    if (!error) {
      dispatch(updateInfo({ ...userData, image: image })).then((res) => {
        if (res.meta.requestStatus == "fulfilled") {
          dispatch(getMyInfo());
        }
      });
    }
  };

  const handleChangePassword = () => {
    dispatch(updatePassword(userPassword)).then((res) => {
      if (res.meta.requestStatus == "rejected") {
        setPassError(res.payload.errors?.[0].msg);
      } else {
        setPassError("");
        setShowModal(false);
        dispatch(getMyInfo());
      }
    });
  };

  useEffect(() => {
    setUserData(User);
    dispatch(getCountries());
  }, [User]);

  useEffect(() => {
    if (
      userData?.firstname &&
      userData?.lastname &&
      userData?.email &&
      userData?.phone
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [userData]);

  useEffect(() => {
    setUserPassword({
      currentPassword: "",
      newPassword: "",
    });
    setPassError("");
  }, [showModal]);

  return (
    <div className={`${classes.container} shadow p-2 pb-5`}>
      {showModal && (
        <CustomModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          title={`${words.CHANGE_PASSWORD[language]} ?`}
        >
          <div dir={language === "en" ? "ltr" : "rtl"}>
            <input
              name="currentPassword"
              type="password"
              value={userPassword.currentPassword}
              onChange={handlePassData}
              placeholder={`${words.CURRENT_PASSWORD[language]} *`}
              className="form-control mb-3"
            />
            <input
              name="newPassword"
              type={"password"}
              value={userPassword.newPassword}
              onChange={handlePassData}
              placeholder={`${words.NEW_PASSWORD[language]} *` }
              className="form-control"
            />

            <p className="text-danger">{passError}</p>
            <div className="text-center">
              <button
                className="btn text-white w-50 mt-4"
                onClick={handleChangePassword}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
                disabled={
                  userPassword.currentPassword && userPassword.newPassword
                    ? false
                    : true
                }
              >
                {passwordLoader ? (
                  <Spinner animation="border" />
                ) : (
                  words.CHANGE_PASSWORD[language]
                )}
              </button>

              {userData?.idImgs && <a href={userData?.idImgs[0]}>
                <img src={userData?.idImgs[0]} width={100} />
              </a>}

              {userData?.licenseImgs && <a href={userData?.licenseImgs[0]}>
                <img src={userData?.licenseImgs[0]} width={100} />
              </a>}
            </div>
          </div>
        </CustomModal>
      )}
      <h3 className="text-center my-3">{words.UPDATE_YOUR_PROFILE[language]}</h3>
      <div className="text-center">
        <input
          className="d-none"
          type="file"
          id="user-pic"
          name="img"
          onChange={handleOnChange}
        />
        <label htmlFor="user-pic">
          <img
            src={image ? image : userData?.img ? userData?.img : user}
            style={{
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              //   objectFit: "contain",
              marginBottom: "15px",
              cursor: "pointer",
            }}
            alt="user pic"
            crossorigin="anonymous"
          />
        </label>
      </div>
      <div className={`${classes.row} d-flex gap-2 mb-3`}>
        <input
          name="firstname"
          placeholder={words.FIRST_NAME[language]}
          className="form-control"
          onChange={handleOnChange}
          value={userData?.firstname}
        />
        <input
          name="lastname"
          placeholder={words.LAST_NAME[language]}
          className="form-control "
          onChange={handleOnChange}
          value={userData?.lastname}
        />
      </div>
      <div className={`${classes.row} d-flex gap-2 mb-3`}>
        <input
          name="email"
          placeholder={words.EMAIL[language]}
          className="form-control w-100"
          onChange={handleOnChange}
          value={userData?.email}
        />
        <div className="d-flex w-100 gap-1">
          <select
            id="inputState"
            className="form-select"
            name="country"
            style={{
              width: "50%",
            }}
            value={userData?.country?.id}
            onChange={handleOnChange}
          >
            <option value="Select" disabled>
              {words.COUNTRY[language]}
            </option>
            {countries?.map((country, index) => {
              return (
                <option value={country?.id} key={index}>
                  {country?.name}
                </option>
              );
            })}
          </select>
          <input
            name="phone"
            placeholder={words.PHONE_NUMBER[language]}
            className="form-control w-100"
            onChange={handleOnChange}
            value={userData?.phone}
          />
        </div>
      </div>
      <div className={`${classes.row} d-flex`}>
        <p
          className="my-3"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {words.CHANGE_PASSWORD[language]}* <AiFillEdit size={15} />
        </p>
      </div>
      <button
        className="btn text-white px-5 mt-4"
        style={{
          backgroundColor: error ? "grey" : "RGBA(74, 62, 76, 0.8)",
        }}
        onClick={handleUpdateInfo}
        disabled={error || updateLoader}
      >
        {updateLoader ? <Spinner animation="border" /> : words.UPDATE[language]}
      </button>
    </div>
  );
};

export default UpdateProfile;
