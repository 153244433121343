import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "./../../Components/ReusableComponent/Footer/Footer";
import NavbarComp from "./../../Components/ReusableComponent/Navbar/NavbarComp";
import { socket } from "../../socket/Socket";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveChatUser } from "../../Store/reducers/chats";
import Chat from "../Dashboard/chat/Chat";
import { useSelector } from "react-redux";

socket.connect();

const Layout = () => {
  const dispatch = useDispatch();
  const chatUserData = useSelector((state) => state.chats.chatUser);
  const callApi = useSelector((state) => state.chats.callApi);

  useEffect(() => {
    socket.on("newMessage", (data) => {
      if((data.user.id != JSON.parse(localStorage.getItem("userId")))){
        dispatch(saveChatUser({
          user: data.user,
          openChat: true
        }))
      }
    });
}, []);

  return (
    <div>
         {chatUserData?.openChat && (
            <Chat
            callApi={callApi}
              vendor={chatUserData?.user}
              onChatClose={() =>
                dispatch(saveChatUser({ openChat: false, user: {} }))
              }
            />
          )}
      <NavbarComp />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
