import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import avatarImg from "../../../assets/Images/avatar.png";
import iconCart from "../../../assets/Images/cart.png";
import logo from "../../../assets/Images/Logo.png";
import { getMyInfo, saveLanguage } from "../../../Store/reducers/auth";
import { getCarts } from "../../../Store/reducers/carts";
import SideBar from "./../../HomePage/SideBar";
import InputSearch from "./../../inputSearchNavbar/InputSearch";
import { Navbar } from "./styledNav";
import { getAddresses } from "../../../Store/reducers/addresses";
import words from "../../../assets/words";
import CustomModal from "../../customModal/CustomModal";

const NavbarComp = () =>
{
  //redux
  const dispatch = useDispatch()
  const carts = useSelector(state => state.carts?.reduxCarts)
  const language = useSelector(state => state.auth.language)
  const auth = useSelector(state => state.auth)
  const user = auth.user
  const loader = auth.loading

  const [showModal, setShowModal] = useState(false);

  const [sidebar, setSideBar] = useState(false);

  const toggleSidebar = () =>
  {
    setSideBar(prevState => !sidebar);
  };

  useEffect(() =>
  {
    !user && dispatch(getMyInfo())
    user && dispatch(getCarts())
    // eslint-disable-next-line
  }, [])

  useEffect(() =>
  {
    user && dispatch(getCarts())
    user && dispatch(getAddresses())
  }, [user])

  const handleChangeLanguage = () =>
  {
    dispatch(saveLanguage({ language: language === "en" ? "ar" : "en" }))
  }

  return (
    <>
      {showModal && (
        <CustomModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title={""}
          size={"lg"}
        >
          <div className="serch_cont  d-flex justify-content-center align-items-center ">
            <InputSearch />
          </div>
        </CustomModal>
      )}
      <Navbar className="shadow-sm fixed-top" dir={language === "en" ? "ltr" : "rtl"}>
        <div
          className={
            sidebar
              ? `sideMenu d-flex justify-content-start px-4 pt-5 align-items-start shadow-sm open--sidemenu ${language === "ar" ? "sideMenu-ar" : null}`
              : `sideMenu ${language === "ar" ? "sideMenu-ar" : null}`
          }
        >
          <SideBar setSideBar={setSideBar} />
        </div>
        <span
          className={sidebar ? "backdrop_overlay backdrop_overlay--open" : "backdrop_overlay"}
          onClick={toggleSidebar}
        ></span>
        <div className="container">
          <div className="row cont__nav">
            <div className="col-sm-12 col-lg-3 d-flex justify-content-start align-items-center gap-5 logo__menu">
              <div className="header--icons d-flex align-items-center">
                <span className="menu rounded-circle" onClick={toggleSidebar}>
                  <RxHamburgerMenu />
                </span>

                <span className="menu d-sm-none rounded-circle" onClick={() => setShowModal(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="#fff"
                      strokeMiterlimit="10"
                      d="M21 3C11.602 3 4 10.602 4 20s7.602 17 17 17c3.355 0 6.46-.984 9.094-2.656l12.281 12.281 4.25-4.25L34.5 30.281C36.68 27.421 38 23.88 38 20c0-9.398-7.602-17-17-17zm0 4c7.2 0 13 5.8 13 13s-5.8 13-13 13S8 27.2 8 20 13.8 7 21 7z"
                      fontFamily="none"
                      fontSize="none"
                      fontWeight="none"
                      textAnchor="none"
                      transform="scale(5.12)"
                    ></path>
                  </svg>
                </span>
              </div>
              <Link to="/" className="logo">
                <img crossorigin="anonymous" src={logo} alt="logo" style={{ width: "7rem" }} />
              </Link>
            </div>
            <div className="col-12 col-lg-6 d-sm-block d-none serch_cont col d-flex justify-content-center align-items-center ">
            <InputSearch />
          </div>
            <div className="col-12 col-lg-3 L_cont col d-flex justify-content-end align-items-center gap-4">
              <button className="lang_nav" onClick={handleChangeLanguage} style={{
                borderRight: language === "en" && "1px solid #fff",
                borderLeft: language === "ar" && "1px solid #fff",
              }}>
                {language === "en" ? "العربية" : "English"}
              </button>
              <div className="login_user d-flex justify-content-between align-items-center gap-2">
                <span className="avatar">
                  {
                    loader ?
                      <Spinner animation='border' />
                      :
                      <img crossorigin="anonymous" src={user?.img ? user?.img : avatarImg} alt="avatar" width={30} height={30} style={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }} />
                  }
                </span>
                {
                  !loader &&
                    user ?
                    <Link to={user?.type == 'USER' ? "/profileUser" : "/dashboard/products"} className="nameUser" style={{ color: "#fff", textDecoration: "none" }}>
                      {user.firstname} <FaAngleDown />
                    </Link>
                    :
                    !loader &&
                    <Link to="/signin" className="nameUser" style={{ color: "#fff", textDecoration: "none" }}>
                      {words.SIGNIN[language]} <FaAngleDown />
                    </Link>
                }
              </div>
              {
                (user?.type === "USER" || !user) &&
                <Link to="/shopping-cart" className="cart_nav">
                  <span className="num_in_cart">{user ? carts?.length : 0}</span>
                  <span>
                    <img src={iconCart} alt="cart" crossorigin="anonymous" />
                  </span>
                </Link>
              }

            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default NavbarComp;
