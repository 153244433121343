import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreasByCountryId,
  getCitiesByCountryId,
  getCountries,
} from "../../../Store/reducers/countries";
import { Spinner } from "react-bootstrap";
import { addAddress, getAddresses, updateAddress } from "../../../Store/reducers/addresses";
import words from "../../../assets/words";

const AddAddressForm = ({onDone, edit, address}) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countries);
  const cities = useSelector((state) => state.countries.cities);
  const areas = useSelector((state) => state.countries.areas);
  const loader = useSelector((state) => state.addresses.loading);
  const language = useSelector((state) => state.auth.language);

  const [addressData, setAddressData] = useState( {
    country: edit ? address?.country?.id : "",
    city: edit ? address?.city?.id : "",
    area: edit ? address?.area?.id : "",
    address: edit ? address?.address : ""
  });
  const [notError, setNotError] = useState(true);

  const handleOnChange = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    dispatch(getCitiesByCountryId(addressData.country));
    dispatch(getAreasByCountryId(addressData.country));
  }, [addressData.country]);

  useEffect(() => {
    if (
      addressData.country &&
      addressData.city &&
      addressData.area &&
      addressData.address
    ) {
      setNotError(false);
    } else {
      setNotError(true);
    }
  }, [addressData]);

  const handleAddAddress = () => {
    if(edit) {
      dispatch(updateAddress({...addressData, id: address?.id})).then((res)=>{
        if(res?.meta?.requestStatus !== "rejected"){
          dispatch(getAddresses())
          onDone()
        }
    })
    }else{
      dispatch(addAddress(addressData)).then((res)=>{
        if(res?.meta?.requestStatus !== "rejected"){
          dispatch(getAddresses())
          onDone()
        }
    })
    }
  }

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      <div className="d-flex gap-2">
        <select
          id="inputState"
          className="form-select"
          name="country"
          style={{
            width: "100%",
          }}
          value={addressData.country}
          onChange={handleOnChange}
          placeholder={words.SELECT_COUNTRY[language]}
        >
          <option value="Select">{words.SELECT_COUNTRY[language]} *</option>
          {countries?.map((country, index) => {
            return (
              <option value={country?.id} key={index}>
                {country?.name}
              </option>
            );
          })}
        </select>
        <select
          id="inputState"
          className="form-select"
          name="city"
          style={{
            width: "100%",
          }}
          value={addressData.city}
          onChange={handleOnChange}
          placeholder="Select City"
        >
          <option value="Select">{words.SELECT_CITY[language]} *</option>
          {cities?.map((country, index) => {
            return (
              <option value={country?.id} key={index}>
                {country?.name}
              </option>
            );
          })}
        </select>
      </div>
      <select
        id="inputState"
        className="form-select mt-3"
        name="area"
        style={{
          width: "100%",
        }}
        value={addressData.area}
        onChange={handleOnChange}
        placeholder="Select Area"
      >
        <option value="Select">{words.SELECT_AREA[language]} *</option>
        {areas?.map((country, index) => {
          return (
            <option value={country?.id} key={index}>
              {country?.name}
            </option>
          );
        })}
      </select>
      <input
        className="form-control mt-3"
        name="address"
        placeholder={`${words.ADDRESS[language]} *`}
        onChange={handleOnChange}
        value={addressData.address}
      />

      <div className="text-center">
        <button
          className="btn text-white w-50 mt-4"
          onClick={handleAddAddress}
          style={{
            backgroundColor: "RGBA(74, 62, 76, 0.8)",
          }}
          disabled={notError}
        >
          {loader ? <Spinner animation="border" /> : edit ? words.EDIT_ADDRESS[language] : words.ADD_NEW_ADDRESS[language]}
        </button>
      </div>
    </div>
  );
};

export default AddAddressForm;
