import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getChats = createAsyncThunk(
  "chats/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/messages?friendId=${data?.vendor}&userId=${data?.user}&page=${data?.page}`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const chatsSlice = createSlice({
  name: "chats",
  initialState: {
    chats: null,
    chatUser: {
      user: null,
      openChat: false,
    },
    loading: false,
    callApi: false,
  },
  reducers: {
    clearChats: (state) => {
        state.chats = []
    },
    saveChatUser: (state, action) => {
      state.chatUser.openChat = action.payload.openChat;
      state.chatUser.user = action.payload.user;
    },
    saveCallApi: (state, action) => {
      state.callApi = action.payload;
    }
  },
  extraReducers: {
    //getChats
    [getChats.pending]: (state, action) => {
      state.loading = true;
    },
    [getChats.fulfilled]: (state, action) => {
      state.loading = false;
      state.chats = action.payload.data;
    },
    [getChats.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {clearChats, saveChatUser, saveCallApi} = chatsSlice.actions;

export default chatsSlice.reducer;
