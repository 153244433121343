import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";

export const GoogleLogin = ({ children, setResponse }) => {
  const onResponse = (resp) => {
    setResponse(resp);
  };

  const REDIRECT_URI = "https://cottage-ksa.com";
  return (
    <LoginSocialGoogle
      client_id={
        "870992014595-dspah10ukatedahlmujqncogpf8ss440.apps.googleusercontent.com"
      }
      onLoginStart={onResponse}
      redirect_uri={REDIRECT_URI}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      access_type="offline"
      onResolve={({ provider, data }) => {
        setResponse(data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      {children}
    </LoginSocialGoogle>
  );
};

export const FacebookLogin = ({ children, setResponse }) => {
  const onResponse = (resp) => {
    console.log(resp);
  };
  const REDIRECT_URI = "https://cottage-ksa.com";
  return (
    <LoginSocialFacebook
      appId={"264837425955688"}
      fieldsProfile={
        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
      }
      onLoginStart={onResponse}
      // onLogoutSuccess={onLogoutSuccess}
      redirect_uri={REDIRECT_URI}
      onResolve={({ provider, data }) => {
        setResponse(data);
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      {children}
    </LoginSocialFacebook>
  );
};
