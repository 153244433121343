import Modal from "react-bootstrap/Modal";

const CustomModal = ({
  show,
  onHide,
  title,
  children,
  size,
  closeButtonShow,
  className
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size={size ? size : "md"}
      backdrop={"static"}
      className={className}
    >
      <Modal.Header closeButton={closeButtonShow === false ? false : true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
