import React, {useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import Pagination from "../../Components/Pagination/Pagination";
import {getBrands} from "../../Store/reducers/brands";
import {getCategories, getCategoryById, getSubCategories} from "../../Store/reducers/categories";
import {getProducts} from "../../Store/reducers/products";
import {filterPath} from "../../utils/formatWords";
import Breadcrmb from "./../../Components/breadCrumb/Breadcrumb";
import ProdctCrd from "./../../Components/ReusableComponent/CardProduct/CardProduct";
import SideFiltring from "./../../Components/SideFiltering/SideFiltring";
import {ProductsStyled} from "./StylePageProducts";
import words from "../../assets/words";

const ProductsPage = ({page}) => {
   //navigate
   const navigate = useNavigate();
   //routes
   const {filter} = useParams();
   const vendorId = useParams()?.id;
   //states
   const [filterObj, setFilterObj] = useState({});
   const [pageNumber, setPageNumber] = useState(1);

   //redux
   const dispatch = useDispatch();
   const category = useSelector((state) => state.categories.category?.data);
   const subCategories = useSelector((state) => state.categories.subCategories?.data);
   const language = useSelector((state) => state.auth.language);
   // const products = useSelector((state) => state.products.products?.data);
   const [products, setProducts] = useState(null);
   const count = useSelector((state) => state.products.products?.totalCount);
   const user = useSelector((state) => state.auth.user);

   const getProductsByFilter = (filterObject, page, id) => {
      //only for price
      const priceFiltered = filterObject?.price?.split(",");

      dispatch(getCategoryById(filterObject?.cat));
      dispatch(getSubCategories(filterObject?.cat));
      dispatch(
         getProducts({
            category: filterObject?.cat,
            subCategory: filterObject?.sub_cat,
            brand: filterObject?.brand,
            search: filterObject?.search,
            rate: filterObject?.rate,
            priceFrom: priceFiltered?.[0], //min price
            priceTo: priceFiltered?.[1], //max price
            sortByPrice: filterObject?.sort,
            page: page,
            owner: id ? id : "",
            userId: user?.id || "",
         })
      ).then((res) => {
         setProducts(res?.payload?.data);
         setFilterObj(filterObject);
      });
   };

   useEffect(() => {
      dispatch(getCategories());
      dispatch(getBrands());
   }, []);

   useEffect(() => {
      if (filter) {
         const filterArr = filter.split("&"); //cat=4
         let copyObj = {};
         filterArr?.map((x) => {
            let filterText = x.split("="); // cat 4
            copyObj = {...copyObj, [filterText[0]]: filterText[1]};
         });
         getProductsByFilter(copyObj, pageNumber, vendorId);
      }
      // eslint-disable-next-line
   }, [filter, pageNumber, vendorId, user]);

   return (
      <>
         <ProductsStyled dir={language === "en" ? "ltr" : "rtl"}>
            {page !== "vendor" && (
               <Breadcrmb>
                  <ol className="breadcrumb mb-0">
                     <li className="breadcrumb-item">
                        <Link to="/">{words.HOME[language]}</Link>
                     </li>
                     <li className="breadcrumb-item">
                        <Link to={`/products-page/cat=${category?.id}`}>{category?.name}</Link>
                     </li>
                     {/* <li className="breadcrumb-item active" aria-current="page">
            Men's wear
          </li> */}
                  </ol>
               </Breadcrmb>
            )}

            <div className="container pt-3">
               <div className="row">
                  <div className="col-sm-12 col-lg-3 mb-4">
                     <SideFiltring filterObj={filterObj} />
                  </div>
                  <div className="col-sm-12 col-lg-9 mb-3">
                     {filterObj?.cat && (
                        <div className="header_filter d-flex justify-content-between align-items-center">
                           {subCategories?.length !== 0 && filterObj?.cat && (
                              <span className="fw-bold">{words.SUB_CATEGORIES[language]}</span>
                           )}
                           <div className="filter_design d-flex align-items-start">
                              <select
                                 class="form-select mx-2"
                                 defaultValue={filterObj?.sort}
                                 onChange={(e) => {
                                    navigate(`${filterPath(filterObj, "sort", vendorId)}sort=${e.target.value}`);
                                 }}
                              >
                                 <option value="">{words.SORT_BY_PRICE[language]}</option>
                                 <option value="down">{words.HIGHEST_PRICE[language]}</option>
                                 <option value="up">{words.LOWEST_PRICE[language]}</option>
                              </select>
                           </div>
                        </div>
                     )}

                     {/* list subCategories */}
                     {subCategories?.length !== 0 && filterObj?.cat && (
                        <div className="ctegories_products mt-4">
                           <div className="row">
                              {subCategories?.map((sub, index) => {
                                 return (
                                    <>
                                       <div
                                          className="col-sm-2 col-4 d-flex align-items-center flex-column mb-4"
                                          key={index}
                                          style={{
                                             cursor: "pointer",
                                          }}
                                          onClick={() => {
                                             navigate(
                                                `${filterPath(filterObj, "sub_cat", vendorId)}sub_cat=${sub?.id}`
                                             );
                                          }}
                                       >
                                          <div
                                             className="img_categ rounded-circle d-flex justify-content-center align-items-center mb-2"
                                             style={{
                                                backgroundColor: "#CED7E6",
                                             }}
                                          >
                                             <img
                                                crossorigin="anonymous"
                                                src={sub?.img}
                                                style={{
                                                   borderRadius: "50%",
                                                   border: sub?.id == filterObj?.sub_cat && "3px solid #c57ed1",
                                                }}
                                                width={"100%"}
                                                height={"100%"}
                                                alt="category"
                                             />
                                          </div>
                                          <p className="title_cteg text-center">{sub?.name}</p>
                                       </div>
                                    </>
                                 );
                              })}
                           </div>
                        </div>
                     )}

                     {/* list Products */}
                     <div className={`${!filterObj?.cat && "p-0"} row Products_in`}>
                        <p className="m-2 fw-bold mb-4 mx-0">{products?.length} {words.PRODUCTS_FOUND[language]}</p>

                        {products?.map((product, index) => {
                           return (
                              <div
                                 className="col-sm-12 col-md-6 col-lg-4"
                                 key={index}
                              >
                                 <ProdctCrd
                                    item={product}
                                    addToFav={user?.type === "USER" || !user ? true : false}
                                 />
                              </div>
                           );
                        })}
                        {count > 10 && (
                           <Pagination
                              count={count}
                              setPageNumber={setPageNumber}
                           />
                        )}
                        {products?.length == 0 && (
                           <div
                              style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 height: 300,
                              }}
                           >
                              <p>{words.NO_DATA_FOUND_FOR_DISPLAY[language]}</p>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </ProductsStyled>
         {/* )} */}
      </>
   );
};

export default ProductsPage;
