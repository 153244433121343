import React from "react";
import "./payment.scss";
import { useLocation } from "react-router-dom";

const Payment = () => {
   const paymentRedirectUrl = useLocation()?.state?.url;

   return (
      <div className="payment">
         <iframe src={`${paymentRedirectUrl}`}></iframe>
      </div>
   );
};

export default Payment;
