import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import SignIn from "./pages/auth/signin/SignIn";
import SignUp from "./pages/auth/signup/SignUp";
import ProductDetails from "./pages/CartDetails/CartDetails";
import Purchase from "./pages/Checkout/PurchaseSuccess/Purchase";
import Review from "./pages/Checkout/ReviewOrder/Review";
import ShipingPayment from "./pages/Checkout/ShipingPage/ShipingPayment";
import Guest from "./pages/GuestCheckout/Guest";
import Layout from "./pages/MainLayoutRoutes/Layout";
import NewOrders from "./pages/PagesUser/NewOrders/NewOrders";
import WishList from "./pages/PagesUser/wishlist/WishList";
import ProductsPage from "./pages/Products/ProductsPage";
import ProfileUser from "./pages/profileUser/ProfileUser";
import Vendor from "./pages/sellerProfile/Vendor";
import ShoppingCart from "./pages/shoppingCart/ShoppingCart";
import TrackOrder from "./pages/TrackOrder/TrackOrder";
import UserLayout from "./pages/UserLayoutRoutes/UserLayout";
import store from "./Store/Store";
import {authLoader, mainLoader} from "./utils/auth";
import UpdateProfile from "./pages/PagesUser/updateProfile/UpdateProfile";
import Products from "./pages/Dashboard/products/ProductsDash";
import DashBoardLayout from "./pages/MainLayoutRoutes/DashBoardLayout";
import VendorsDash from "./pages/Dashboard/vendors/Vendors";
import OrdersDash from "./pages/Dashboard/orders/Orders";
import DriversDash from "./pages/Dashboard/drivers/Drivers";
import DriversReplies from "./pages/Dashboard/driversReplies/DriversReplies";
import AddProduct from "./pages/Dashboard/products/AddProduct";
import VendorProfile from "./pages/Dashboard/profile/VendorProfile";
import VerifyAccount from "./pages/auth/verfiyAccount/VerfiyAccount";
import About from "./pages/S_Aboutus/About";
import Terms from "./pages/S_TermsAndCondition/Terms";
import ContactUs from "./pages/S_Contactus/ContactUs";
import Payment from "./pages/PaymentScreen/Payment";
import ChatContacts from "./pages/Dashboard/chatContacts/ChatContacts";

function App() {
   const MainRoutes = createBrowserRouter([
      // Routes Of main page
      {
         path: "/",
         element: <Layout />,
         children: [
            {
               path: "/",
               index: true,
               element: <HomePage />,
            },
            {
               path: "/shipping-payment",
               loader: mainLoader,
               element: <Review />,
            },
            {
               path: "/payment",
               loader: mainLoader,
               element: <Payment />,
            },
            {
               path: "/purchase-success/:id",
               loader: mainLoader,
               element: <Purchase />,
            },
            {
               path: "/review",
               loader: mainLoader,
               element: <ShipingPayment />,
            },
            {
               path: "/track-order/:id",
               element: <TrackOrder />,
            },
            {
               path: "/product-details/:id/:slug?",
               element: <ProductDetails />,
            },
            {
               path: "/shopping-cart",
               loader: mainLoader,
               element: <ShoppingCart />,
            },
            {
               path: "/vendor/:id/:filter",
               element: <Vendor />,
            },
            {
               path: "/guest-checkout",
               element: <Guest />,
            },
            {
               path: "/products-page/:filter",
               element: <ProductsPage />,
            },
            {
               path: "/About-us",
               element: <About />,
            },
            {
               path: "/Terms-and-Condition",
               element: <Terms />,
            },
            {
               path: "/Contact-us",
               element: <ContactUs />,
            },
         ],
      },
      // Routes Of pages UserProfile
      {
         path: "/",
         loader: mainLoader,
         element: <UserLayout />,
         children: [
            {
               path: "/profileUser",
               index: true,
               element: <ProfileUser />,
            },
            {
               path: "/wishlist",
               element: <WishList />,
            },
            {
               path: "/all-orders/:type",
               element: <NewOrders />,
            },
            {
               path: "/update-profile",
               element: <UpdateProfile />,
            },
         ],
      },
      // Auth routes
      {
         path: "/signin",
         loader: authLoader,
         element: <SignIn />,
      },
      {
         path: "/verify-account/:id",
         loader: authLoader,
         element: <VerifyAccount />,
      },
      {
         path: "/verify-account/:id/:country/:forgot",
         loader: authLoader,
         element: <VerifyAccount />,
      },

      {
         path: "/signup/:type",
         loader: authLoader,
         element: <SignUp />,
      },
      //dashboard-routes
      {
         path: "/dashboard",
         loader: mainLoader,
         element: <DashBoardLayout />,
         children: [
            {
               path: "products",
               index: true,
               element: <Products />,
            },
            {
               path: "add-product",
               element: <AddProduct />,
            },
            {
               path: "add-product/:id",
               element: <AddProduct />,
            },
            {
               path: "vendors",
               element: <VendorsDash />,
            },
            {
               path: "chat-contacts",
               element: <ChatContacts />,
            },
            {
               path: "orders/:type",
               element: <OrdersDash />,
            },
            {
               path: "drivers",
               element: <DriversDash />,
            },
            {
               path: "drivers-replies",
               element: <OrdersDash />,
            },
            {
               path: "drivers-replies/:id",
               element: <DriversReplies />,
            },
            {
               path: "profile",
               element: <VendorProfile />,
            },
         ],
      },
   ]);
   return (
      <>
         <Provider store={store}>
            <RouterProvider router={MainRoutes} />
         </Provider>
      </>
   );
}

export default App;
