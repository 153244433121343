import React, { useEffect } from "react";
import CoverImg from "../../assets/Images/cover_photo.webp";
import { StyleSeller } from "./Style";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../Store/reducers/users";
import ProductsPage from "../Products/ProductsPage";
import { BeatLoader } from "react-spinners";
import words from "../../assets/words";
const Vendor = () => {
  const { id, filter } = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.users.user?.data);
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    dispatch(getUserById(id));
  }, [id]);

  useEffect(() => {
    if(userData){
      filter !== "cat=" &&
      document
        .getElementById("filter_products")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [filter]);

  return (
    <StyleSeller dir={language === "en" ? "ltr" : "rtl"}>
      {!userData ? (
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            width: "100%",
            height: "calc(95vh - 46px)",
          }}
        >
          <BeatLoader color="rgb(1, 73, 124)" />
        </div>
      ) : (
        <div className="header_page">
        <img
         crossorigin="anonymous"
          src={userData?.cover || CoverImg}
          alt="cover"
          className="w-100 rounded-bottom shadow-sm cover_img"
          style={{ height: "400px", objectFit: "cover" }}
        />
        <div className="container">
          <div className="seller_info d-flex flex-column align-items-start justify-content-center">
            <img
             crossorigin="anonymous"
              src={userData?.img}
              alt="profile"
              className="img-thumbnail shadow-lg rounded-circle"
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
            />
            <div className="data_seller pt-2">
              <h4 className="fw-bold">
                {userData?.firstname} {userData?.lastname}
              </h4>
              {/* <p className="fs-6">Info about seller Info about seller</p> */}
              <p className="fs-6">{words.EMAIL[language]}: {userData?.email}</p>
              <p className="fs-6">{words.PHONE[language]}: {userData?.phone}</p>
            </div>
          </div>
          <div id='filter_products' style={{paddingTop: "60px"}}>
          <ProductsPage page={"vendor"} />
          </div>
          
        </div>
      </div>
      )}
    </StyleSeller>
  );
};

export default Vendor;
