import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import addProd from "../../assets/Images/add_prod.svg";
import IconAsk from "../../assets/Images/conversation.png";
import IconCart from "../../assets/Images/Icon Color.png";
import login from "../../assets/Images/login.jpg";
import minaas from "../../assets/Images/minas.svg";
import SimilarProduct from "../../Components/Cart/SimilarProduct/SimilarProduct";
import StarsRate from "../../Components/StarsRate/StarsRate";
import { getProduct, getReviewsProduct } from "../../Store/reducers/products";
import { createFavorite, deleteFavorite } from "../../Store/reducers/favorites";
import Breadcrmb from "./../../Components/breadCrumb/Breadcrumb";
import GalleryProduct from "./../../Components/Cart/productGallery/ProductGallery";
import { OverView, ProdDetails } from "./StyleDetails";
import BeatLoader from "react-spinners/BeatLoader";
import {
  addToReduxCart,
  decreaseCart,
} from "../../Store/reducers/carts";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { checkToken } from "../../utils/auth";
import CustomModal from "../../Components/customModal/CustomModal";
import Chat from "../Dashboard/chat/Chat";
import { saveChatUser, saveCallApi } from "../../Store/reducers/chats";
import { Spinner } from "react-bootstrap";
import words from "../../assets/words";


const ProductDetails = () => {
  const [toggleTab, setToggleRab] = useState(1);
  const toggleTabs = (index) => {
    setToggleRab(index);
  };

  //route
  const { id } = useParams();
  //navigate
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products.product?.data);
  const language = useSelector((state) => state.auth.language);
  const productLoading = useSelector((state) => state.products.productLoading);
  const favLoading = useSelector((state) => state.favorites.loading);
  const carts = useSelector((state) => state.carts.reduxCarts);
  const userId = useSelector((state) => state.auth.userId);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const reviewsProducts = useSelector(
    (state) => state.products.reviewsProducts?.data
  );

  const [currentStock, setCarrentStocck] = useState({});
  const [images, setImages] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [fav, setFav] = useState(product?.isFavourite);
  const [favCount, setFavCount] = useState(product?.favCount);

  useEffect(() => {
    dispatch(
      getProduct({
        id: id,
        userId: userId,
      })
    );
    dispatch(getReviewsProduct(id));
    //update quantity
    // eslint-disable-next-line
  }, [id, userId]);

  useEffect(() => {
    if (product?.files?.length > 0) {
      setCarrentStocck({
        price: product?.stock?.[0]?.price,
        quantity: product?.stock?.[0]?.quantity,
        // color: product?.stock?.[0]?.colors?.[0]?.color,
        // colors: product?.stock?.[0]?.colors,
        size: product?.stock?.[0]?.size,
      });
    } else {
      setCarrentStocck({
        price: product?.stock?.[0]?.colors?.[0].price,
        quantity: product?.stock?.[0]?.colors?.[0].quantity,
        color: product?.stock?.[0]?.colors?.[0]?.color,
        colors: product?.stock?.[0]?.colors,
        size: product?.stock?.[0]?.size,
      });
    }
    setFav(product?.isFavourite);
    setFavCount(product?.favCount);
  }, [product]);

  useEffect(() => {
    if (product?.files?.length > 0) {
      setImages(product?.files);
    } else {
      setImages(
        product?.images?.find((x) => x.color.id === currentStock?.color?.id)
      );
    }
    if (token) {
      let foundItem = null;
      if (product?.files?.length > 0) {
        foundItem = carts?.find(
          (item) =>
            item?.id == id &&
            item?.size?.id == currentStock?.size?.id
        );
      } else {
        foundItem = carts?.find(
          (item) =>
            item?.id == id &&
            item?.color?.id == currentStock?.color?.id &&
            item?.size?.id == currentStock.size.id
        );
      }
      foundItem ? setQuantity(foundItem?.quantity) : setQuantity(0);
    }
    // eslint-disable-next-line
  }, [currentStock, carts]);

  const handleChangeColor = (color) => {
    const stockFiltered = product?.stock
      ?.find((one) => one.size.id === currentStock?.size?.id)
      ?.colors?.find((two) => two.color?.id === color?.id);
    setCarrentStocck((prev) => {
      return {
        ...prev,
        price: stockFiltered?.price,
        quantity: stockFiltered?.quantity,
        color: stockFiltered?.color,
      };
    });
    setImages(product?.images?.find((x) => x.color.id === color?.id));
  };

  const handleChangeSize = (size) => {
    const stockFiltered = product?.stock?.find((one) => one.size.id == size);
    setCarrentStocck({
      price: stockFiltered?.price,
      quantity: stockFiltered?.quantity,
      color: stockFiltered?.colors?.[0]?.color,
      size: stockFiltered?.size,
      colors: stockFiltered?.colors,
    });
  };

  const handleAddToFav = () => {
    if (checkToken(token)) {
      setFav(true);
      setFavCount((prev) => {
        return (prev += 1);
      });
      dispatch(createFavorite(id));
    } else {
      setShowModal(true);
    }
  };

  const handleRemoveFromFav = (e) => {
    if (checkToken(token)) {
      setFav(false);
      setFavCount((prev) => {
        return (prev -= 1);
      });
      dispatch(deleteFavorite(id));
    } else {
      setShowModal(true);
    }
  };

  const addToCart = () => {
    if (checkToken(token)) {
      if (product?.files?.length > 0) {
        dispatch(
          addToReduxCart({
            id: product?.id,
            vendor: product?.owner?.id,
            name: product?.name,
            files: product?.files?.[0],
            price: currentStock.price,
            stockQuantity: currentStock?.quantity,
            size: currentStock?.size,
          })
        );
      } else {
        dispatch(
          addToReduxCart({
            id: product?.id,
            vendor: product?.owner?.id,
            name: product?.name,
            image: product.files?.length > 0 ? product?.files?.[0] : images?.images[0],
            price: currentStock.price,
            stockQuantity: currentStock?.quantity,
            size: currentStock?.size,
            color: currentStock?.color,
          })
        );
      }
    } else {
      setShowModal(true);
    }
  };

  const decreaseFromCart = () => {
    if (checkToken(token)) {
      if (product?.files?.length > 0) {
        dispatch(
          decreaseCart({
            id: id,
            size: currentStock?.size,
            files: product.files
          })
        );
      } else {
        dispatch(
          decreaseCart({
            id: id,
            size: currentStock?.size,
            color: currentStock?.color,
          })
        );
      }

    } else {
      setShowModal(true);
    }
  };

  const handleAskSeller = () => {
    if (checkToken(token)) {
      dispatch(saveChatUser({ openChat: true, user: product?.owner }));
      dispatch(saveCallApi(true))
    } else {
      setShowModal(true);
    }
  }

  return (
    <>
      {productLoading ? (
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            width: "100%",
            height: "calc(95vh - 46px)",
          }}
        >
          <BeatLoader color="rgb(1, 73, 124)" />
        </div>
      ) : (
        <>
          <Breadcrmb>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">{words.HOME[language]}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/products-page/cat=${product?.category?.id}`}>{product?.category?.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <p style={{whiteSpace: "nowrap"}}>{product?.name}</p>
              </li>
            </ol>
          </Breadcrmb>
          <ProdDetails className="container pt-5" dir={language === "en" ? "ltr" : "rtl"}>
            {showModal && (
              <CustomModal
                show={showModal}
                onHide={() => {
                  setShowModal(false);
                }}
                title={words.YOU_aRE_NOT_LOGGED_IN[language]}
              >
                <div className="text-center">
                  <img
                    crossorigin="anonymous"
                    src={login}
                    alt="login pic"
                    style={{
                      width: "50%",
                    }}
                  />
                  <p>{words.YOU_MUST_SIGN_IN_FIRST[language]}</p>
                  <button
                    className="btn text-white w-50 mt-4"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/signin");
                    }}
                    style={{
                      backgroundColor: "RGBA(74, 62, 76, 0.8)",
                    }}
                  >
                    {words.LOGIN_IN[language]}
                  </button>
                </div>
              </CustomModal>
            )}
            <div className="row">
              <div className="col-sm-12 col-lg-6 mb-4">
                <GalleryProduct
                  images={product?.files?.length > 0 ? product?.files : images?.images}
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="img_brand d-flex gap-2 justify-content-start mb-2">
                  <img
                    src={product?.brand?.img}
                    alt="imageBrand"
                    width={"100%"}
                    height={"100%"}
                    crossorigin="anonymous"
                  />
                </div>
                <Link
                  to={`/vendor/${product?.owner?.id}/cat=`}
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontWeight: "700",
                  }}
                >
                  {product?.owner?.firstname} {product?.owner?.lastname}
                </Link>

                <div className="title_Brand w-75 fw-normal pb-3">
                  <p className="">{product?.name} </p>
                </div>

                <div className="rate_brand pb-4 d-inline d-flex justify-content-start align-items-center">
                  <div className="stars_rate">
                    {product?.rate >= 0 && (
                      <StarsRate num={Math.ceil(product?.rate)} size={20} />
                    )}
                  </div>
                  <span className="num_rate p-0 m-0">
                    {product?.rateNumbers}
                  </span>
                  <span className="mx-3 point"></span>
                  <span
                    className="inStoke fw-medium"
                    style={{ color: currentStock?.quantity <= 0 && "red" }}
                  >
                    {currentStock?.quantity > 0 ? words.IN_STOCK[language] : words.OUT_OF_STOCK[language]}
                  </span>
                </div>

                <div className="price pb-3">
                  <span className="price_num">{currentStock?.price} {words.SR[language]}</span>
                  <span className="per_itm px-5">/{words.PER_ITEM[language]}</span>
                </div>

                <p className="description w-100 pb-5">
                  {product?.description}
                </p>

                <ul className="info_product pb-3">
                  {/* <li className="list mb-3">
                <span className="title_info">Type</span>
                <span className="Result_info">Regular</span>
              </li> */}
                  {product?.files?.length == 0 && (
                    <li className="list mb-3">
                      <span className="title_info">{words.COLOR[language]}</span>
                      <span className="Result_info">
                        {currentStock?.color?.name}
                      </span>
                    </li>
                  )}

                  <li className="list mb-3">
                    <span className="title_info">{words.SIZE[language]}</span>
                    <span className="Result_info">
                      {currentStock?.size?.name}
                    </span>
                  </li>
                  <li className="list mb-3">
                    <span className="title_info">{words.BRAND[language]}</span>
                    <span className="Result_info">{product?.brand?.name}</span>
                  </li>
                </ul>

                {product?.files?.length == 0 && (
                  <>
                    <hr className="w-100" />
                    <div className="list_colors w-100">
                      <h3 className="tit_color fs-6">{words.COLORS[language]}</h3>
                      <ul className="list_ d-flex justify-content-start align-items-center gap-2">
                        {currentStock?.colors?.map((color, index) => {
                          return (
                            <li
                              className="item_color"
                              key={index}
                              onClick={() => handleChangeColor(color?.color)}
                              style={{
                                border:
                                  color?.color?.id ===
                                  currentStock?.color?.id &&
                                  "3px solid #c57ed1",
                                backgroundColor: "green",
                              }}
                            >
                              <img
                                src={color?.color?.img}
                                alt="item pic color"
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  borderRadius: "50%",
                                }}
                                crossorigin="anonymous"
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}

                <hr className="w-100" />

                <div className="count_size d-flex justify-content-start align-items-center py-2 gap-3">
                  <div className="size_">
                    <label htmlFor="cars" className="fs-6">
                      {words.SIZE[language]}
                    </label>
                    <select
                      name="cars"
                      id="cars"
                      className="w-100 size_btn"
                      onChange={(e) => handleChangeSize(e.target.value)}
                    >
                      {product?.sizes?.map((size, index) => {
                        return (
                          <option value={size?.id} key={index}>
                            {size?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div className="Quantity_">
                    <label htmlFor="Quantity" className="fs-6">
                      Quantity
                    </label>
                    <div className="inp_qty w-100 d-flex justify-content-between align-items-center bg-white fs-4">
                      <button
                        className="btn"
                        onClick={() => {
                          if (quantity !== 0) {
                            setQuantity((prev) => {
                              return (prev -= 1);
                            });
                          }
                        }}
                      >
                        <img src={minaas} alt="minas" />
                      </button>
                      <span className="c_o_u_n">{quantity}</span>
                      <button
                        className="btn"
                        onClick={() => {
                          if (quantity != currentStock?.quantity) {
                            setQuantity((prev) => {
                              return (prev += 1);
                            });
                          }
                        }}
                      >
                        <img src={addProd} alt="add" />
                      </button>
                    </div>
                  </div> */}
                </div>

                <hr className="w-100" />
                <div className="footer__details pb-5 pt-3 d-flex justify-content-start align-items-center flex-wrap gap-2">
                  <button className="btn seller_as" onClick={handleAskSeller}>
                    <img src={IconAsk} alt="iconAsk" crossorigin="anonymous" /> {words.ASK_THE_SELLER[language]}
                  </button>
                  {user?.type === "USER" && (
                    <>
                      {quantity == 0 || !userId ? (
                        <button
                          className="btn add_cart"
                          onClick={addToCart}
                          disabled={currentStock?.quantity > 0 ? false : true}
                        >
                          <img src={IconCart} alt="iconAsk" crossorigin="anonymous" /> {words.ADD_TO_CART[language]}
                        </button>
                      ) : (
                        <div className="">
                          <div
                            className="gap-3 inp_qty w-100 d-flex justify-content-between align-items-center fs-4"
                            style={{
                              border: "1px solid grey",
                              borderRadius: 10,
                            }}
                          >
                            <button className="btn" onClick={decreaseFromCart}>
                              <img src={minaas} alt="minas" crossorigin="anonymous" />
                            </button>
                            <span className="c_o_u_n">{quantity}</span>
                            <button className="btn" onClick={addToCart}>
                              <img src={addProd} alt="add" crossorigin="anonymous" />
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="fav_io btn">
                        {
                          favLoading ?
                          <BeatLoader color="rgb(1, 73, 124)" />
                            :
                            <>
                              {fav ? (
                                <AiFillHeart
                                  color={"red"}
                                  onClick={(e) => handleRemoveFromFav(e)}
                                />
                              ) : (
                                <AiOutlineHeart onClick={(e) => handleAddToFav(e)} />
                              )}
                              <span className="num">{favCount}</span>
                            </>
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ProdDetails>
          <OverView dir={language === "en" ? "ltr" : "rtl"}>
            <div className="container py-5">
              <div className="row d-flex">
                <div className="col-sm-12 col-lg-8 mb-4">
                  <div className="bg-white shadow-sm rounded-3">
                    <div className="tabs_ px-5 d-flex justify-content-start align-items-center gap-5 border-bottom">
                      <span
                        className={
                          toggleTab === 1 ? "tab_li active_tab" : "tab_li"
                        }
                        onClick={() => toggleTabs(1)}
                      >
                        {words.OVERVIEW[language]}
                      </span>
                      <span
                        className={
                          toggleTab === 2 ? "tab_li active_tab" : "tab_li"
                        }
                        onClick={() => toggleTabs(2)}
                      >
                        {words.SPACIFICATON[language]}
                      </span>
                      <span
                        className={
                          toggleTab === 3 ? "tab_li active_tab" : "tab_li"
                        }
                        onClick={() => toggleTabs(3)}
                      >
                        {words.REVIEWS[language]} <span>({reviewsProducts?.length})</span>
                      </span>
                    </div>
                    <div className="container_content p-4">
                      <div
                        className={
                          toggleTab === 1
                            ? "content_tabs active_content"
                            : "content_tabs"
                        }
                      >
                        <p>{product?.description}</p>
                      </div>
                      <div
                        className={
                          toggleTab === 2
                            ? "content_tabs active_content"
                            : "content_tabs"
                        }
                      >
                        {product?.properties?.map((property, i) => {
                          return (
                            <div>
                              <h3>- {property?.property}</h3>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className={
                          toggleTab === 3
                            ? "content_tabs active_content"
                            : "content_tabs"
                        }
                      >
                        <div>
                          {reviewsProducts?.length === 0 && (
                            <p className="text-center">{words.NO_REVIEWS_FOUND[language]}</p>
                          )}
                          {reviewsProducts?.map((review, index) => {
                            return (
                              <div
                                className="p-3 py-4 rounded-3 shadow-sm"
                                key={index}
                              >
                                <div className="d-flex gap-3">
                                  <img
                                    src={review?.user?.img}
                                    alt="user pic"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                    crossorigin="anonymous"
                                  />
                                  <div>
                                    <p>
                                      {review?.user?.firstname}{" "}
                                      {review?.iuser?.lastname}
                                    </p>
                                    <p>
                                      <StarsRate
                                        num={Math.ceil(review?.rate)}
                                        size={15}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <hr />
                                <p className="mt-3">{review?.comment}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4 mb-4">
                  <div className=" bg-white shadow-sm rounded-3 p-4">
                    <SimilarProduct product={product} />
                  </div>
                </div>
              </div>
            </div>
          </OverView>
        </>
      )}
    </>
  );
};

export default ProductDetails;
