import React from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { CardList } from "./CardbrandsStyled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const CardBrands = ({ user }) => {
  const navigate = useNavigate() 
  const language = useSelector(state => state.auth.language)

  return (
    <CardList className="shadow-sm mb-4 rounded-3"  onClick={()=>{
      navigate(`/vendor/${user.id}/cat=`)
    }}>
      <div className="image_card">
        <img src={user?.img} alt={user?.firstname + " " + user?.lastname} style={{
          width: "200px",
          height: "120px",
          objectFit: "contain"
        }} crossorigin="anonymous" />
      </div>
      <div className="text_box">
        {/* <span className="f_ch d-inline-block">Fashion</span> */}
        <h5 className="fw-bold mb-2">{user?.firstname}{" "}{user?.lastname}</h5>
        <div className="disc_arrw d-flex justify-content-between align-items-center">
          {/* <p className="m-0 offer_disc">Offers up 20%</p> */}
          <p className="m-0 "></p>
          {
            language === "en" ?
            <BiRightArrowAlt color="#0a2c4c" className="shadow-lg" />
            :
            <BiLeftArrowAlt color="#0a2c4c" className="shadow-lg" />
          }
        </div>
      </div>
    </CardList>
  );
};

export default CardBrands;
