import MenuDash from "../../Components/Dashboard/MenuLinks/Menu_Dash";
import classes from "./DashBoardLayout.module.scss";
import userImg from "../../assets/Images/user-placeholder.png";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getMyInfo } from "../../Store/reducers/auth";
import { getCategories } from "../../Store/reducers/categories";
import { getBrands } from "../../Store/reducers/brands";
import { getSizes } from "../../Store/reducers/sizes";
import { getColors } from "../../Store/reducers/colors";
import MobileMenu from "../../Components/Dashboard/MobileMenu/MobileMenu";
import NavbarComp from "../../Components/ReusableComponent/Navbar/NavbarComp";
import Chat from "../Dashboard/chat/Chat";
import { saveChatUser } from "../../Store/reducers/chats";
import { socket } from "../../socket/Socket";
socket.connect();

const DashBoardLayout = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const chatUserData = useSelector((state) => state.chats.chatUser);
  const language = useSelector((state) => state.auth.language);
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getBrands());
    dispatch(getSizes());
    dispatch(getColors());
  }, []);

  useEffect(() => {
      socket.on("newMessage", (data) => {
        if((data.user.id != JSON.parse(localStorage.getItem("userId")))){
          dispatch(saveChatUser({
            user: data.user,
            openChat: true
          }))
        }
      });
  }, []);

  return (
    <>
      <NavbarComp />
      <div className={`${classes.container} container-lg`} dir={language === "en" ? "ltr" : "rtl"}>
        <div className={classes.sideBar}>
          {chatUserData?.openChat && (
            <Chat
            openChat={chatUserData?.openChat}
              vendor={chatUserData?.user}
              onChatClose={() =>
                dispatch(saveChatUser({ openChat: false, user: {} }))
              }
            />
          )}

          <div className={classes.vendor}>
            <img
              src={user?.img ? user?.img : userImg}
              alt="user Pic"
              crossorigin="anonymous"
            />
            <div>
              <p className="name_seller fw-medium">
                {user?.firstname} {user?.lastname}
              </p>
              <p className="job_seller">{user?.type}</p>
            </div>
          </div>

          <MenuDash setCurrentPage={setCurrentPage} />
          <p className="copyRight fw-medium mt-4 pb-5">&#169; 2023 Cottage</p>
        </div>
        <div className={classes.modileMenu}>
          <MobileMenu />
        </div>
        <div className={classes.contentContainer}>
          <p className={classes.pageTitle}>{currentPage?.name}</p>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardLayout;
