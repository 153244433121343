import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getOrders = createAsyncThunk("orders/get", async (data, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/orders/?status=${data?.status ? data?.status : ""}`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});

export const getOrderById = createAsyncThunk("order/get/id", async (id, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/orders/${id}/getMainOrder`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});

export const getDriversOfferByOrderId = createAsyncThunk("offers/id/get", async (id, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/offers/${id}/get`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});
export const acceptDriversOfferByOrderId = createAsyncThunk("offers/id/accept", async (id, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/offers/${id}/accept`;
      const response = await axios.put(url, {}, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});

export const createOrder = createAsyncThunk("orders/post", async (data, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/orders/${localStorage?.getItem("userId")}/users`;
      const response = await axios.post(url, data, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});

export const changeOrderStatus = createAsyncThunk("order/changeStatus", async (data, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/orders/${data?.id}/${data?.status}`;
      const response = await axios.put(url, {}, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});

export const sendOrderRoDerivers = createAsyncThunk("order/sendToDrivers", async (data, { getState, rejectWithValue }) =>
{
   try
   {
      const url = `${BASE_URL}/orders/${data?.id}/sendToDrivers`;
      const response = await axios.put(url, {}, tokenConfig(getState));
      return response.data;
   } catch (error)
   {
      if (error.response && error.response.data)
      {
         return rejectWithValue(error.response.data);
      } else
      {
         return rejectWithValue(error.message);
      }
   }
});

const ordersSlice = createSlice({
   name: "orders",
   initialState: {
      orders: null,
      order: null,
      orderCreated: null,
      loading: false,
      cancelLoading: false,
      paymentRedirectUrl:
         "https://secure.paytabs.sa/payment/wr/5E8EE77582E497529DFB0FBE46064D90010A6BDDB80BE5BE144E0560",
   },
   reducers: {
      setRedirectUrl: (state, action) =>
      {
         state.paymentRedirectUrl = action.payload;
      },
   },
   extraReducers: {
      //get orders
      [getOrders.pending]: (state, action) =>
      {
         state.loading = true;
         state.orders = null;
      },
      [getOrders.fulfilled]: (state, action) =>
      {
         state.loading = false;
         state.orders = action.payload;
      },
      [getOrders.rejected]: (state, action) =>
      {
         state.loading = false;
      },

      //get order
      [getOrderById.pending]: (state, action) =>
      {
         state.loading = true;
         state.order = null;
      },
      [getOrderById.fulfilled]: (state, action) =>
      {
         state.loading = false;
         state.order = action.payload;
      },
      [getOrderById.rejected]: (state, action) =>
      {
         state.loading = false;
      },

      //cancel order
      [changeOrderStatus.pending]: (state, action) =>
      {
         state.cancelLoading = true;
      },
      [changeOrderStatus.fulfilled]: (state, action) =>
      {
         state.cancelLoading = false;
      },
      [changeOrderStatus.rejected]: (state, action) =>
      {
         state.cancelLoading = false;
      },

      //create orders
      [createOrder.pending]: (state, action) =>
      {
         state.loading = true;
      },
      [createOrder.fulfilled]: (state, action) =>
      {
         state.loading = false;
         state.orderCreated = action.payload;
      },
      [createOrder.rejected]: (state, action) =>
      {
         state.loading = false;
      },

      //payment redirect url

      [getDriversOfferByOrderId.pending]: (state, action) =>
      {
         state.loading = true;
      },
      [getDriversOfferByOrderId.fulfilled]: (state, action) =>
      {
         state.loading = false;
         state.drivers = action.payload;
      },
      [getDriversOfferByOrderId.rejected]: (state, action) =>
      {
         state.loading = false;
      },


   },
});

// export const {} = anoncementsSlice.actions;

export const { setRedirectUrl } = ordersSlice.actions;
export default ordersSlice.reducer;
