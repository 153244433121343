import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/Logo.png";
import logOutPic from "../../assets/Images/logout.png";
import { logOut } from "../../Store/reducers/auth";
import { SideMenu } from "./styledHome/SideMenuStyle";
import { removeReduxCart } from "../../Store/reducers/carts";
import words from "../../assets/words";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";

const SideBar = ({ setSideBar }) =>
{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const language = useSelector(state => state.auth.language)
  const user = useSelector(state => state.auth.user)

  const handleLogout = () =>
  {
    dispatch(logOut()).then((res) =>
    {
      dispatch(removeReduxCart())
    })
    navigate("/");
  }

  return (
    <SideMenu dir={language === "en" ? "ltr" : "rtl"}>
      <Link to="/" className="logo">
        <img src={Logo} alt="logo" className="img_logo" crossorigin="anonymous" />
      </Link>
      <div className="list__ shop_categories">
        <h3 className="fw-normal title_list">{words.SHOP_BY_CATEGORY[language]}</h3>
        <ul className="d-flex align-items-start flex-column" id="list-croll">
          {/*Get data categories*/}

          {categories?.data?.map((cat, index) =>
          {
            return (
              <li className="item_list" key={cat.id}>
                <Link to={`/products-page/cat=${cat?.id}`} onClick={() => setSideBar(prev => !prev)} >{language === "en" ? cat.name_en : cat.name_ar}</Link>
              </li>
            );
          })}
        </ul>
      </div>

      {/* <div className="list__ my_account">
        <h3 className="fw-normal title_list">My ACCOUNT</h3>
        <ul className="d-flex align-items-start flex-column">
          <li className="item_list">
            <Link to="/profileUser">Profile</Link>
          </li>
          <li className="item_list">
            <Link to="/neworders">My Orders</Link>
          </li>
          <li className="item_list">
            <Link to="/profileUser">My Addresses</Link>
          </li>
        </ul>
      </div> */}

      {/* <div className="list__ my_seller_account">
        <h3 className="fw-normal title_list">Your Seller Account</h3>
        <ul className="d-flex align-items-start flex-column">
          <li className="item_list">
            <Link to="/seller">Profile</Link>
          </li>
          <li className="item_list">
            <Link to="/neworders">My Orders</Link>
          </li>
          <li className="item_list">
            <Link to="/seller">My Products</Link>
          </li>
        </ul>
      </div> */}

      {
        user &&
        <button className="btn" onClick={() => navigate('/wishlist')}>
          <AiOutlineHeart size={30} />
          {words.MY_WISHLIST[language]}
        </button>
      }

      {
        user &&
        <button className="btn" onClick={handleLogout}>
          <img src={logOutPic} alt="logout" crossorigin="anonymous" />
          {words.LOG_OUT[language]}
        </button>
      }
    </SideMenu>
  );
};

export default SideBar;
