import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import smallLatest_1 from "../../../assets/Images/70hvv4od.png";
import smallLatest_2 from "../../../assets/Images/j4raq205.png";
import { getAnoncements } from "../../../Store/reducers/anoncements";

import { LatestStyle } from "./LatestItemsStyled";
import words from "../../../assets/words";
import { Link } from "react-router-dom";

const LatestItems = ({ }) =>
{
  const dispatch = useDispatch();
  const anoncements = useSelector(state => state.anoncements.anoncements)
  const language = useSelector(state => state.auth.language)

  useEffect(() =>
  {
    dispatch(getAnoncements())
  }, [])

  console.log(anoncements?.data, 'LLL')

  return (
    <LatestStyle dir={language === "en" ? "ltr" : "rtl"}>
      <div className="container">
        <h3 className="pb-5 title_ fw-normal">{words.LATEST_OFFERS[language]}</h3>
        <div className="row">
          {anoncements?.data &&
            <div className="big_img col-lg-8 col-sm-12">
              <a href={anoncements?.data[0].link} target="_blank">
              <img crossorigin="anonymous" src={anoncements?.data[0].img} alt="latest" style={{ height: "100%", width: "100%" }} />
              </a>
            </div>}
          <div className="small_img col-lg-4 ">
            {(anoncements?.data || []).slice(1, anoncements?.data.length).map(ele =>
            {
              return (
                <>
                <a href={ele.link} target="_blank">
                  <img src={ele.img} alt="latest" crossorigin="anonymous" />
                  </a>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </LatestStyle>
  );
};

export default LatestItems;
