import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {AiOutlineHeart, AiFillHeart} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {createFavorite, deleteFavorite, getFavorites} from "../../../Store/reducers/favorites";
import {checkToken} from "../../../utils/auth";
import CustomModal from "../../customModal/CustomModal";
import {CardProdStyle} from "./StyleCardProduct";
import login from "../../../assets/Images/login.jpg";
import words from "../../../assets/words";
import {nameToSlug} from "../../../utils/general"

const ProdctCrd = ({addToCard, addToFav, offerPaddge, newPaddge, item}) => {
   //navigate
   const navigate = useNavigate();
   //redux
   const dispatch = useDispatch();
   const token = useSelector((state) => state?.auth?.token);
   const language = useSelector((state) => state?.auth?.language);

   //states
   const [fav, setFav] = useState(item?.isFavourite);
   const [showModal, setShowModal] = useState(false);

   useEffect(() => {
      setFav(item?.isFavourite);
   }, [item]);

   const handleRemoveFromFav = (e) => {
      if (checkToken(token)) {
         e.preventDefault();
         setFav(false);
         dispatch(deleteFavorite(item?.id)).then((res) => {
            dispatch(getFavorites());
         });
      } else {
         setShowModal(true);
      }
   };

   const handleAddToFav = (e) => {
      e.preventDefault();
      if (checkToken(token)) {
         e.preventDefault();
         setFav(true);
         dispatch(createFavorite(item?.id)).then((res) => {
            dispatch(getFavorites());
         });
      } else {
         setShowModal(true);
      }
   };

   return (
      <Link to={`/product-details/${item?.id}/${nameToSlug(item.name)}`}>
         {showModal && (
            <CustomModal
               show={showModal}
               onHide={(e) => {
                  e.preventDefault();
                  setShowModal(false);
               }}
               title={words.YOU_aRE_NOT_LOGGED_IN[language]}
            >
               <div className="text-center">
                  <img
                     src={login}
                     crossorigin="anonymous"
                     alt="login pic"
                     style={{
                        width: "50%",
                     }}
                  />
                  <p>{words.YOU_MUST_SIGN_IN_FIRST[language]}</p>
                  <button
                     className="btn w-50 text-white mt-4"
                     onClick={(e) => {
                        e.preventDefault();
                        navigate("/signin");
                     }}
                     style={{
                        backgroundColor: "RGBA(74, 62, 76, 0.8)",
                     }}
                  >
                     {words.LOGIN_IN[language]}
                  </button>
               </div>
            </CustomModal>
         )}
         <CardProdStyle>
            <div
               className="card"
               style={{maxWidth: "18rem"}}
            >
               <img
                  // src={image?.[0]?.images?.[0]}
                  crossorigin="anonymous"
                  src={item?.files?.[0] ? item?.files?.[0] : item?.images?.[0]?.images?.[0]}
                  className="card-img-top p-5"
                  alt="CardProduct"
                  style={{
                     maxWidth: "300px",
                     height: 300,
                     margin: "0 auto",
                     display: "block",
                     objectFit: "contain",
                  }}
               />
               <div className="text_price pt-3 d-flex justify-content-between">
                  <div>
                     <p className="price fw-semibold">{item?.priceFrom} {words.SR[language]}</p>
                     <span className="descrp">{item?.name}</span>
                  </div>
                  {addToFav && (
                     <span className="iconFav">
                        {fav ? (
                           <AiFillHeart
                              color={"red"}
                              onClick={(e) => handleRemoveFromFav(e)}
                           />
                        ) : (
                           <AiOutlineHeart onClick={(e) => handleAddToFav(e)} />
                        )}
                     </span>
                  )}
               </div>
               {addToCard && (
                  <div className="foot_card d-flex justify-content-start align-items-center gap-2 mb-2">
                     <button className="btn">{words.ADD_TO_CART[language]}</button>
                  </div>
               )}
               {newPaddge && <span className="padg_new">{words.NEW[language]}</span>}
               {offerPaddge && <span className="offer_">{words.OFFER[language]}</span>}
            </div>
         </CardProdStyle>
      </Link>
   );
};

export default ProdctCrd;
