import { useEffect } from "react";
import { useState } from "react";
import { BsStarFill } from "react-icons/bs";

const StarsRate = ({ num, size, clicked, setClickedStar }) => {
  const [StarNumber, setStarNumber] = useState(num);
  const stars = [1, 2, 3, 4, 5];

  useEffect(()=>{
    setStarNumber(num)
  },[num])
  
  return (
    <>
      {stars?.map((star, index) => {
        return (
          <BsStarFill
          style={{
            cursor: clicked ? "pointer" : "auto"
          }}
            color={index < StarNumber ? "#ff9017" : "#ddd"}
            size={size ? size : 20}
            key={index}
            className="fill_st me-1"
            onClick={() => {
              if (clicked) {
                setStarNumber(index + 1);
                setClickedStar(index + 1);
              }
            }}
          />
        );
      })}
    </>
  );
};

export default StarsRate;
