import React, {useState} from "react";
import {Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {checkCoupon} from "../../Store/reducers/carts";
import ProductCart from "./../../Components/Cart/productCart/ProductCart";
import DataSummary from "./../../Components/Checkout/DataSummary/DataSummary";
import RecomendItems from "./../../Components/RecommendedItems/RecomendItems";
import {StyleShCart} from "./StyleShopCart";
import words from "../../assets/words";

const ShoppingCart = () => {
   const dispatch = useDispatch();
   const shoppingCart = useSelector((state) => state?.carts?.reduxCarts);
   const discount = useSelector((state) => state.carts?.discount);
   const loader = useSelector((state) => state.carts?.loading);
   const language = useSelector((state) => state.auth.language);
   const [coupon, setCoupon] = useState("");
   const [couponError, setCouponError] = useState(false);

   const checkValidCoupon = () => {
      dispatch(
         checkCoupon({
            couponNumber: coupon,
         })
      ).then((res) => {
         if (res?.payload?.success == false) {
            setCouponError(true);
         } else {
            setCouponError(false);
         }
      });
   };

   return (
      <>
         <StyleShCart dir={language === "en" ? "ltr" : "rtl"}>
            <div className="head_page mb-4">
               <div className="container">
                  <h4>{words.SHOPPING_CART[language]}</h4>
               </div>
            </div>
            <div className="container">
               <div className="row">
                  <div className="col-sm-12 col-lg-9 list_prods">
                     <div className="bg-white mb-5 rounded-3 p-4 def_shadow">
                        <ul className="list_products">
                           {shoppingCart?.length === 0 && (
                              <div className="text-center">
                                 <p>{words.YOUR_CART_IS_EMPTY[language]}</p>
                              </div>
                           )}
                           {shoppingCart?.map((cart, index) => {
                              return <ProductCart item={cart} />;
                           })}
                        </ul>

                        {/* <div className="free_deliv">
                  <h5>Free Delivery within 1-2 weeks</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </p>
                </div> */}
                     </div>
                  </div>

                  {/* side content */}
                  <div className="col-sm-12 col-lg-3">
                     <div className=" bg-white p-3 def_shadow rounded-3 mb-3 ">
                        <div className="coupon">
                           <label className="pb-1">{words.HAVE_A_COUPON[language]}</label>
                           <div className="input-group mb-3">
                              <input
                                 type="text"
                                 className="form-control"
                                 placeholder={words.TYPE_COUPOUN_CODE_HERE[language]}
                                 aria-label="Recipient's username"
                                 aria-describedby="button-addon2"
                                 value={coupon}
                                 onChange={(e) => {
                                    setCoupon(e.target.value);
                                 }}
                              />
                              <button
                                 className="btn"
                                 type="button"
                                 id="button-addon2"
                                 disabled={!coupon || loader}
                                 onClick={checkValidCoupon}
                              >
                                 {loader ? <Spinner animation="border" /> : words.APPLY[language]}
                              </button>
                           </div>

                           <p className={`${couponError ? "text-danger" : "text-success"}`}>{couponError ? "Invalid Coupon" : discount ? "Valid Coupon" : ""}</p>
                        </div>
                     </div>
                     <div className="bg-white def_shadow rounded-3">
                        <DataSummary
                           page="cart"
                           coupon={couponError}
                        />
                        <div className="p-3">
                           <Link
                              to="/shipping-payment"
                              className="checkOut-btn w-100"
                              style={{
                                 pointerEvents: shoppingCart.length == 0 ? "none" : "auto",
                              }}
                           >
                              <button className="btn w-100 fw-medium mb-2">{words.CHECKOUT[language]}</button>
                           </Link>
                           <Link
                              to="/"
                              className="route_to_shpop w-100 "
                           >
                              <button className="btn w-100 border fw-medium">{words.BACK_TO_SHOP[language]}</button>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="bg_section py-5 ">
               <div className="container">
                  <RecomendItems />
               </div>
            </div>
         </StyleShCart>
      </>
   );
};

export default ShoppingCart;
