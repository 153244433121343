import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

const token = localStorage.getItem("token")
	? localStorage.getItem("token")
	: null

export const postSignIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/signin/`;
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postSignUp = createAsyncThunk(
  "auth/signup",
  async (data, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/signup/`;
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const logOut = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/logout/`;
      const response = await axios.post(url,_, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMyInfo = createAsyncThunk(
  "auth/myinfo",
  async (_, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/getMyData`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateInfo = createAsyncThunk(
  "auth/updateInfo",
  async (data, { rejectWithValue, getState }) => {
    const formData = new FormData()
    formData.append("firstname", data.firstname)
    formData.append("lastname", data.lastname)
    formData.append("phone", data.phone)
    formData.append("email", data.email)
   data.image && formData.append("img", data.img)

    try {
      const url = `${BASE_URL}/user/${data.id}/updateInfo`;
      const response = await axios.put(url,formData,
        {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${getState().auth.token}`,
					},
				});
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (data, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/user/updatePassword/`;
      const response = await axios.put(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verfiyAccount = createAsyncThunk(
  "auth/verfiyAccount",
  async (data, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/confirmVerifyCode`;
      const response = await axios.post(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendCode = createAsyncThunk(
  "auth/sendCode",
  async (data, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/sendCode-phone`;
      const response = await axios.post(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/reset-password-phone`;
      const response = await axios.post(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const checkSocaiLogin = createAsyncThunk(
  "auth/checkSocaiLogin",
  async (data, { rejectWithValue, getState }) => {
    try {
      const url = `${BASE_URL}/socialLogin`;
      const response = await axios.post(url,data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);




const authSlice = createSlice({
  name: "products",
  initialState: {
    loading: false,
    language: localStorage.getItem("language") || "en",
    isAuth: false,
    user: null,
    userId: localStorage.getItem("userId"),
    token: token,
    error: "",
    updateLoading: false,
    updatePassword: false,
    socialLoading: false,
  },
  reducers: {
    saveLanguage: (state, action) => {
      if (state.language !== action.payload.language) {
          localStorage.setItem('language', action.payload.language);
          window.location.reload();
      }
  },
  },
  extraReducers: {
    //login
    [postSignIn.pending]: (state, action) => {
      state.loading = true;
    },
    [postSignIn.fulfilled]: (state, action) => {
      state.loading = false;
      if(action.payload.data.accountType == "ACTIVE"){
        if((action.payload.data.type === "VENDOR" && action.payload.data.verify) || action.payload.data.type === "USER"){
          localStorage.setItem("token", action.payload.token);
          localStorage.setItem("userId", action.payload.data.id)
          state.userId = action.payload.data.id
          state.token = action.payload.token;
          state.isAuth = true;
          state.user = action.payload.data;
        }
        
      }
     
    },
    [postSignIn.rejected]: (state, action) => {
      state.loading = false;
      localStorage.removeItem("token");
      state.token = null;
      state.isAuth = false;
      state.user = null;
      state.userId = null
    },

    //signup
    [postSignUp.pending]: (state, action) => {
      state.loading = true;
    },
    [postSignUp.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [postSignUp.rejected]: (state, action) => {
      state.loading = false;
    },

    //user data
    [getMyInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [getMyInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.data
      state.userId = action.payload.data.id
      localStorage.setItem("userId", action.payload.data.id)
    },
    [getMyInfo.rejected]: (state, action) => {
      state.loading = false;
      localStorage.removeItem("token");
      localStorage.removeItem("userId")
      state.token = null;
      state.isAuth = false;
      state.user = null;
      state.userId = null
    },

    //logout
    [logOut.pending]: (state, action) => {
      state.loading = true;
    },
    [logOut.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = null;
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      state.token = null
      state.userId = null
    },
    [logOut.rejected]: (state, action) => {
      state.loading = false;
    },


    //uodate user info
    [updateInfo.pending]: (state, action) => {
      state.updateLoading = true;
    },
    [updateInfo.fulfilled]: (state, action) => {
      state.updateLoading = false;
    },
    [updateInfo.rejected]: (state, action) => {
      state.updateLoading = false;
    },

    //uodate user password
    [updatePassword.pending]: (state, action) => {
      state.updatePassword = true;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.updatePassword = false;
    },
    [updatePassword.rejected]: (state, action) => {
      state.updatePassword = false;
    },

     //reset user password
     [resetPassword.pending]: (state, action) => {
      state.updatePassword = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.updatePassword = false;
    },
    [resetPassword.rejected]: (state, action) => {
      state.updatePassword = false;
    },

    //verfiy account
    [verfiyAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [verfiyAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.userId = action.payload.data.id
      localStorage.setItem("userId", action.payload.data.id)
    },
    [verfiyAccount.rejected]: (state, action) => {
      state.loading = false;
    },

     //check socail login
     [checkSocaiLogin.pending]: (state, action) => {
     state.socialLoading = true;
    },
    [checkSocaiLogin.fulfilled]: (state, action) => {
      state.socialLoading = false;
      if(action.payload.data.accountType == "ACTIVE"){
        if((action.payload.data.type === "VENDOR" && action.payload.data.verify) || action.payload.data.type === "USER"){
          localStorage.setItem("token", action.payload.token);
          localStorage.setItem("userId", action.payload.data.id)
          state.userId = action.payload.data.id
          state.token = action.payload.token;
          state.isAuth = true;
          state.user = action.payload.data;
        }
        
      }
    },
    [checkSocaiLogin.rejected]: (state, action) => {
      state.socialLoading = false;
      localStorage.removeItem("token");
      state.token = null;
      state.isAuth = false;
      state.user = null;
      state.userId = null
    },
  },
});

export const { saveLanguage } = authSlice.actions;

export default authSlice.reducer;
