import React from "react";
import {AiFillHeart} from "react-icons/ai";
import {FaTruck, FaUserCircle} from "react-icons/fa";
import {IoMdSettings} from "react-icons/io";
import {RiHandbagFill} from "react-icons/ri";
import {TiHome} from "react-icons/ti";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {logOut} from "../../../Store/reducers/auth";
import {Menu} from "./StyleMenuProfile";
import {useState} from "react";
import {useEffect} from "react";
import {GiMassDriver, GiTempleGate} from "react-icons/gi";
import {BsFillCalendarEventFill} from "react-icons/bs";
import {removeReduxCart} from "../../../Store/reducers/carts";
import words from "../../../assets/words";

const MenuProfile = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const language = useSelector((state) => state.auth.language);
   const [tabs, setTabs] = useState([]);

   useEffect(() => {
      if (user?.type == "USER") {
         setTabs([
            {
               icon: <TiHome />,
               name: words.ACCOUNT_MAIN[language],
               to: "/profileUser",
            },
            {
               icon: <RiHandbagFill />,
               name: words.NEW_ORDERS[language],
               to: "/all-orders/new",
            },
            {
               icon: <FaTruck />,
               name: words.ORDERS_HISTORY[language],
               to: "/all-orders/old",
            },
            {
               icon: <AiFillHeart />,
               name: words.MY_WISHLIST[language],
               to: "/wishlist",
            },
            {
               icon: <IoMdSettings />,
               name: words.PROFILE_SETTINGS[language],
               to: "/update-profile",
            },
         ]);
      } else if (user?.type == "VENDOR") {
         setTabs([
            {
               icon: <GiTempleGate />,
               name: "Products",
               to: "/products",
            },
            {
               icon: <BsFillCalendarEventFill />,
               name: "Vendors",
               to: "/vendors-list",
            },
            {
               icon: <GiMassDriver />,
               name: "Drivers",
               to: "/drivers-list",
            },
            {
               icon: <RiHandbagFill />,
               name: "New orders",
               to: "/all-orders/new",
            },
            {
               icon: <FaTruck />,
               name: "Orders history",
               to: "/all-orders/old",
            },
            {
               icon: <IoMdSettings />,
               name: "Profile setting",
               to: "/update-profile",
            },
         ]);
      }
   }, [user]);

   const handleLogout = () => {
      dispatch(logOut()).then((res) => {
         dispatch(removeReduxCart());
      });
      navigate("/");
   };

   return (
      <Menu dir={language === "en" ? "ltr" : "rtl"}>
         <ul className="list-group">
            {tabs.map((tab, index) => {
               return (
                  <li
                     className="item_list"
                     key={index}
                  >
                     <NavLink
                        to={tab?.to}
                        className="link_R d-flex justify-content-start align-items-center"
                     >
                        {tab.icon}
                        <span className="txt_list me-2">{tab.name}</span>
                     </NavLink>
                  </li>
               );
            })}

            <li
               className="item_list"
               onClick={handleLogout}
               style={{
                  cursor: "pointer",
               }}
            >
               <div className="link_R logOut">
                  <FaUserCircle />
                  <span className="txt_list me-2">{words.LOG_OUT[language]}</span>
               </div>
            </li>
         </ul>
      </Menu>
   );
};

export default MenuProfile;
