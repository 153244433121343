import React from "react";
import { BsPlus } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import words from "../../../assets/words";

const AddProductDash = () => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.auth.language);

  return (
    <StyleAddProd>
      <div onClick={()=> navigate("/dashboard/add-product")}>
        <BsPlus />
        <button className="btn">{words.NEW_PRODUCT[language]}</button>
      </div>
    </StyleAddProd>
  );
};

export default AddProductDash;

const StyleAddProd = styled.div`
  background-color: #4a3e4c;
  color: #fff;
  border-radius: 10px;
  padding: 0px 15px;
  margin-bottom: 10px;
  svg {
    background-color: #fff;
    color: #000;
    font-size: 22px;
    border-radius: 5px;
  }
  .btn {
    color: #fff;
  }
`;
