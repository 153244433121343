import React from "react";
import { Bread } from "./StyleBreadCrumb";
import { useSelector } from "react-redux";

const Breadcrmb = ({ children }) => {
  const language = useSelector((state) => state?.auth?.language);

  return (
    <Bread aria-label="breadcrumb" dir={language === "en" ? "ltr" : "rtl"} >
      <div className="container py-3">{children}</div>
    </Bread>
  );
};

export default Breadcrmb;
