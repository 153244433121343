import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getCategories = createAsyncThunk(
  "categories/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/categories`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCategoryById = createAsyncThunk(
  "category/get",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/categories/${id}/`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "subcategories/get",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/categories/${id}/sub-categories`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: null,
    category: null,
    subCategories: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //
    //get categories
    [getCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
    },

    //get category by id
    [getCategoryById.pending]: (state, action) => {
      state.loading = true;
      state.category = null;
    },
    [getCategoryById.fulfilled]: (state, action) => {
      state.loading = false;
      state.category = action.payload;
    },
    [getCategoryById.rejected]: (state, action) => {
      state.loading = false;
    },

     //get subcategories by category id
     [getSubCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getSubCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.subCategories = action.payload;
    },
    [getSubCategories.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = categoriesSlice.actions;

export default categoriesSlice.reducer;
