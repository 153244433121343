import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getAnoncements = createAsyncThunk(
  "anoncements/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/anoncements`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const anoncementsSlice = createSlice({
  name: "anoncements",
  initialState: {
    anoncements: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //get brands
    [getAnoncements.pending]: (state, action) => {
      state.loading = true;
    },
    [getAnoncements.fulfilled]: (state, action) => {
      state.loading = false;
      state.anoncements = action.payload;
    },
    [getAnoncements.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = anoncementsSlice.actions;

export default anoncementsSlice.reducer;
