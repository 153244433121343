import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../Store/reducers/products";
import ProdctCrd from "./../ReusableComponent/CardProduct/CardProduct";
import { StyleCardRecom } from "./StyleRecomend";
import words from "../../assets/words";

const RecomendItems = () =>
{
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products?.data);
  const userId = useSelector((state) => state.auth.userId);
  const language = useSelector((state) => state.auth.language);

  useEffect(() =>
  {
    dispatch(
      getProducts({
        userId: userId ? userId : "",
      })
    );
  }, []);

  return (
    <>
      <div className="heading py-3 text-sm-center">
        <h4>{words.RECOMMENDED_ITEMS[language]}</h4>
      </div>
      <StyleCardRecom>
        <div className="row">
          {(products || []).slice(0, 4).map((item, index) =>
          {
            return (
              <div key={index} className="col-sm-12 col-md-6 col-lg-3 mt-3 d-flex justify-content-center align-items-center">
                <ProdctCrd newPaddge={true} addToFav={true} item={item} />
              </div>
            );
          })}
        </div>
      </StyleCardRecom>
    </>
  );
};

export default RecomendItems;
