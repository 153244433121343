import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import backLogin from "../../../assets/Images/backLogin.png";
import logoSign from "../../../assets/Images/Logo.png";
import BottomBar from "../../../Components/ReusableComponent/Footer/bottomBar/BottomBar";
import { SignInStyle } from "../signin/StyleSignIn";
import VerificationInput from "react-verification-input";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../../Store/reducers/users";
import { useNavigate, useParams } from "react-router-dom";
import {
  getMyInfo,
  resetPassword,
  sendCode,
  updatePassword,
  verfiyAccount,
} from "../../../Store/reducers/auth";
import CustomModal from "../../../Components/customModal/CustomModal";
import success from "../../../assets/Images/success.png";
import words from "../../../assets/words";

const VerifyAccount = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, forgot, country } = useParams();
  const user = useSelector((state) => state.users.user?.data);
  const loading = useSelector((state) => state.auth.loading);
  const passwordLoader = useSelector((state) => state.auth.updatePassword);
  const language = useSelector((state) => state.auth.language);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    repeatPassword: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [passError, setPassError] = useState("");

  useEffect(() => {
    !forgot && dispatch(getUserById(id));
    forgot &&
      dispatch(
        sendCode({
          phone: id,
          country: country,
        })
      ).then((res) => {
        if (res?.meta?.requestStatus === "rejected") {
          setError(words.SOMTHING_WRONG[language]);
        }
      });
  }, [id, country, forgot]);

  useEffect(()=>{
    !forgot && user?.phone && user?.country?.id &&
    dispatch(
      sendCode({
        phone: user?.phone,
        country: user?.country?.id,
      })
    ).then((res) => {
      if (res?.meta?.requestStatus === "rejected") {
        setError(words.SOMTHING_WRONG[language]);
      }
    });
  },[user])

  const handleVerfiyAccount = () => {
    setError("");
    setPassError("");
    setUserPassword({
      newPassword: "",
      repeatPassword: "",
    });
    dispatch(
      verfiyAccount({
        phone: forgot ? id : user?.phone,
        verifycode: code,
      })
    ).then((res) => {
      if (res?.meta?.requestStatus === "rejected") {
        setError(words.SOMTHING_WRONG[language]);
      } else {
        forgot ? setShowModal(true) : navigate("/");
      }
    });
  };

  const handlePassData = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
  };

  const handleResetPassword = () => {
    dispatch(
      resetPassword({
        phone: id,
        newPassword: userPassword.newPassword,
      })
    ).then((res) => {
      if (res.meta.requestStatus == "rejected") {
        setPassError(res.payload.errors?.msg?.[0].msg);
      } else {
        setPassError("");
        setShowModal(false);
        // navigate("/signin");
        setSuccessModal(true);
      }
    });
  };

  useEffect(() => {
    if (userPassword.newPassword.length < 8 && userPassword.newPassword) {
      setPassError(words.AT_LEAST_8_CHARACTERS[language]);
    } else {
      setPassError("");
      if (userPassword.newPassword !== userPassword.repeatPassword) {
        setPassError(words.PASSWORDS_NOT_MATCH[language]);
      } else {
        setPassError("");
      }
    }
  }, [userPassword]);

  return (
    <>
      {showModal && (
        <CustomModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          title={words.RESET_PASS[language]}
        >
          <div className="my-5">
            <p className="text-danger text-center">{passError}</p>
            <input
              name="newPassword"
              type="password"
              value={userPassword.newPassword}
              onChange={handlePassData}
              placeholder={`${words.NEW_PASS[language]} *`}
              className="form-control mb-3"
            />
            <input
              name="repeatPassword"
              type={"password"}
              value={userPassword.repeatPassword}
              onChange={handlePassData}
              placeholder={`${words.REPEAT_PASSWORD[language]} *`}
              className="form-control"
            />

            <div className="text-center">
              <button
                className="btn text-white w-50 mt-4"
                onClick={handleResetPassword}
                style={{
                  backgroundColor: "RGBA(74, 62, 76, 0.8)",
                }}
                disabled={
                  (userPassword.newPassword &&
                    userPassword.repeatPassword &&
                    userPassword.newPassword === userPassword.repeatPassword &&
                    userPassword.newPassword.length >= 8) ||
                  passwordLoader
                    ? false
                    : true
                }
              >
                {passwordLoader ? (
                  <Spinner animation="border" />
                ) : (
                  words.RESET_PASS[language]
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
      {
        successModal && (
          <CustomModal
            show={successModal} 
            onHide={() => {
              setSuccessModal(false);
            }}
            title={words.SUCCESS[language]}
            closeButtonShow={false}
            >
            <div className="text-center">
              <img src={success} alt="success" style={{
                width: "300px",
              }}/>
              <p className="text-center mt-3" style={{fontSize: "20px"}}>{words.YOUR_PASS_RESET_SUCCESS[language]} 
              </p>
              <b style={{
                fontSize: "18px",
                color: "RGBA(74, 62, 76, 0.8)",
              }} className="mb-2">
              {words.PLEASE_LOGIN_WITH_NEW_PASS[language]}
              </b>
              <div className="text-center">
                <button
                  className="btn text-white w-50 mt-4"
                  onClick={() => {
                    setSuccessModal(false);
                    navigate("/signin");
                  }}
                  style={{
                    backgroundColor: "RGBA(74, 62, 76, 0.8)",
                  }}
                >
                  {words.OK[language]}
                </button>
              </div>
            </div>
            </CustomModal>
        )
      }
      <SignInStyle className="_signIn_">
        <img
          src={backLogin}
          alt="imageBack"
          className="backimgForm"
          crossorigin="anonymous"
        />
        <span className="overlay"></span>
        <div className="row align-align-items-stretch justify-content-around w-100">
          <div className="col-sm-12 col-lg-5 img_sign ">
            <div className="container">
              <div className="cont_head d-flex flex-column justify-content-start align-items-center gap-5 pt-5">
                <div className="text text-center">
                  <h4 className="mb-4">{words.SHOP_ALL_IN_ONE_PLACE[language]}</h4>
                  <h2 className="">#{words.FIRST_IN_SUADI_ARABIA[language]}</h2>
                </div>
                <img src={logoSign} alt="logo" crossorigin="anonymous" />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-5 f-cnt">
            <div className="form-control shadow-sm">
              <div className="container">
                <h2 className="py-3">{words.VERFICATION_CODE[language]}</h2>
                {error && (
                  <b className="text-danger text-center w-100">{error}</b>
                )}
                <h5 className="text-center my-3">
                  ({forgot ? id : user?.phone})
                </h5>
                <div className="mb-3">
                  <label className="form-label mb-2">
                    {words.ENTER_CODE[language]} <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex gap-1 justify-content-center align-items-center">
                    <VerificationInput
                      length={4}
                      placeholder="-"
                      value={code}
                      onChange={(e) => setCode(e)}
                    />
                  </div>
                </div>

                <button
                  className="btn btn-primary w-100 my-5"
                  disabled={code.length !== 4 || loading}
                  onClick={handleVerfiyAccount}
                >
                  {loading ? <Spinner animation="border" /> : words.SUBMIT[language]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SignInStyle>
      <BottomBar />
    </>
  );
};

export default VerifyAccount;
