import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { getCategories } from "../../../Store/reducers/categories";
import CardBrands from "../Cardbrands/CardBrands";
import { Category } from "./styledCateg";
import { getUsers } from "../../../Store/reducers/users";
const Categories = ({ }) => {
  //navigate
  const navigate = useNavigate()
  //redux
  const dispatch = useDispatch()
  const categories = useSelector(state => state.categories.categories)
  const language = useSelector(state => state.auth.language)
  const users = useSelector(state => state.users.users)

  useEffect(()=> {
    dispatch(getCategories())
    dispatch(getUsers({
      type: "VENDOR"
    }))
  },[])

  return (
    <Category>
      <>
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 8,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper mb-5"
          slidesPerView={8}
          spaceBetween={10}
        >
          {/*Get data categories*/}
          {categories?.data?.map(categores => {
            return (
              <SwiperSlide key={categores.id} onClick={()=>{
                navigate(`/products-page/cat=${categores.id}`)
              }}>
                <div className="img_categ d-flex justify-content-center align-items-center" >
                  <img src={categores.img} alt="tshirt"  crossorigin="anonymous"/>
                </div>
                <span className="title_category">{language === "en" ? categores.name_en : categores.name_ar}</span>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>

      <div className="list">
        <div className="row">
          {users?.data?.filter((user)=> user?.accountType == "ACTIVE")?.map(userData => {
            return (
              <div className="col-sm-12 col-md-6 col-lg-3">
                <CardBrands user={userData} />
              </div>
            );
          })}
        </div>
      </div>
    </Category>
  );
};

export default Categories;
