import { useDispatch, useSelector } from "react-redux";
import classes from "./StockForm.module.scss";
import { useRef, useState } from "react";
import { Collapse } from "react-bootstrap";
import { BsPlusSquareDotted } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect } from "react";
import { CiCircleRemove } from "react-icons/ci";
import { uploadImages } from "../../../Store/reducers/products";
import words from "../../../assets/words";

const StockForm = ({ setOpenStock, stoks, setStoks, sizeData, setSizeData, colorsArr, setColorsArr }) => {
  const dispatch = useDispatch();
  const sizes = useSelector((state) => state.sizes.sizes);
  const language = useSelector((state) => state.auth.language);
  const colors = useSelector((state) => state.colors.colors);
  const [openColor, setOpenColor] = useState(false);
  const [sizeFound, setSizeFound] = useState("");
  const [noErrors, setNoErrors] = useState(false);
  const [colorData, setColorData] = useState({
    color: "",
    quantity: "",
    price: "",
    images: [],
  });
  const [noColorError, setNoColorError] = useState(false);

  const ref = useRef();
  function colorImagesRef() {
    ref.current.value = ""
    }

  useEffect(() => {
    if (sizeData.size && sizeData.quantity && sizeData.price) {
      setNoErrors(false);
    } else {
      setNoErrors(true);
    }
  }, [sizeData]);

  useEffect(() => {
    if (
      colorData.color &&
      colorData.quantity &&
      colorData.price &&
      colorData.images?.length > 0
    ) {
      setNoColorError(false);
    } else {
      setNoColorError(true);
    }
  }, [colorData]);

  const handleSizeChange = (e) => {
    setSizeData({ ...sizeData, [e.target.name]: e.target.value });
    setOpenColor(false);
  };
  const handleColorChange = (e) => {
    if (e.target.type == "file") {
      let arr = [];
      for (let i = 0; i < e.target.files?.length; i++) {
        arr.push(e.target.files[i]);
      }
      dispatch(uploadImages({ images: arr })).then((res) => {
        if (res?.meta?.requestStatus === "fulfilled") {
          setColorData({ ...colorData, images: res?.payload });
        }
      });
    } else {
      setColorData({ ...colorData, [e.target.name]: e.target.value });
    }
  };

  const addSize = () => {
    let sumQuan = 0;
    if(colorsArr?.length > 0){
      colorsArr?.map((color)=> {
        sumQuan += Number(color?.quantity)
      })
    }

    if(sumQuan > sizeData?.quantity){
      setSizeFound(words.QUANTITY_DESC[language])
    }else{
      setSizeFound("")
      const findIndex = stoks?.findIndex((item) => item.size === sizeData.size);
      if (findIndex === -1) {
        //if the key is not found in the array
        setStoks((prev) => [...prev, { ...sizeData, colors: colorsArr }]);
        setOpenStock(false);
        setSizeFound("");
        setSizeData({});
      } else {
        // setSizeFound("Size is already exists");
        setStoks((prev) => {
          const copyData = [...prev];
          copyData[findIndex] = { ...sizeData, colors: colorsArr };
          return copyData;
        });
        setOpenStock(false);
        setSizeFound("");
        setSizeData({});
      }
    }

  
  };

  const addColor = () => {
    const findIndex = colorsArr?.findIndex(
      (item) => item.color === colorData?.color
    );
    if (findIndex === -1) {
      //if the key is not found in the array
      setColorsArr((prev) => [...prev, colorData]);
      // setOpenStock(false);
      setSizeFound("");
      setColorData({
        color: "",
        quantity: "",
        price: "",
        images: [],
      });
      colorImagesRef()
    } else {
      setSizeFound(words.COLOR_IS_EXIST[language]);
    }
  };

  const removeColor = (data) => {
    let arr = colorsArr.filter((color) => color?.color !== data?.color);
    setColorsArr(arr);
  };

  return (
    <div className={classes.container} 
    dir= {language === "ar" ? "rtl" : "ltr"}
    >
      <p className="text-center text-danger w-100 mb-2">{sizeFound}</p>
      <div className={classes.sizeContainer} onChange={handleSizeChange}>
        <div>
          <p>
            {words.SIZE[language]} <span>*</span>
          </p>
          <select
            id="inputState"
            className="form-select"
            name="size"
            style={{
              width: "100%",
            }}
            value={sizeData?.size?.id}
            placeholder={words.SELECT_SIZE[language]}
          >
            <option value="">{words.SELECT_SIZE[language]}</option>
            {sizes?.data?.map((size, index) => {
              return (
                <option value={size?.id} key={index}>
                  {size?.size}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <p>{words.QUANTITY[language]}</p>
          <input
            placeholder={words.QUANTITY[language]}
            name="quantity"
            type="number"
            className="form-control"
            value={sizeData?.quantity}
          />
        </div>

            {
              colorsArr?.length > 0 ? <div></div>
              :
              <div>
              <p>{words.PRICE[language]}</p>
              <input
                placeholder={words.PRICE[language]}
                name="price"
                type="number"
                className="form-control"
                value={sizeData?.price}
                disabled={colorsArr?.length > 0}
              />
            </div>
            }
       
      </div>
      <div className="colorsDiv">
        <p
          aria-controls="example-collapse-text"
          aria-expanded={openColor}
          onClick={() => {
            if (noErrors) {
              setSizeFound(words.PLEASE_SELECT_SIZE_FIRST[language]);
            } else {
              setOpenColor(!openColor);
              setSizeFound("");
            }
          }}
          style={{ cursor: "pointer" }}
          className={classes.colorBtn}
        >
          {words.COLORS[language]} <IoIosArrowDown />
        </p>

        <Collapse in={openColor}>
          <div id="example-collapse-text">
            <div
              className={`${classes.sizeContainer} mt-2`}
              onChange={handleColorChange}
            >
              <div>
                <p>{words.COLOR[language]}</p>
                <select
                  id="inputState"
                  className="form-select"
                  name="color"
                  style={{
                    width: "100%",
                  }}
                  value={colorData?.color}
                  placeholder={words.SELECT_COLOR[language]}
                >
                  <option value="">{words.SELECT_COLOR[language]}</option>
                  {colors?.data?.map((color, index) => {
                    return (
                      <option value={color?.id} key={index}>
                        {color?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <p>{words.QUANTITY[language]}</p>
                <input
                  placeholder={words.QUANTITY[language]}
                  name="quantity"
                  type="text"
                  className="form-control"
                  value={colorData?.quantity}
                />
              </div>
              <div>
                <p>{words.PRICE[language]}</p>
                <input
                  placeholder={words.PRICE[language]}
                  name="price"
                  type="text"
                  className="form-control"
                  value={colorData?.price}
                />
              </div>
            </div>
            <div onChange={handleColorChange} className="mt-2">
              <div>
                <p>{words.IMAGES[language]}</p>
                <input
                  placeholder={words.IMAGES[language]}
                  type="file"
                  className="form-control"
                  multiple={true}
                  accept="image/*"
                  // value={colorsImages}
                  ref={ref}
                />
              </div>
            </div>

            <button
              className="btn d-flex m-auto mt-2 py-2 border"
              style={{ width: "10%" }}
              onClick={addColor}
              disabled={noColorError}
            >
              <AiOutlinePlus />
            </button>
            <div className={classes.colorsContainer}>
              {colorsArr?.map((color, index) => {
                return (
                  <p key={index}>
                    {
                      colors?.data?.find((colorf) => colorf?.id == color.color)
                        ?.name
                    }
                    <CiCircleRemove
                      size={20}
                      color={"#4a3e4c"}
                      style={{ cursor: "pointer" }}
                      onClick={() => removeColor(color)}
                    />
                  </p>
                );
              })}
            </div>
          </div>
        </Collapse>
      </div>
      <button
        className="btn"
        style={{
          backgroundColor: "#4a3e4c",
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          marginTop: 10,
          padding: "5px 30px",
        }}
        disabled={noErrors}
        onClick={addSize}
      >
        <BsPlusSquareDotted />
        {words.ADD_NEW_STOCK[language]}
      </button>
    </div>
  );
};

export default StockForm;
