import classes from "./ChatMessage.module.scss";

const ChatMessage = ({ messageInfo }) => {
  return (
    <div
      className={`${classes.chatMessage} ${
        messageInfo.type === "TEXT" ?
        (messageInfo?.messageFrom === "sender"
          ? classes.senderMessage
          : classes.recieverMessge)
          :
          null 
      }`}
      style={{
        display: "flex",
        marginLeft: messageInfo.messageFrom === "sender" ? "auto" : null
      }}
    >
      {messageInfo?.type === "IMAGE" ? (
        <img src={messageInfo?.message} alt="pic" crossorigin="anonymous | use-credentials"/>
      ) : (
        <p>{messageInfo?.message}</p>
      )}
    </div>
  );
};

export default ChatMessage;
