import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getSizes = createAsyncThunk(
  "sizes/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/sizes/withoutPagenation/get`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



const sizesSlice = createSlice({
  name: "sizes",
  initialState: {
    sizes: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //get users
    [getSizes.pending]: (state, action) => {
      state.loading = true;
    },
    [getSizes.fulfilled]: (state, action) => {
      state.loading = false;
      state.sizes = action.payload;
    },
    [getSizes.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = anoncementsSlice.actions;

export default sizesSlice.reducer;
