import React from "react";
import "./about.scss";
import imageAbout from "../../assets/Images/shop-online.jpg";
import { useSelector } from "react-redux";
import words from "../../assets/words";
const About = () => {
   const language = useSelector(state => state.auth.language)

   return (
      <div className="about_us"
      dir= {language === "ar" ? "rtl" : "ltr"}
      >
         <div className="head_page mb-4">
            <div className="container">
               <h4>{words.ABOUT_US[language]}</h4>
            </div>
         </div>
         <div className="container mb-5">
            <div className="row">
               <p className="px-3 bg-gray txt col-sm-12 col-lg-6">
                {words.ABOUT_US_DESC[language]}
               </p>
               <img
                  src={imageAbout}
                  alt="about-us"
                  className="col-sm-12 col-lg-6"
               />
            </div>
         </div>
      </div>
   );
};

export default About;
