import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyleSearch } from "./InpStyle";
import words from "../../assets/words";

const InputSearch = () =>
{
  const navigate = useNavigate();
  const categories = useSelector((state) => state.categories.categories?.data);
  const language = useSelector((state) => state.auth.language);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(null);

  return (
    <StyleSearch className=" w-100 rounded-2  position-relative">
      <div class={'input_group ' + (language == "ar" ? "rtl" : "ltr")} style={{
        direction: language == "ar" ? "rtl" : "ltr"
      }}>
        <select
          class="form-select"
          aria-label="Default select example"
          style={{ width: "120%" }}
          onChange={(e) => setCategory(e.target.value)}
          value={category}
        >
          <option selected value={""}>{words.ALL[language]}</option>
          {categories?.map((cat, index) =>
          {
            return <option value={cat?.id} key={index}>{cat?.name}</option>;
          })}
        </select>
        <div className="inpp__">
          <input
            type="text"
            class="form-control"
            aria-label="Text input with dropdown button"
            placeholder={words.SEARCH_EX[language]}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) =>
            {
              if (e.key === "Enter" && search)
              {
                category
                  ? navigate(`/products-page/cat=${category}&search=${search}`)
                  : navigate(`/products-page/search=${search}`);
              }
            }}
          />
        </div>
      </div>
    </StyleSearch>
  );
};

export default InputSearch;
