import React from "react";
import { useEffect } from "react";
import { BiLogOut } from "react-icons/bi";
import { BsFillHandbagFill, BsGridFill } from "react-icons/bs";
import { FaBullhorn, FaTruck } from "react-icons/fa";
import { HiUser, HiUserGroup } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { logOut } from "../../../Store/reducers/auth";
import { AiFillWechat } from "react-icons/ai";
import words from "../../../assets/words";

const MenuDash = ({ setCurrentPage }) => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user)
  const language = useSelector((state) => state.auth.language);

  const tabs = [
    {
      name: words.PRODUCTS[language],
      icon: <BsGridFill />,
      to: "/dashboard/products",
    },
    {
      name: words.NEW_ORDERS[language],
      icon: <BsFillHandbagFill />,
      to: "/dashboard/orders/new",
    },
    {
      name: words.ORDERS_HISTORY[language],
      icon: <FaTruck />,
      to: "/dashboard/orders/old",
    },
    {
      name: words.VENDORS[language],
      icon: <HiUserGroup />,
      to: "/dashboard/vendors",
    },
    {
      name: words.CHAT_CONTACTS[language],
      icon: <AiFillWechat />,
      to: "/dashboard/chat-contacts",
    },
    // {
    //   name: "Drivers",
    //   icon: <HiUserGroup />,
    //   to: "/dashboard/drivers"
    // },
    {
      name: words.DRIVERS_REPLIES[language],
      icon: <FaBullhorn />,
      to: "/dashboard/drivers-replies",
    },
    {
    name: words.PROFILE_SETTINGS[language],
    // icon: <HiUser />,
    icon: <img crossorigin="anonymous" src={user?.img} alt='user pic' style={{width: "20px", height: "100%", borderRadius: "50%"}}/>,
    to: "/dashboard/profile"
  },
  ];

  useEffect(() => {
    const find = tabs.find((tab) => tab.to === location);
    if (location.includes("add-product")) {
      setCurrentPage({
        name: words.ADD_PRODUCT[language],
      });
    } else {
      setCurrentPage(find);
    }
  }, [location]);

  return (
    <StyleMenu>
      {tabs.map((tab, index) => {
        return (
          <li className="item mb-4" key={index}>
            <NavLink
              to={tab.to}
              className="link_route fw-medium d-flex justify-content-start align-items-center gap-4"
              onClick={() => setCurrentPage(tab)}
            >
              {tab.icon}
              {tab.name}
            </NavLink>
          </li>
        );
      })}
      <li className="item mb-4">
        <NavLink
          to="/"
          className="link_route fw-medium d-flex justify-content-start align-items-center gap-4"
          onClick={() => dispatch(logOut())}
        >
          <BiLogOut />
          {words.LOG_OUT[language]}
        </NavLink>
      </li>
    </StyleMenu>
  );
};

export default MenuDash;

const StyleMenu = styled.ul`
  .link_route {
    color: #aaabb1;
  }
  svg {
    font-size: 22px;
    color: #aaabb1;
  }
  .active {
    color: #4a3e4c;
    svg {
      color: #4a3e4c;
    }
  }
  .complete {
    background-color: #f7f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #82828c;
    margin-top: 7rem;
    margin-bottom: 4rem;
    .comp {
      background-color: #4a3e4c;
      color: #fff;
    }
  }
  .head__ {
    color: #aaabb1;
    margin-bottom: 1rem;
  }
`;
