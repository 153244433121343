import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import android from "../../../assets/Images/google-play.png";
import ios from "../../../assets/Images/ios-apple.png";
import logoFooter from "../../../assets/Images/Logo.png"; //Screenshot_2.png
import { getCategories } from "../../../Store/reducers/categories";
import BottomBar from "./bottomBar/BottomBar";
import { FooterStyle } from "./FooterStyled";
import { Link, useNavigate } from "react-router-dom";
import words from "../../../assets/words";

const Footer = () =>
{
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const categories = useSelector((state) => state.categories.categories);
   const language = useSelector((state) => state.auth.language);

   useEffect(() =>
   {
      dispatch(getCategories());
   }, []);

   return (
      <>
         <FooterStyle dir={language === "en" ? "ltr" : "rtl"}>
            {/* <img src={triangleImage} alt="triangle" className="backGTriangle" /> */}
            <div className="container">
               <div className="row padd__">
                  <div className="col-sm-12 col-lg-6 mb-2">
                     <div className="d-flex justify-content-start align-items-start flex-column">
                        <div className="logo mb-4">
                           <img
                              src={logoFooter}
                              alt="logo"
                              crossorigin="anonymous"
                           />
                        </div>

                        <p className="text_foot mb-4" style={{
                           paddingRight: language === "en" && "10rem",
                           paddingLeft: language === "ar" && "20rem",
                        }}>
                           {words.COTTAGE_DESC[language]}{" "}
                        </p>

                        <div className="downloadApps">
                           <h3 className="mb-3">{words.DOWNLOAD_OUR_APP[language]}</h3>
                           <div className="d-flex align-items-center">
                              <a
                                 href="/"
                                 className="box__app d-flex justify-content-center align-items-center rounded-2"
                              >
                                 <img
                                    src={android}
                                    alt="google"
                                    crossorigin="anonymous"
                                 />
                                 <div className="text_app">
                                    <span>GET IT ON</span>
                                    <p>Google Play</p>
                                 </div>
                              </a>

                              <a
                                 href="/"
                                 className="box__app mx-3 d-flex justify-content-center align-items-center rounded-2"
                              >
                                 <img
                                    src={ios}
                                    alt="google"
                                    crossorigin="anonymous"
                                 />
                                 <div className="text_app">
                                    <span>GET IT ON</span>
                                    <p>Google Play</p>
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-sm-12 col-lg-2 foot_categories  mb-3">
                     <h3 className="fw-medium mb-4 ">{words.CATEGORIES[language]}</h3>
                     <ul>
                        {categories?.data?.map((cat, index) =>
                        {
                           return (
                              <li
                                 key={index}
                                 className="text-white"
                              >
                                 <Link
                                    to={`/products-page/cat=${cat?.id}`}
                                    className="text-white links_footer"
                                 >
                                    {language === "en" ? cat?.name_en : cat?.name_ar}
                                 </Link>
                              </li>
                           );
                        })}
                     </ul>
                  </div>
                  <div className="col-sm-12 col-lg-2 foot_categories  mb-3">
                     <h3 className="fw-medium mb-4">{words.MY_ACCOUNT[language]}</h3>
                     <ul>
                        <li>
                           <Link
                              to={`/dashboard/profile`}
                              className="text-white">
                              {words.ACCOUNT_INFORMATION[language]}
                           </Link>
                        </li>
                        <li>
                           <Link
                              to={`/dashboard/products`}
                              className="text-white">
                              {words.MY_LISTINGS[language]}
                           </Link>
                        </li>
                        <li>
                           <Link
                              to={`/wishlist`}
                              className="text-white">
                              {words.MY_FAVORITES[language]}
                           </Link></li>
                        <li>
                           <Link
                              to={`/all-orders/old`}
                              className="text-white">
                              {words.MY_ORDERS[language]}
                           </Link>
                        </li>
                     </ul>
                  </div>
                  <div className="col-sm-12 col-lg-2 foot_categories  mb-3">
                     <h3 className="fw-medium mb-4 ">{words.INFORMATION[language]}</h3>
                     <ul>
                        <li>
                           <Link
                              to="About-us"
                              className="links_footer"
                           >
                              {words.ABOUT_US[language]}
                           </Link>
                        </li>
                        <li>
                           <Link
                              to="Terms-and-Condition"
                              className="links_footer"
                           >
                              {words.TERMS_AND_CONDITIONS[language]}
                           </Link>
                        </li>
                        {/* <li>Privacy & Cookie Policy</li>
                        <li>Blog</li> */}
                        <li>
                           <Link
                              to="Contact-us"
                              className="links_footer"
                           >
                              {words.CONTACT_US[language]}
                           </Link>
                        </li>
                        {/* <li>Sitemap</li> */}
                     </ul>
                  </div>
               </div>
            </div>
            <BottomBar />
         </FooterStyle>
      </>
   );
};

export default Footer;
