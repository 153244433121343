import { BsPlusSquareDotted } from "react-icons/bs";
import classes from "./AddStock.module.scss";
import { useState } from "react";
import CustomModal from "../../customModal/CustomModal";
import StockForm from "./StockForm";
import { useSelector } from "react-redux";
import words from "../../../assets/words";

const AddStock = ({ stoks, setStoks, openStock, setOpenStock, sizeData, setSizeData, colorsArr, setColorsArr }) => {
  // const [openStock, setOpenStock] = useState(false);
  const language = useSelector((state) => state.auth.language);

  return (
    <div className={classes.stock}>
      {openStock && (
        <CustomModal
          onHide={() => setOpenStock(false)}
          show={openStock}
          title={words.ADD_NEW_STOCK[language]}
          size={"lg"}
        >
          <StockForm
            stoks={stoks}
            setStoks={setStoks}
            setOpenStock={setOpenStock}
            sizeData={sizeData}
            setSizeData={setSizeData}
            colorsArr={colorsArr}
            setColorsArr={setColorsArr}
          />
        </CustomModal>
      )}

      <button className="btn" onClick={() => {
        setOpenStock(true)
        setSizeData({})
        setColorsArr([])
      }}>
        <BsPlusSquareDotted />
        {words.ADD_NEW_STOCK[language]}
      </button>
    </div>
  );
};

export default AddStock;
