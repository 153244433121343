import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getCarts = createAsyncThunk(
  "carts/get",
  async (_, { getState, rejectWithValue }) =>
  {
    try
    {
      const url = `${BASE_URL}/cart/get`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error)
    {
      if (error.response && error.response.data)
      {
        return rejectWithValue(error.response.data);
      } else
      {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addToCart = createAsyncThunk(
  "carts/post",
  async (data, { getState, rejectWithValue }) =>
  {
    try
    {
      const url = `${BASE_URL}/cart/${data?.id}/products`;
      const response = await axios.post(url, data, tokenConfig(getState));
      return response.data;
    } catch (error)
    {
      if (error.response && error.response.data)
      {
        return rejectWithValue(error.response.data);
      } else
      {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "carts/delete",
  async (id, { getState, rejectWithValue }) =>
  {
    try
    {
      const url = `${BASE_URL}/cart/${id}`;
      const response = await axios.delete(url, tokenConfig(getState));
      return response.data;
    } catch (error)
    {
      if (error.response && error.response.data)
      {
        return rejectWithValue(error.response.data);
      } else
      {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkCoupon = createAsyncThunk(
  "coipon/check",
  async (data, { getState, rejectWithValue }) =>
  {
    try
    {
      const url = `${BASE_URL}/coupons/checkValidateCoupon`;
      const response = await axios.post(url, data, tokenConfig(getState));
      return response.data;
    } catch (error)
    {
      if (error.response && error.response.data)
      {
        return rejectWithValue(error.response.data);
      } else
      {
        return rejectWithValue(error.message);
      }
    }
  }
);

const loadCarts = () => {
  try {
    const serializedState = localStorage.getItem('carts');
    if (serializedState === null) {
      return [];
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Error loading state from local storage:', error);
    return [];
  }
};


const initialFormData = {
};

const cartsSlice = createSlice({
  name: "carts",
  initialState: {
    carts: null,
    loading: false,
    reduxCarts: loadCarts() || [],
    discount: null,
    formData: initialFormData // Initialize form data
  },
  reducers: {
    // hydrate: (state, action) =>
    // {
    //   // do not do state = action.payload it will not update the store
    //   return action.payload
    // },
    setFormData(state, action) {
      state.formData = action.payload;
    },
    removeReduxCart: (state, action) =>
    {
      state.reduxCarts = []
    },
    addToReduxCart: (state, action) =>
    {
      console.log(action.payload)
      let index = null;
      let item = null;
      if (action.payload?.files)
      {
        index = state.reduxCarts.findIndex(
          (item) => item.id == action.payload.id && item.size.id == action.payload.size.id
        );
        item = state.reduxCarts.find(
          (item) => item.id == action.payload.id && item.size.id == action.payload.size.id
        );
      } else
      {
        index = state.reduxCarts.findIndex(
          (item) => item.id == action.payload.id && item.color.id == action.payload.color.id && item.size.id == action.payload.size.id
        );
        item = state.reduxCarts.find(
          (item) => item.id == action.payload.id && item.color.id == action.payload.color.id && item.size.id == action.payload.size.id
        );
      }

      if (index != -1)
      {
        item.quantity = item.quantity === item.stockQuantity ? item.quantity : item.quantity + 1;
        // state.reduxCarts[index].quantity = state.reduxCarts[index].quantity + 1;
      } else
      {
        action.payload.stockQuantity > 0 && state.reduxCarts.push({ ...action.payload, quantity: 1 });
      }
    },
    removeFromReduxCart: (state, action) =>
    {
      let index = null;
      if (action.payload?.files)
      {
        index = state.reduxCarts.findIndex(
          (item) => item.id == action.payload.id && item.size.id == action.payload.size.id
        )
      } else
      {
        index = state.reduxCarts.findIndex(
          (item) => item.id == action.payload.id && item.color.id == action.payload.color.id && item.size.id == action.payload.size.id
        );
      }
      state.reduxCarts.splice(index, 1)
    },
    decreaseCart: (state, action) =>
    {
      console.log(action.payload)
      let index = null;
      let item = null;
      if (action.payload?.files)
      {
        index = state.reduxCarts.findIndex(
          (item) => item.id == action.payload.id && item.size.id == action.payload.size.id
        );
        item = state.reduxCarts.find((item) => item.id == action.payload.id && item.size.id == action.payload.size.id)
      } else
      {
        index = state.reduxCarts.findIndex(
          (item) => item.id == action.payload.id && item.color.id == action.payload.color.id && item.size.id == action.payload.size.id
        );
        item = state.reduxCarts.find((item) => item.id == action.payload.id && item.color.id == action.payload.color.id && item.size.id == action.payload.size.id)
      }

      item
        .quantity == 1
        ? state.reduxCarts.splice(index, 1)
        : item.quantity -= 1;
    }
  },
  extraReducers: {
    //get favorites
    [getCarts.pending]: (state, action) =>
    {
      state.loading = true;
    },
    [getCarts.fulfilled]: (state, action) =>
    {
      state.loading = false;
      state.carts = action.payload;
    },
    [getCarts.rejected]: (state, action) =>
    {
      state.loading = false;
    },

    //create fav
    [addToCart.pending]: (state, action) =>
    {
      state.loading = true;
    },
    [addToCart.fulfilled]: (state, action) =>
    {
      state.loading = false;
    },
    [addToCart.rejected]: (state, action) =>
    {
      state.loading = false;
    },

    [removeFromCart.pending]: (state, action) =>
    {
      state.loading = true
    },
    [removeFromCart.fulfilled]: (state, action) =>
    {
      state.loading = false
    },
    [removeFromCart.rejected]: (state, action) =>
    {
      state.loading = false
    },

    [checkCoupon.pending]: (state, action) =>
    {
      state.loading = true
    },
    [checkCoupon.fulfilled]: (state, action) =>
    {
      state.loading = false
      state.discount = action.payload.data
    },
    [checkCoupon.rejected]: (state, action) =>
    {
      state.loading = false
    }
  },
});

export const { decreaseCart, removeFromReduxCart, setFormData, addToReduxCart, removeReduxCart } = cartsSlice.actions;

export default cartsSlice.reducer;
