import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getAddresses = createAsyncThunk(
  "addresses/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/address`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addAddress = createAsyncThunk(
  "addresses/post/new",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/address`;
      const response = await axios.post(url, data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAddress = createAsyncThunk(
  "addresses/update/put",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/address/${data?.id}`;
      const response = await axios.put(url, data, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "addresses/delete",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/address/${id}`;
      const response = await axios.delete(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const addressesSlice = createSlice({
  name: "addresses",
  initialState: {
    addresses: null,
    loading: false,
    newAddress: null,
  },
  reducers: {},
  extraReducers: {
    //get addresses
    [getAddresses.pending]: (state, action) => {
      state.loading = true;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.loading = false;
      state.addresses = action.payload;
    },
    [getAddresses.rejected]: (state, action) => {
      state.loading = false;
    },

    //add address
    [addAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [addAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.newAddress = action.payload;
    },
    [addAddress.rejected]: (state, action) => {
      state.loading = false;
    },

    //update address
    [updateAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAddress.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAddress.rejected]: (state, action) => {
      state.loading = false;
    },

    //delete address
    [deleteAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteAddress.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = anoncementsSlice.actions;

export default addressesSlice.reducer;
