import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, tokenConfig } from "../common";

export const getCountries = createAsyncThunk(
  "countries/get",
  async (data, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/countries`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCitiesByCountryId = createAsyncThunk(
  "cities/get",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/cities/${id}/countries`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAreasByCountryId = createAsyncThunk(
  "areas/get",
  async (id, { getState, rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/cities/${id}/areas`;
      const response = await axios.get(url, tokenConfig(getState));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    countries: null,
    cities: null,
    areas: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //get brands
    [getCountries.pending]: (state, action) => {
      state.loading = true;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.loading = false;
      state.countries = action.payload.data;
    },
    [getCountries.rejected]: (state, action) => {
      state.loading = false;
    },

    [getCitiesByCountryId.pending]: (state, action) => {
      state.loading = true;
    },
    [getCitiesByCountryId.fulfilled]: (state, action) => {
      state.loading = false;
      state.cities = action.payload.data;
    },
    [getCitiesByCountryId.rejected]: (state, action) => {
      state.loading = false;
    },

    [getAreasByCountryId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAreasByCountryId.fulfilled]: (state, action) => {
      state.loading = false;
      state.areas = action.payload.data;
    },
    [getAreasByCountryId.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// export const {} = anoncementsSlice.actions;

export default countriesSlice.reducer;
