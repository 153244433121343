export const BASE_URL = "https://api.cottage-ksa.com/api/v1";

export const tokenConfig = (getState) => {
    // Get token from state
    const token = getState().auth.token;
  
    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': getState().auth.language,
      },
    };
  
    // If token, add to headers config
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  };
  