import { useDispatch, useSelector } from "react-redux";
import AddProductForm from "../../../Components/Dashboard/AddProduct/AddProductForm";
import AddStock from "../../../Components/Dashboard/AddProduct/AddStock";
import Properties from "../../../Components/Dashboard/AddProduct/Properties";
import classes from "./AddProduct.module.scss";
import { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";
import { useEffect } from "react";
import {
  addProduct,
  clearProduct,
  getProduct,
  getProducts,
  updateProduct,
  uploadImages,
} from "../../../Store/reducers/products";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getSubCategories } from "../../../Store/reducers/categories";
import words from "../../../assets/words";

const AddProduct = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const id = useParams()?.id;
  const sizes = useSelector((state) => state.sizes.sizes?.data);
  const loader = useSelector((state) => state.products.loading);
  const updateLoader = useSelector((state) => state.products.updateLoader);
  const language = useSelector((state) => state.auth.language);
  const user = useSelector((state) => state.auth.user);
  const [product, setProduct] = useState({});
  const [properties, setProperties] = useState([]);
  const [stoks, setStoks] = useState([]);
  const [noErrors, setNoErrors] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const [filesError, setFilesError] = useState("")
  const [sizeData, setSizeData] = useState({
    size: "",
    colors: [],
    quantity: "",
    price: "",
  });
  const [colorsArr, setColorsArr] = useState([]);
  const [filesLoader, setFilesLoader] = useState(false);

  useEffect(() => {
    if (
      product?.name_en &&
      product?.name_ar &&
      product?.description_en &&
      product?.description_ar &&
      product?.category &&
      product?.subCategory &&
      // product?.quantity &&
      product?.brand &&
      properties?.length > 0 &&
      stoks?.length > 0
    ) {
      setNoErrors(false);
    } else {
      setNoErrors(true);
    }
  }, [stoks, properties, product]);

  useEffect(()=>{
    if(id){
      dispatch(getProduct({id})).then(res=>{
        if(res?.meta?.requestStatus === "fulfilled"){
          setProduct(res?.payload?.data)
          dispatch(getSubCategories(res?.payload?.data?.category?.id))
        }
      })
    }
  },[id])

  useEffect(()=>{
    if(product && id){
      let arr = []
      let arr2 = []
      product?.stock?.map((item)=>{
        arr2.push({
          size: item?.size?.id || item?.size,
          colors: item?.colors?.map((color)=>{
            return({
              color: color?.color?.id,
              quantity: color?.quantity,
              price: color?.price,
              images: product?.images?.find((img) => img?.color?.id === color?.color?.id)?.images
            })
          }),
          quantity: item?.quantity,
          price: item?.price,
        })
      })
      setStoks(arr2)
      if(product?.properties?.length > 0){
        product?.properties?.map((item)=>{
          arr.push({
            property_en: item.property,
            property_ar: item.property
          })
        })
        setProperties(arr)
      }
    }else{
      dispatch(clearProduct())
      setStoks([])
      setProperties([])
    }
  },[product])

  const handleOnChange = (e) => {
    if (e.target.type == "file") {
      let arr = [];
      for (let i = 0; i < e.target.files?.length; i++) {
        arr.push(e.target.files[i]);
      }
      setFilesLoader(true);
      dispatch(uploadImages({ images: arr })).then((res) => {
        if (res?.meta?.requestStatus === "fulfilled") {
          setFilesLoader(false);
          setProduct({ ...product, files: res?.payload });
        }
      });
    } else {
      setProduct({ ...product, [e.target.name]: e.target.value });
    }
  };

  const removeProperty = (data) => {
    let arr = properties.filter(
      (property) => property?.property_en !== data?.property_en
    );
    setProperties(arr);
  };

  const removeStock = (e, data) => {
    e.preventDefault();
    let arr = stoks.filter((stock) => stock?.size !== data?.size);
    setStoks(arr);
  };

  const addProductFun = () => {
    let images = [];
    stoks.map((stock) => {
      stock?.colors?.map((color) => {
        images.push({
          color: color.color?.id || color.color,
          images: color.images,
        });
      });
    });
    if (images.length > 0) {
      dispatch(
        addProduct({
          name_en: product?.name_en,
          name_ar: product?.name_ar,
          description_en: product?.description_en,
          description_ar: product?.description_ar,
          category: product?.category,
          subCategory: product?.subCategory,
          brand: product?.brand,
          properties: properties,
          theStock: stoks,
          images: images,
        })
      ).then((res) => {
        getProducts({
          owner: user?.id,
        });
        navigate("/dashboard/products")
      });
    } else {
      if(product?.files?.length > 0){
        setFilesError("")
        dispatch(
          addProduct({
            ...product,
            properties: properties,
            theStock: stoks,
          })
        ).then((res) => {
          getProducts({
            owner: user?.id,
          });
          navigate("/dashboard/products")
        });
      }else{
        setFilesError("You must add images.")
      }
    }
  };

  const updateProductFun = () => {
    let images = [];
    stoks.map((stock) => {
      stock?.colors?.map((color) => {
        images.push({
          color: color.color,
          images: color.images,
        });
      });
    });
    if (images.length > 0) {
      dispatch(
        updateProduct({
          id: product?.id,
          name_en: product?.name_en,
          name_ar: product?.name_ar,
          description_en: product?.description_en,
          description_ar: product?.description_ar,
          category: product?.category?.id || product?.category,
          subCategory: product?.subCategory?.id || product?.subCategory,
          brand: product?.brand?.id || product?.brand,
          properties: properties,
          theStock: stoks,
          images: images,
        })
      ).then((res) => {
        getProducts({
          owner: user?.id,
        });
        navigate("/dashboard/products")
      });
    } else {
      if(product?.files?.length > 0){
        setFilesError("")
        dispatch(
          updateProduct({
            id: product?.id,
            name_en: product?.name_en,
            name_ar: product?.name_ar,
            description_en: product?.description_en,
            description_ar: product?.description_ar,
            category: product?.category?.id || product?.category,
            subCategory: product?.subCategory?.id || product?.subCategory,
            brand: product?.brand?.id || product?.brand,
            properties: properties,
            theStock: stoks,
            files: product?.files,
          })
        ).then((res) => {
          getProducts({
            owner: user?.id,
          });
          navigate("/dashboard/products")
        });
      }else{
        setFilesError("You must add images.")
      }
    }
  }

  const showStock = (e, data) => {
    e.preventDefault();
    setOpenStock(true);
    setSizeData(data);
    setColorsArr(data?.colors);
  };


  return (
    <div className={`${classes.container} shadow`}>
      <div className={classes.content}>
        {
          id && loader ?
          <Spinner animation="border" />
          :
          <>
           <AddProductForm onChange={handleOnChange} product={product} filesError={filesError}/>
        {filesLoader && (
          <div className="text-center mt-2">
            <Spinner animation="border" />
          </div>
        )}
           <div className={`${classes.properties} shadow-sm`}>
          {properties.length > 0 ? (
            properties.map((property, index) => {
              return (
                <div key={index} className={classes.property}>
                  <p>- {property?.property_en}</p>
                  <CiCircleRemove
                    size={20}
                    color={"#4a3e4c"}
                    style={{ cursor: "pointer" }}
                    onClick={() => removeProperty(property)}
                  />
                </div>
              );
            })
          ) : (
            <p className="text-center w-100">
             {words.YOU_MUST_ADD_PROPERTY[language]}
            </p>
          )}
        </div>

        <Properties properties={properties} setProperties={setProperties} />

        <div className={`${classes.properties} shadow-sm`}>
          {stoks?.length > 0 ? (
            stoks?.map((stock, index) => {
              return (
                <div
                  key={index}
                  className={classes.property}
                  style={{ cursor: "pointer" }}
                >
                  <p
                    onClick={(e) => showStock(e, stock)}
                    style={{ textDecoration: "underLine", width: "30%" }}
                  >
                    - {sizes?.find((size) => size?.id == stock?.size)?.size || stock?.size?.name}
                  </p>
                  <CiCircleRemove
                    size={20}
                    color={"#4a3e4c"}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => removeStock(e, stock)}
                  />
                </div>
              );
            })
          ) : (
            <p className="text-center w-100">
              {words.YOU_MUST_ADD_STOCK[language]}
            </p>
          )}
        </div>
        <AddStock
          stoks={stoks}
          setStoks={setStoks}
          openStock={openStock}
          setOpenStock={setOpenStock}
          sizeData={sizeData}
          setSizeData={setSizeData}
          colorsArr={colorsArr}
          setColorsArr={setColorsArr}
        />

       {
        id ? 
        <button className="btn" disabled={noErrors || updateLoader} onClick={updateProductFun}>
        {updateLoader ? <Spinner animation="border" /> : words.UPDATE_PRODUCT[language]}
      </button>
        :
        <button className="btn" disabled={noErrors || loader} onClick={addProductFun}>
        {loader ? <Spinner animation="border" /> : words.ADD_PRODUCT[language]}
      </button>
       }
       
       
          </>
        }
       
      </div>
    </div>
  );
};

export default AddProduct;
