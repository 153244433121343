import styled from "styled-components";

export const ProductsStyled = styled.div`
   min-height: 100vh;
   .header_filter {
      .form-select {
         background-color: #fafafa;
         box-shadow: none;
      }
      .change_design_cards {
         .btn {
            display: flex;
            align-items: center;
            padding: 0;
            .cont_icon {
               display: inline-block;
               width: 2.2rem;
               height: 2.2rem;
               line-height: 2rem;
               font-size: 23px;
               color: #9da1a7;
            }
            .active_icon {
               background-color: #e0e0e0;
            }
         }
      }
   }

   .Products_in {
      padding: 20px 0;
      .title_sections {
         margin-bottom: 15px !important;
      }
      .card___ {
         border: 1px solid #ddd;
      }
   }
   .card {

      img {
         background-color: #f7f7f7;
      }
      /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      padding: 10px;
      transition: all 0.1s;
      &:hover {
         box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      } */
   }
`;
