import React, { useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { MdKeyboardArrowDown } from "react-icons/md";
import styled from "styled-components";
import Table from "../../../Components/table/Table";
import { useState } from "react";
import classes from "./Drivers.module.scss";
import userImg from "../../../assets/Images/user-placeholder.png";
import { cutWords } from "../../../utils/formatWords";
import { BsChatDotsFill } from "react-icons/bs";
import StarsRate from "../../../Components/StarsRate/StarsRate";
import { getDriversOfferByOrderId } from "../../../Store/reducers/orders";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

const DriversDash = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  // const orders = useSelector((state) => state.orders.orders);
  // const loader = useSelector((state) => state.orders.loading);
  // const statusLoader = useSelector(state => state.orders.cancelLoading)
  // const language = useSelector((state) => state.auth.language);

  console.log(id, 'sss')

  useEffect(() => {
    dispatch(
      getDriversOfferByOrderId(id)
    );
  }, [id]);

  const [data, setData] = useState([
    {
      name: "shrouk khaled",
      phoneNumber: "0114465828",
      rate: 3,
      peopleRate: 15,
    },
    {
      name: "shrouk khaled",
      phoneNumber: "0114465828",
      rate: 3,
      peopleRate: 15,
    },
    {
      name: "shrouk khaled",
      phoneNumber: "0114465828",
      rate: 3,
      peopleRate: 15,
    },
  ]);

  return (
    <StyleProd className="container-fluid">
      <div className="row">
        <div className={`${classes.container} col-12 side_content pt-2`}>
          {/*Filter section*/}
          <div className="addProduct_&_filter d-flex justify-content-between align-items-center">
            <div className="inp_search position-relative">
              <input className="form-control py-3" placeholder="search..." />
              <BiSearch />
            </div>
            <div className="filters d-flex justify-content-start gap-4">
              {/*Filter Sort*/}
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle sort d-flex justify-content-between align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="text_t">Sort by</span>
                  <MdKeyboardArrowDown />
                </button>
                <ul class="dropdown-menu">
                  <li>option #1</li>
                  <li>option #1</li>
                  <li>option #1</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="w-100 mt-2 d-flex gap-4" style={{ flexWrap: "wrap",}}>
            {data.map((drive, index) => {
              return (
                <div
                  className="shadow"
                  style={{
                    width: "25%",
                    display: "flex",
                   
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                  }}
                  key={index}
                >
                  <img
                   crossorigin="anonymous"
                    src={userImg}
                    alt="driverPic"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                  />
                  <h3>{drive.name}</h3>
                  <p style={{ color: "grey" }}>{drive.phoneNumber}</p>
                  <div className="mt-2">
                    <StarsRate num={Math.ceil(drive.rate)} size={15} />{" "}
                    <span style={{ color: "grey" }}>({drive.peopleRate})</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </StyleProd>
  );
};

export default DriversDash;

const StyleProd = styled.div`
  .side_menu_dash {
    .inf_user {
      background-color: #f7f6fa;
      padding: 15px;
      border-radius: 15px;
      margin-bottom: 3rem;
    }
  }
  .side_content {
    min-height: 100vh;
    background-color: #f7f6fa;
    border-radius: 10px;
    .inp_search {
      min-width: 500px;
      .form-control {
        padding-left: 50px !important;
        background-color: #f0eff3;
        border-color: transparent;
        &:focus {
          box-shadow: none;
          border-color: #4a3e4c;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        font-size: 22px;
      }
    }
    .btn-secondary {
      background-color: #f0eff3;
      border: none;
      outline: none;
      color: #000;
    }
    .logNotifMess {
      .inf_user {
        svg {
          display: none;
        }
      }
      .title_ {
        padding-right: 10px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .icon_ {
      background-color: #f0eff3;
      color: #dddce2;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      width: 55px;
      padding: 10px;
    }

    // Style filter
    .filters {
      .sort {
        svg {
          font-size: 20px;
          margin-left: 5px;
        }
      }
    }
  }
  .copyRight {
    color: #aaabb1;
  }
`;
