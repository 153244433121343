import classes from "./Chat.module.scss";
import userPlaceholder from "../../../assets/Images/user-placeholder.png";
import { IoCloseCircleOutline } from "react-icons/io5";
import { BiImageAdd } from "react-icons/bi";
import { MdSend } from "react-icons/md";
import ChatMessage from "../../../Components/Dashboard/Chat/ChatMessage";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../../socket/Socket";
import { clearChats, getChats } from "../../../Store/reducers/chats";
import { Spinner } from "react-bootstrap";
import { uploadImages } from "../../../Store/reducers/products";
import words from "../../../assets/words";
socket.connect();

const Chat = ({ vendor, onChatClose, callApi }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const chats = useSelector((state) => state.chats.chats);
  const loading = useSelector(state => state.chats.loading)
  const language = useSelector((state) => state.auth.language);
  const [chatMessages, setChatMessages] = useState([]);
  const [writeMessage, setWriteMessage] = useState("");
  const [fileImage, setFileImage] = useState(null)
  const [image, setImage] = useState("");
  const [page, setPage] = useState(1);

  const handleSentMessage = () => {
    let arr = [];
    if (image) {
        setChatMessages(prev => [ {
          message: fileImage,
          messageFrom: "sender",
          type: "IMAGE",
        }, ...prev]);
        setWriteMessage("");
        setImage("");
        setFileImage("")
      socket.emit("newMessage", {
        dataType: "IMAGE", //TEXT - IMAGE - DOCX - RECORD - LINK - VIDEO
        content: fileImage,
        toId: vendor?.id,
        fromId: user?.id,
      });
    } else {
      setChatMessages(prev => [ {
        message: writeMessage,
        messageFrom: "sender",
        type: "TEXT",
      }, ...prev]);
      setWriteMessage("");
      setImage("");
      setFileImage("")
      socket.emit("newMessage", {
        dataType: "TEXT", //TEXT - IMAGE - DOCX - RECORD - LINK - VIDEO
        content: writeMessage,
        toId: vendor?.id,
        fromId: user?.id,
      });
    }
   
  };

  useEffect(() => {
    socket.on("newMessage", (data) => {
       if((data.user.id === vendor.id )){
        setChatMessages(prev => [ {
          message: data.content,
          messageFrom: data.user.id === user.id ? "sender" : "reciever",
          type: data.dataType,
        }, ...prev]);
        setWriteMessage("");
        setImage("");
        setFileImage("")
      }
    });
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if(callApi || !chats){
      setChatMessages([])
    }
    setPage(1)
    setWriteMessage("")
    setImage("")
    setFileImage("")
  }, [vendor]);

  useEffect(()=>{
    if(callApi || !chats){
      dispatch(
        getChats({
          user: user?.id,
          vendor: vendor?.id,
          page: page,
        })
      );
    }
  },[vendor, page])

  useEffect(() => {
    let arr = [];
    chats?.map((chat) => {
      arr.push({
        message: chat.content,
        messageFrom: chat.user.id === user.id ? "sender" : "reciever",
        type: chat.dataType,
      });
    });
    setChatMessages(prev => [...prev, ...arr]);
  }, [chats]);

  const chatOnScrollTop = (e) => {
    var a = e.target.scrollTop;
    var b = e.target.clientHeight - e.target.scrollHeight;
    var maxScrollTop = a / b;
    if (maxScrollTop >= 0.9 && chatMessages?.length == page * 20) {
      setPage((prev) => prev + 1);
    }
  };


  return (
    <div className={classes.chatRoom}>
      <div className={classes.vendorDetails}>
        <div className={classes.user}>
          <img src={vendor?.img} alt="user pic"  crossorigin="anonymous"/>
          <div>
           <p>  {vendor?.firstname} {vendor?.lastname} </p>
           <p>  {vendor?.type}</p>
          </div>
         
        </div>
        <div>
          <IoCloseCircleOutline
            size={30}
            color="#fff"
            onClick={() => {
              setChatMessages([])
              setPage(1)
              dispatch(clearChats())
              onChatClose()
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className={classes.chatContent} onScroll={(e) => chatOnScrollTop(e)}>
       
        {chatMessages?.map((message, index) => {
          return <ChatMessage messageInfo={message} key={index} />;
        })}
         {
          loading ?
          <div className="text-center w-100 m-auto">
            <Spinner animation='border'/>
          </div>
          :
          <></>
        }
      </div>
      <div className={classes.controller}>
        <img
         crossorigin="anonymous"
          src={image}
          alt="pic"
          style={{ width: "50px", height: "50px", margin: "0px 10px" }}
          className={`${image ? null : "d-none"}`}
        />
        <input
          placeholder={words.WRITE_HERE[language]}
          name="message"
          className={`form-control me-2 ${image ? "d-none" : null}`}
          onChange={(e) => setWriteMessage(e.target.value)}
          value={writeMessage}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setChatMessages(prev => [ {
                message: writeMessage,
                messageFrom: "sender",
                type: "TEXT",
              }, ...prev]);
              setWriteMessage("");
              setImage("");
              setFileImage("")
              socket.emit("newMessage", {
                dataType: "TEXT", //TEXT - IMAGE - DOCX - RECORD - LINK - VIDEO
                content: writeMessage,
                toId: vendor?.id,
                fromId: user?.id,
              });
            }
          }}
        />
        <input
          type="file"
          name="sendImage"
          className="d-none"
          id="sendImage"
          onChange={(e) => {
            dispatch(uploadImages({
              images: [e.target.files[0]]
            })).then((res)=>{
              setFileImage(res.payload?.[0])
            })
            setImage(URL.createObjectURL(e.target.files[0]));
          }}
        />
        {image ? (
          <IoCloseCircleOutline
            size={30}
            color="#fff"
            onClick={() => setImage("")}
            style={{
              cursor: "pointer",
            }}
          />
        ) : (
          <label htmlFor="sendImage">
            <div className={`${classes.imageBtn}`}>
              <BiImageAdd
                size={30}
                color="#fff"
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </label>
        )}

        <button
          className={`${classes.sendBtn} btn`}
          onClick={handleSentMessage}
          disabled={!writeMessage && !fileImage}
        >
          <MdSend size={25} color="#fff" />
        </button>
      </div>
    </div>
  );
};

export default Chat;
