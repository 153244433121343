import React from "react";
import { IoAddSharp } from "react-icons/io5";
import ItemAdress from "./../ItemAdress/ItemAdress";
import { useSelector } from "react-redux";
import { useState } from "react";
import CustomModal from "../../customModal/CustomModal";
import AddAddressForm from "./AddAddressForm";
import words from "../../../assets/words";

const ListAdress = () => {
  const addresses = useSelector((state) => state.addresses.addresses?.data);
  const language = useSelector((state) => state.auth.language);

  const [showAddressModal, setShowAddressModal] = useState(false);

  return (
    <>
      {showAddressModal && (
        <CustomModal
          show={showAddressModal}
          onHide={() => setShowAddressModal(false)}
          title={words.ADD_NEW_ADDRESS[language]}
        >
          <AddAddressForm onDone={()=> setShowAddressModal(false)} />
        </CustomModal>
      )}
      <div className="row mt-4">
        {addresses?.map((address, index) => {
          return <ItemAdress address={address} key={index} />;
        })}
      </div>
      <button
        className="btn"
        style={{
          border: "1px solid #E0E0E0",
          paddingRight: language === "en" && "2rem",
          color: "#4a3e4c",
          fontWeight: 500,
        }}
        onClick={() => setShowAddressModal(true)}
      >
        <IoAddSharp style={{ marginRight:  "5px", fontSize: "20px" }} />
        {words.ADD_NEW_ADDRESS[language]}
      </button>
    </>
  );
};

export default ListAdress;
