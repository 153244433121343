import styled from "styled-components";

export const DataSummstyle = styled.ul`
  li {
    color: #545454;
  }
  .val_discount {
    color: #fa3434;
  }
  .val_tax {
    color: #00b517;
  }
`;
