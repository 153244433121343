import { useEffect, useState } from "react";
import OrderCard from "../../../Components/ProfileUser/Order/OrderCard";
import { createDate } from "../../../utils/formatWords";
import pic from "../../../assets/Images/bigImage.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderStatus, getOrders,sendOrderRoDerivers } from "../../../Store/reducers/orders";
import { Spinner } from "react-bootstrap";
import words from "../../../assets/words";

const OrdersDash = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const orders = useSelector((state) => state.orders.orders);
  const loader = useSelector((state) => state.orders.loading);
  const statusLoader = useSelector(state => state.orders.cancelLoading)
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    dispatch(
      getOrders({
        status: type == "new" ? "ACCEPTED" : "",
      })
    );
  }, [type]);

  const handleChangeStatus = (e, order) => {
    dispatch(changeOrderStatus({
      id: order?.id, 
      status: e.target.value
    })).then(()=>{
      dispatch( getOrders({
        status: type == "new" ? "ACCEPTED" : "",
      }))
    })
  }


  const handleDerivers = (order) => {
    dispatch(sendOrderRoDerivers({
      id: order?.id, 
    })).then(()=>{
      dispatch( getOrders({
        status: type == "new" ? "ACCEPTED" : "",
      }))
    })
 };


  return (
    <div>
      {orders?.data?.map((order, index) => {
        return (
          <>
            {(order?.status !== "ACCEPTED" || type == "new") && (
              <OrderCard key={index}>
                <div className="d-flex justify-content-between align-items-start mb-3">
                  <div className="id_date">
                    <p className="id_order">
                      {words.ORDER_ID[language]}: <span>{order?.id}</span>
                      <br/>
                      {words.DATE[language]}:{" "}
                      <span className="date_order mx-4">
                        {createDate(order?.createdAt)}
                      </span>
                    </p>
                    <span
                      className="state_order fw-medium"
                      style={{
                        color: order?.status == "CANCELED" && "red",
                      }}
                    >
                      {order?.status}
                    </span>
                  </div>
                  {type != "old" && type && (
                    <div className="action_order d-flex gap-2 ">
                      <select
                        id="inputState"
                        className="form-select"
                        name="city"
                        style={{
                          width: "100%",
                        }}
                        // value={addressData.city}
                        onChange={(e) => handleChangeStatus(e, order)}
                        placeholder="Select City"
                        disabled={statusLoader}
                      >
                        <option value="Select" disabled>
                          {words.CHANGE_STATUS[language]}
                        </option>
                        <option value="ON-PROGRESS">{words.ON_PROGRESS[language]}</option>
                        <option value="outForDelivery">
                          {words.OUT_OF_DELIVERY[language]}
                        </option>
                        <option value="delivered">{words.DELIVERED[language]}</option>
                      </select>

                      <button className="btn track_ord mx-2 w-100"
                      onClick={() => handleDerivers(order)}
                      >
                        {words.SENT_TO_DERIVERS[language]}
                      </button>
                      {/* <Link to={`/track-order/${order?.id}`} className="btn track_ord">
         Track order
       </Link> */}
                    </div>
                  )}
                  {!type && order?.status !== 'DELIVERED' && (
                    <div className="action_order d-flex gap-2 ">
                      <button
                        className="btn track_ord mx-2 w-100"
                        onClick={() =>
                          navigate(`/dashboard/drivers-replies/${order?.id}`)
                        }
                      >
                        {words.SHOW_OFFERS[language]}
                      </button>
                    </div>
                  )}
                </div>

                <div className="about_user border-bottom pb-3">
                  <div className="row">
                    <div className="col">
                      <span className="title d-inline-block pb-2">{words.PERSON[language]}</span>
                      <ul className="list-group">
                        <li>
                          {order?.client?.firstname} {order?.client?.lastname}
                        </li>
                        <li>{words.PHONE[language]}: {order?.client?.phone}</li>
                        <li>{words.EMAIL[language]}: {order?.client?.email}</li>
                      </ul>
                    </div>
                    <div className="col">
                      <span className="title d-inline-block pb-2">
                        {words.ADDRESS[language]}
                      </span>
                      <ul className="list-group">
                        <li className="address">
                          {order?.area?.name}, {order?.city?.name}
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <span className="title d-inline-block pb-2">{words.PAYMENT[language]}</span>
                      <ul className="list-group">
                        <li className="num_credit">{order?.paymentSystem}</li>
                        <li>{words.SHIPPING_FEE[language]}: {order?.deliveryCost}</li>
                        <li>{words.TOTAL_PAID[language]}: {order?.finalTotal}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="products_bought pt-3">
                  <div className="row">
                    {order?.orderItems?.map((item, y) => {
                      return (
                        <div className="col" key={y}>
                          <div className="d-flex justify-content-start align-items-start gap-3 mb-2">
                            <div className="image_prod border border-1 p-3 rounded-3">
                              <img
                                crossorigin="anonymous"
                                src={
                                  item?.product?.files
                                    ? item?.product?.files
                                    : item?.product?.images?.images?.[0]
                                }
                                alt="productShorts"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div className="txt_product">
                              <p className="title_ pb-2">
                                {item?.product?.name}
                              </p>
                              <p className="title_ pb-2">
                                {item?.size?.name}, {item?.color?.name}
                              </p>
                              <p className="num_products_price">
                                {item?.count}x = {item?.unitCost}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </OrderCard>
            )}
          </>
        );
      })}

      {orders?.data?.length === 0 && (
        <div className="text-center" style={{ height: "50%" }}>
          <b>{words.NO_DATA_FOUND_FOR_DISPLAY[language]} </b>
        </div>
      )}

      {loader && (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default OrdersDash;
